.o-070 {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    &__tab-content-wrapper {
        display: flex;
        @include fromDesktop {
            display: block;
        }
    }
    &__tab-content {
        display: none;
        width: 100%;
        padding: 15px;
        @include fromDesktop {
            display: block;
            padding: 0 15px;
        }
        &.active {
            display: block;
        }
    }
    &__category-headline {
        margin-top: 30px;
    }
    &__sections-desktop {
        margin-top: 50px;
    }
    .a-01400 {
        @include fromDesktop {
            display: none;
        }
    }
    px-a02700 {
        @include fromTablet {
            padding-right: 5px;
        }
        @include fromDesktop {
            text-align: left !important;
        }
    }
    .m-0190 {
        @include fromDesktop {
            margin-bottom: 1rem;
        }
    }
    .m-0160 {
        .cars-detail__headline {
            margin-top: 25px;
            @include font-primary(h3);
        }
    }
}