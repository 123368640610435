.c-0017 {
    position: relative;
    width: 100%;
    .a-00500.a-00500--d {
        img {
            width: 100%;
            margin-bottom: -1px;
            @include fromDesktop {
                object-fit: cover;
                min-height: 811px;
            }
            @include fromExtraWide {
                object-fit: cover;
                height: 940px;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        &_text {
            padding: 25px 15px 0;
            width: 100%;
            max-width: 450px;

            @include fromPhone {
                padding: 70px 30px 0;
                max-width: unset;
            }

            @include fromTablet {
                padding: 70px 40px 0;
                max-width: 880px;
            }

            @include fromDesktop {
                padding: 70px 50px 0;
                max-width: 900px;
                justify-content: flex-end;
            }

            @include fromWide {
                padding: 70px 80px 0;
                max-width: 960px;
            }

            .subHeadline {
                color: $colorWhite;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 0;
                text-transform: none;

                @include fromPhone {
                    font-size: 25px;
                    line-height: 30px;
                }

                @include fromTablet {
                    font-size: 30px;
                    line-height: 36px;
                }
            }

            .headline {
                color: $colorWhite;
                font-family: $font-primary-regular;
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 15px;

                @include fromPhone {
                    font-size: 40px;
                    line-height: 48px;
                }

                @include fromTablet {
                    font-size: 50px;
                    line-height: 60px;
                }
            }

            .copy {
                color: $colorWhite;
                font-size: 15px;
                line-height: 1.4;
                word-break: break-word;
                hyphens: none;
                @include fromPhone {
                    font-size: 15px;
                    line-height: 1.6;
                    max-width: 540px;
                }

                @include fromTablet {
                    font-size: 16px;
                    line-height: 30px;
                    max-width: unset;
                }
            }
        }
    }
    &__envkv {
        position: static;
        width: 100%;
        @include fromTablet {
            position: absolute;
            bottom: 0;
        }
    }
}