.o-210 {
    &__background {
        background-color: $colorDarkGrey;
        @include fromDesktop {
            background-color: transparent;
        }
    }
    &__headline {
        // @include font-lato-bold(18px, $colorWhite, normal, 22px);
        // @include fromDesktop {
        //     @include font-lato-bold(28px, $colorWhite, normal, 29px);
        //     color: $colorBlack;
        //     text-align: center;
        // }
        @include font-primary(h1, $colorWhite);
        @include fromDesktop {
            color: $colorBlack;
            text-align: center;
        }
        span {
            // @include font-lato-regular(14px, $colorWhite, normal, 20px);
            // @include fromDesktop {
            //     @include font-lato-regular(16px, $colorWhite, normal, 26px);
            //     color: $colorBlack;
            // }
            @include font-primary(copy, $colorWhite);
                @include fromDesktop {
                color: $colorBlack;
            }
        }
    }
    &__filter-headline {
        @include font-lato-bold(24px, $colorBlack, normal, 29px);
        // @include font-primary(h1);
        text-align: center;
        margin-bottom: 20px;
    }
    &__filter-section {
        @include fromDesktop {
            padding-top: 36px;
            padding-bottom: 16px;
            background-color: $colorWhite;
            @include fullWidth;
            &--filter-options {
                margin-top: 0;
            }
        }
    }
    &__controls {
        margin: 0 auto;
        position: relative;
        @include fromDesktop {
            max-width: 800px;
        }
        @include fromWide {
            max-width: 1200px;
        }
        > button {
            position: absolute;
            top: 40px;
            z-index: 1;
            background: none;
            border: none;
            outline: 0 !important;
            svg {
                width: 20px;
            }
        }
        .prev {
            left: -45px;
        }
        .next {
            right: -45px;
        }
    }
    &__car-title {
        position: relative;
        @include font-primary(copy);
        @include fromDesktop {
            margin-bottom: 0;
        }
        .check {
            // position: absolute;
            right: -19px;
            top: -5px;
            svg {
                width: 14px;
                height: 12px;
            }
        }
    }
    &__content-wrapper {
        padding: 20px 12px;
    }
    &__car-filter {
        @include fromDesktop {
            max-width: 800px;
            overflow: hidden;
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0 auto;
        }
        @include fromWide {
            max-width: 1200px;
        }
    }
    &__car-filter-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        @include fromDesktop {
            width: auto;
            flex-wrap: nowrap;
            max-width: 1200px;
            justify-content: center;
            transition: transform .4s ease-in-out;
        }
    }
    &__car-filter-element {
        max-width: 47%;
        margin-top: 18px;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid rgba(0,0,0,.1);
        @include fromDesktop {
            margin-top: 0;
            max-width: 200px;
            border: 0;
            flex-shrink: 0;
        }
        img {
            max-width: 100%;
            height: auto;
        }
        &.active {
            span {
                color: $colorRed;
            }
        }
    }
    // &__seperator {
    //     margin: 0 auto;
    //     margin-top: 25px;
    //     @include fromDesktop {
    //         max-width: 800px;
    //     }
    //     @include fromWide {
    //         max-width: 1200px;
    //     }
    // }
    &__modal-window {
        z-index: 0;
    }
    &__modal-close {
        position: absolute;
        top: 20px;
        right: 20px;
        svg {
            width: 17px;
            height: 16px;
        }
    }
    &__modal-top-wrapper {
        margin-top: 17px;
        padding: 0 20px;
    } 
    &__filter-options-row {
        padding: 7px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &--slider {
            padding-top: 0;
            padding-bottom: 16px;
        }
        h3 {
            @include font-lato-bold(14px, $colorBlack, normal, 18px);
            margin-bottom: 0;
        }
    }
    &__filter-options-value {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 75%;
        @include font-lato-regular(14px, $colorDarkGrey, normal, 18px);
        .value {
            margin-right: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .icon {
            svg {
                width: 15px;
                height: 9px;
                position: relative;
                top: -1px;
                polygon {
                    fill: $colorGrey !important;
                }
            }
        }
    }
    &__slider-value {
        @include font-lato-regular(14px, $colorDarkGrey, normal, 18px);
    }
    &__filter-options-collapse {
        padding: 0px 20px 9px 20px;
        hr {
            position: relative;
            margin-bottom: 25px;
            width: 100vw;
            left: -50vw;
            margin-left: 50%;
        }
        &--registration {
            padding: 0 11px 9px 11px;
            hr {
                margin-bottom: 13px;
            }
        }
    }
    &__registration-button-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__slider-wrapper {
        padding: 0 20px;
    }
    &__filter-option-registration-button {
        border: 1px solid $colorGrey;
        width: 47%;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        background-color: white;
        transition: all .2s ease-in-out;
        @include fromDesktop {
            width: 46%;
            height: 44px;
        }
        span {
            position: relative;
            @include font-lato-bold(12px, $colorBlack, normal, normal);
            transition: color .2s ease-in-out;
            text-transform: uppercase;
            .icon {
                position: absolute;
                right: -15px;
                top: -1px;
                svg {
                    width: 10px;
                    height: 8px;
                    polygon {
                        fill: $colorWhite !important;
                    }
                }
            }
        }
        &.active {
            background-color: $colorRed;
            border-color: $colorRed;
            span {
                color: $colorWhite;
            }
        }
    }
    &__collapse-link {
        &[aria-expanded="true"] {
            .icon {
                transform: rotate(180deg);
            }
        }
    }
    &__filter-options-model-wrapper {
        margin-top: -18px;
    }
    &__filter-cta {
        position: fixed;
        bottom: 50px;
        left: 0;
        right: 0;
        @include fromTablet {
            bottom: 59px;
        }
    }
    &__last-hr {
        margin-bottom: 0;
    }
    &__filter-options-desktop {
        margin: 0 auto;
        display: flex;
        @include fromDesktop {
            max-width: $container-width-lg;
            px-a01000 {
                margin-left: auto;
            }
        }
        @include fromWide {
            max-width: 1200px;
        }
    }
    &__filter {
        @include fromDesktop {
            position: relative;
            max-width: 200px;
            width: 180px;
            margin: 0 10px;
            border-top: 1px solid $colorLightGrey;

            &.active {
              border-color: $colorRed;
              border-width: 3px;
              top: -2px;
              margin-bottom: -2px;
              .text {
                font-family: mmc-bold,Helvetica,Arial,sans-serif;
              }
            }
        }
    }
    &__filter-dropdown-button {
        background: none;
        border: none;
        padding: 18px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        @include fromWide {
            padding: 18px 15px;
        }
        .text {
            // @include font-lato-bold(14px, $colorBlack, normal, 16px);
            @include font-primary(cta);
        }
        .icon {
            position: relative;
            top: -4px;
            &.active {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
        svg {
            width: 16px;
            height: 9px;
        }
    }
    &__filter-dropdown {
        position: absolute;
        bottom: 0;
        width: 305px;
        height: auto;
        padding: 25px;
        background-color: white;
        transform: translateY(100%);
        box-shadow: 0;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
        &.active {
            z-index: 3;
            opacity: 1;
            visibility: visible;
            box-shadow: 0 5px 7px 1px rgba(0,0,0,.2);
        }
        .a-00800 {
            margin-top: 22px;
        }
    }
    &__filter-dropdown-input-wrapper {
        display: flex;
        align-items: center;
        margin-top: 20px;
        span {
            margin: 0 4px;
        }
    }
    &__reset-button-wrapper {
        padding: 0 20px 10px 20px;
        .a-01000 {
            max-width: 100%;
        }
    }
    &__desktop-sticky-filter {
        position: fixed !important;
        top: 0;
        background-color: $colorWhite;
        z-index: 3;
        transform: translateY(-100%);
        box-shadow: 0;
        transition: all .3s ease-in-out;
        &.active {
            transform: translateY(0);
            box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
        }
    }
    &__sticky-filter-wrapper {
        padding: 20px 16px;
        span {
            padding-right: 30px;
            border-right: 1px solid $colorBorderGray;
        }
    }
    &__sticky-filter-button {
        margin-left: 30px;
        background: none;
        border: none;
        @include font-primary(copy-bold);
    }
}

px-filter-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //height: 100%;
    padding-top: 50px;
    @include fromDesktop {
        display: block;
        width: 100%;
        padding-top: 0;
    }
}

px-car-filter {
    width: 100%;
}

px-filter-modal {
    height: 100%
}

// Range slider with 2 values
.ng5-slider {
    margin-top: 16px !important;
}
.ng5-slider-pointer {
    outline: 0 !important;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.30);
    background-color: $colorWhite !important;
    width: 29px !important;
    height: 29px !important;
    &:after {
        background: #fff !important;
    }
}
.ng5-slider-bar {
    height: 2px !important;
}
.ng5-slider-bubble {
    display: none !important;
}
.ng5-slider-selection {
    background: $colorRed !important;
}
