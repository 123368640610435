// .cc_banner-wrapper{
//     .cc_banner{
//         background: rgba(255,255,255,0.9) !important;
//         color: $colorBlack;
//         font-size: 14px;
//         .cc_btn{
//             background-color: $colorBlack;
//             text-transform: uppercase;
//             border: 0;
//             border-radius: 0px;
//             color: $colorWhite;
//             -webkit-border-radius: 0px;
//             font-size: 14px;
//             @include font-primary-regular();
//             &:hover{
//                 background-color: $colorDarkGrey;
//                 color: $colorWhite;
//             }
//             @include toTablet(){
//                 float: unset;
//                 margin: auto;
//             }
//         }
//         .cookieL{
//             text-decoration: unset;
//             transition: unset;
//             @include font-lato-bold(15px, $colorDarkGrey, normal, 21px);
//         }
//     }
//     p{
//         font-size: 15px !important;
//     }
//     .cc-message{
//         width: 80% !important;
//         @include font-primary(copy);
//     }
// }
@include toDesktop {
    .cmpboxrecall {
        bottom: 60px;
    }
}
*.cmpbox *, *.cmpbox > * {
    font-family: $font-primary-regular !important;
}
*.cmpboxhl, .cmpfullscreen *.cmpprpshl, *.cmptbl th{
    font-family: $font-primary-bold !important;
    font-weight: normal !important;
}