.a-01400 {
    &__content-wrapper {
        padding: 0 25px;
    }
    &__labels {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $colorBlack;
        overflow: hidden;
    }
    .indicator {
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $colorRed;
        transition: left 0.6s cubic-bezier(0.4, 0, 0.8, 1.40);;
    }
    &__section {
        position: relative;
        display: flex;
        color: $colorWhite !important;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        height: 60px;
        position: relative;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
        &.active {}
        &:first-child {
            &::after {
                @extend .tabSeparator;
                right: 0px;
            }
        }
        &:nth-child(3) {
            &::before {
                @extend .tabSeparator;
                left: 0px;
            }
        }
    }
    .mat-tab-list {
        width: 100%;
    }
    .mat-tab-body-content {
        padding-bottom: 50px;
    }
    .mat-tab-labels {
        @extend .a-01400__labels;
    }
    .mat-tab-label {
        @extend .a-01400__section;
        opacity: 1;
        white-space: normal !important;
        font-family: $font-primary-regular;
        &:first-child {
            &::after {
                display: none;
            }
        }
        &:nth-child(3) {
            &::before {
                display: none;
            }
        }
    }
    .mat-tab-label-content {
        color: white;
        white-space: normal !important;
    }
    .mat-ink-bar {
        background-color: $colorRed !important;
        height: 6px;
    }
}

.tabSeparator {
    content: '';
    display: block;
    height: 28px;
    width: 1px;
    background-color: $colorGrey;
    position: absolute;
    top: 19px;
}
