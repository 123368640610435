.m-0200 {
    &__wrapper {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $colorBorderGray;
    }
    &__label {
        font-size: 12px;
        font-family: $font-primary-regular;
        color: $colorBlack;
        margin-bottom: 0;
    }
    &__collapse {
        border-bottom: 1px solid $colorBorderGray;
        padding-top: 17px;

    }
    &__exception {
        margin-top: 12px;
    }
    px-a02700 {
        display: block;
        margin-bottom: 17px;
    }
}