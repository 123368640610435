.c-0012 {
    position: relative;
    font-size: 0;
    line-height: 0;
    .slick-track {
        @include toPhone {
            background-color: #202020;
        }
    }
    
    .m-0320_key_facts {
        display: none;
        @include fromPhone {
            display: block;
        }
    }

    .slick-next {
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 15px;
        cursor: pointer;
        transform: translateY(-15px);
        @include toPhone {
            top: 50vh;
        }

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            width: 30px;
            height: 30px;
            background-image: url(../Icons/arrow-next.svg);
            background-size: contain;
        }

        @include fromTablet {
            right: 30px;
            width: 45px;
            height: 45px;
            transform: translateY(-22px);
            &::before {
                width: 45px;
                height: 45px;
            }
        }
        @include fromDesktop {
            right: 60px;
            width: 60px;
            height: 60px;
            transform: translateY(-30px);
            &::before {
                width: 60px;
                height: 60px;
            }
        }

    }
    .slick-prev {
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 15px;
        cursor: pointer;
        transform: translateY(-15px) rotate(180deg);
        @include toPhone {
            top: 50vh;
        }
        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            width: 30px;
            height: 30px;
            background-image: url(../Icons/arrow-prev.svg);
            background-size: contain;
        }
        @include fromTablet {
            width: 45px;
            height: 45px;
            left: 30px;
            transform: translateY(-22px) rotate(180deg);
            &::before {
                width: 45px;
                height: 45px;
            }
            
        }
        @include fromDesktop {
            width: 60px;
            height: 60px;
            left: 60px;
            transform: translateY(-30px) rotate(180deg);
            &::before {
                width: 60px;
                height: 60px;
            }
        }
    }
    .a-01000--c:hover {
        background-color: transparent;
        opacity: 0.4;
    }
    @include toPhone {
        .m-0320 .image-primitive px-lazy-image img {
            min-height: 165.21vw;
        }
    }
}