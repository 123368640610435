.a-03200 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 32px;
    height: 32px;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    /* only for preview */
    span {
        color: white;
        top: -2px;
        position: relative;
    }
}