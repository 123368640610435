.a-01800 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 55px;
    padding-left: 25px;
    padding-right: 25px;
    .text {
        color: $colorDarkGrey;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        font-weight: 400;
        height: 100%;
        width: 100%;
        border-bottom: 1px solid $colorGrey;
        display: flex;
        align-items: center;
    }
    &:focus {
        background-color: $colorDarkGrey;
        .text {
            color: white;
            border-bottom: 1px solid $colorDarkGrey;
        }
    }
}