.modal{
    @media (min-width: 992px){
        .modal-lg{
            max-width: 1220px;
        }
    }
}

.editModal {
    &__spinnerWrapper {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.32);
        z-index: 1060;
        &.active {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}