.m-0350 {
    &__wrapper {
        display: inline-block;
        position: relative;
        max-width: calc(100vw - 60px);
        width: 376px;
        height: 100%;
        background-color: #f9f9f9;

        &:hover {
            @include fromDesktop {
                animation-name: m-0350scale;
                animation-duration: 0.4s;
                transform: scale(1.04, 1.04);   
            }
        }

        a:visited {
            color: unset;
        }

        &__image-wrapper {
            max-height: 282px;
        }

        &__content {
            position: relative;
            padding: 12px 24px;

            h6 {
                line-height: 24px;
                font-size: 21px;
                font-family: $font-primary-regular;
                font-weight: 400;
                margin-top: 0;
                height: 49px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 16px;
                &:first-child {
                    margin: 0 0 5px 0;
                    margin: 0;
                    height: auto;
                }
            }

            &__vehicle-data-top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                color: #494948;
                font-size: 16px;

                .value {
                    display: block;
                    font-family: 'mmc-regular';
                }
            }

            hr {
                margin: 12px 0;
            }

            &__vehicle-data-bottom {
                height: 325px;
                max-height: 325px;
                display: flex;
                flex-direction: column;
                color: #9e9e9e;
                font-size: 14px;
                font-family: 'mmc-regular';

                .text {
                    font-weight: 400;
                    font-family: 'mmc-bold';
                  }

                .text-group {
                    display: flex;
                    justify-content: space-between;
                }

                .value {
                    display: block;
                    &.double-value {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    }
                  }
            }

            &__price {
                .productCardPrice {
                    display: flex;
                    justify-content: space-between;
                    color: #e3000f;
                    font-family: $font-primary-bold;
                    font-size: 20px;
                    width: 100%;
                    line-height: 1;
                    font-weight: 700;
                    vertical-align: baseline;
                    @include fromPhone {
                        font-size: 24px;
                    }
                    .productCardRate {
                        float: right;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    &__default-wrapper {
        display: inline-block;
        position: relative;
        max-width: calc(100vw - 60px);
        width: 376px;
        height: 100%;

        &__image-wrapper {
            max-height: 282px;
        }

        &__content {
            padding: 12px 24px;
            position: relative;

            h3 {
                font-family: $font-primary-regular;
                font-size: 25px;
                font-weight: 400;
            }

            .copy {
                margin: 10px 0;
                p {
                    font-family: $font-primary-regular;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 400;
                }
            }

            .cta-wrapper {
                width: 250px;

                a {
                    height: 42px;
                }

                .text {
                    font-size: 12px;
                    text-transform: none;
                }
            }
        }
    }

    .mobile {
        display: none;
    }
}
