.a-03300 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .icon {
        width: 28px;
        height: 18px;
        display: block;
        text-align: center;
        flex-shrink: 0;
        // @include fromDesktop {
        //     width: 20px;
        //     height: 20px;
        // }
        svg {
            vertical-align: top;
            max-width: 100%;
            max-height: 100%;
        }
        &__icon_art_gebrauchtwagen {
            height: 15px;
        }
        &__icon_art_neuwagen {
            height: 19px;
        }
        &__icon_zapfsaeule {
            height: 17px;
        }
        &__icon_getriebe {
            height: 15px;
        }
        &__icon_dashboard_kilometer {
            height: 16px;
        }
        &__icon_calendar_datepicker_erstz {
            height: 17px;
        }
        &__icon_engine_leistung {
            height: 15px;
        }
        &__icon-mesh-seat {
            height: 23px;
        }
        &__icon-dashboard-speed {
            height: 13px;
        }
        &__pw_icn_hubraum {
            height: 22px;
        }
        &__ic_marke {
            height: 15px;
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
        }
        .type {
            @include font-primary(tag, $colorBlack);
            // font-family: $font-primary-bold;
            // font-size: 10px;
            // color: black;
            // @include fromTablet {
            //     font-size: 14px;
            // }
        }
        .description {
            @include font-primary(copy, $colorDarkGrey);
            // font-size: 14px;
            // color: $colorDarkGrey;
            // @include fromTablet {
            //     font-size: 16px;
            // }
        }
    }
}