.o-110__main-navigation > .row {
    margin: 0;
}
.o-110__partner-navigation > .row {
    margin: 0;
}


.mobileMenuActive{
    -webkit-overflow-scrolling : touch !important;
	overflow: hidden !important;
	height: 100% !important;
}

body{
    overflow-x: hidden;
}

.page-header {
    @include fromDesktop {
        // width: 100vw;
        margin-left: -15px;
        margin-right: -15px;
        box-shadow: 0 1px 15px 0 rgba(0,0,0,0.12);
        position: relative;
    }
}
.o-110 {
    margin: 0;
    z-index: 1051;
    @include fromDesktop {
        z-index: 1;
    }
    &__partner-navigation {
        background-color: $colorDarkGrey;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        z-index: 1051;
        width: 100vw;
        max-width: 100%;
        overflow: hidden;
        -webkit-backface-visibility: hidden !important;
        backface-visibility: hidden !important;
        > .row > .container {
            padding: 0;
            @include toDesktop {
                max-width: 100%;
            }
            @include fromDesktop {
                padding: 0 15px;
            }
        }
        @include fromDesktop {
            position: relative;
            z-index: 1;
        }
    }
    &__navi-container {
        width: 100%;
        z-index: 10;
        @include fromDesktop {
            background-color: $colorWhite;
            position: relative;
            bottom: 0;
            width: auto;
            display: inline-block;
            float: right;
            overflow: visible;
            max-height: 100%;
            z-index: 1;
        }
        &.mobile {
            position: fixed;
            left: 0;
            overflow-y: auto;
            overflow-x: hidden;
            bottom: 50px;
            transition: transform .5s cubic-bezier(0, 0.34, 0, 1);
            // opacity: 0;
            
            transform: translateY(100%);

            input{
                z-index: 999;
            }
            //transform-origin: bottom;
            @include fromTablet {
                bottom: 59px;
            }
            &.active {
                // opacity: 1;
                max-height: calc(100% - 50px);
                transform: translateY(0);
                @include fromTablet {
                    max-height: calc(100% - 59px);
                }

                height: 100%;
            }
            &.fullheight {
                max-height: 100% !important;
            }
        }
        .m-0251:last-child {
            padding-bottom: 0;
            .a-03400 {
                border-bottom: 0;
            }
        }
    }
    &__navi-container-mobile-wrapper {
        position: absolute;
        // top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 100%;

        &.active {
            z-index: 10;
        }
    }
    &__navi-container-mobile {
        z-index: 1;
        overflow: hidden;
        // transition: opacity .5s ease-in-out;
        // height: 0;
        // transform: scaleY(0);
        // transform-origin: bottom;
        // opacity: 0;
        display: none; 
        transform: translateY(100%);
        transition: transform 0.4s ease-in-out;
        background-color: $colorWhite;
        // transition-delay: 0.8s;
        &.show {
            // transition: none;
            // opacity: 1;
        }
        &.active {
            // transform: scaleY(1);
            display: block;
            height: 100%;
            // opacity: 1;
            transition: transform 0.8s ease-in-out;
            transform: translateY(0%);
            // transition-delay: 0s;
        }
    }
    &__overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        z-index: 0;
        top: 0px;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: all .5s ease-in-out;
        visibility: hidden;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        &.fullheight {
            top: 0 !important;
        }
    }
    .m-0080 {
        .a-03600 {
            .text {
                margin-left: 0;
            }
            // .icon {
            //     @include fromDesktop {
            //         display: none;
            //     }
            // }
        }
    }
}