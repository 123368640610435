.a-01200 {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid $colorGrey;
    // max-width: 276px;
    .elementWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .text {
        padding: 0 15px;
        text-transform: uppercase;
        text-align: left;
        // @include font-lato-bold(12px, $colorBlack, normal, 16px);
        @include font-primary(cta);
        color: $colorBlack;
    }
    .arrow {
        display: inline-block;
        align-items: center;
        -webkit-align-items: center;
        width: 9px;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    &:hover {
        .icon {
            svg {
                @include fillSVG($colorDarkGrey);
            }
        }
        .text {
            color: $colorDarkGrey;
        }
        .arrow {
            svg {
                @include fillSVG($colorDarkGrey);
            }
        }
    }
    &:focus {
        .icon {
            svg {
                @include fillSVG($colorRed);
            }
        }
        .text {
            color: $colorRed;
        }
        .arrow {
            svg {
                @include fillSVG($colorRed);
            }
        }
    }
    &.disabled {
        .icon {
            svg {
                @include fillSVG($colorGrey);
            }
        }
        .text {
            color: $colorGrey;
        }
        .arrow {
            svg {
                @include fillSVG($colorGrey);
            }
        }
    }
    &--b {
        .icon {
            svg {
                @include fillSVG($colorWhite);
            }
        }
        .text {
            color: $colorWhite;
        }
        .arrow {
            svg {
                @include fillSVG($colorWhite);
            }
        }
        &:hover {
            .icon {
                svg {
                    @include fillSVG($colorGrey);
                }
            }
            .text {
                color: $colorGrey;
            }
            .arrow {
                svg {
                    @include fillSVG($colorGrey);
                }
            }
        }
        &.disabled {
            .icon {
                svg {
                    @include fillSVG($colorDarkGrey);
                }
            }
            .text {
                color: $colorDarkGrey;
            }
            .arrow {
                svg {
                    @include fillSVG($colorDarkGrey);
                }
            }
        }
    }
    &--c, &--d {
        padding-left: 18px;
        padding-right: 21px;
        height: 73px;
        .icon {
            width: 31px;
            height: auto;
            svg {
                @include fillSVG($colorRed);
            }
        }
        &.disabled {
            .icon {
                svg {
                    @include fillSVG($colorGrey);
                }
            }
        }
    }
    &--d {
        cursor: pointer;
        .arrow {
            transform: rotate(90deg);
        }
    }
}