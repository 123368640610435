.a-02400 {
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 26px;
    margin-bottom: 0;
    .labelText {
        font-size: 12px;
        padding-left: 44px;
        font-weight: bold;
        text-transform: uppercase;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid $colorGrey;
}

.checkmark--red{
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid $colorGrey;
}

/* When the checkbox is checked, add a grey background */
.a-02400 input:checked ~ .checkmark {
    background-color: $colorDarkGrey;
    border: 1px solid $colorDarkGrey;
}
.a-02400 input:checked ~ .checkmark--red {
    background-color: $colorRed;
    border: 1px solid $colorRed;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkmark--red:after {
    content: "";
    position: absolute;
    display: none;
}

.a-02400 input:checked ~ .checkmark:after {
    display: block;
}

.a-02400 input:checked ~ .checkmark--red:after {
    display: block;
}


.a-02400 .checkmark:after {
    left: 9px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.a-02400 .checkmark--red:after {
    left: 9px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.a-02400 {
    &.hasError {
        .labelText {
            color: $colorRed;
        }
        .checkmark {
            border: 1px solid $colorRed !important;
        }
    }
}