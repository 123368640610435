.a-03350 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    @include toDesktop {
        margin-bottom: 10px;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .headline {
            @include font-primary(copy-bold);
        }
        .description {
            @include font-primary(copy, $colorDarkGrey);
        }
    }
}