.a-02600 {
    display: flex;
    flex-direction: row;
    .star {
        & ~ .star {
            margin-left: 6px;
        }
        svg {

            &.active {
                stop {
                    stop-color:$colorRed !important;
                }
                &--0 {
                    stop {
                        stop-color:$colorRed !important;
                    }
                }
                &--10 {
                    stop:nth-child(1), stop:nth-child(2) {
                        stop-color:$colorRed !important;
                    }
                }
                &--20 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3) {
                        stop-color:$colorRed !important;
                    }
                }
                &--30 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4) {
                        stop-color:$colorRed !important;
                    }
                }
                &--40 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4), stop:nth-child(5) {
                        stop-color:$colorRed !important;
                    }
                }
                &--50 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4), stop:nth-child(5),stop:nth-child(6) {
                        stop-color:$colorRed !important;
                    }
                }
                &--60 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4), stop:nth-child(5), stop:nth-child(6), stop:nth-child(7) {
                        stop-color:$colorRed !important;
                    }
                }
                &--70 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4), stop:nth-child(5),stop:nth-child(6), stop:nth-child(7), stop:nth-child(8) {
                        stop-color:$colorRed !important;
                    }
                }
                &--80 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4), stop:nth-child(5),stop:nth-child(6), stop:nth-child(7), stop:nth-child(8), stop:nth-child(9) {
                        stop-color:$colorRed !important;
                    }
                }
                &--90 {
                    stop:nth-child(1), stop:nth-child(2), stop:nth-child(3), stop:nth-child(4), stop:nth-child(5),stop:nth-child(6), stop:nth-child(7), stop:nth-child(8), stop:nth-child(9), stop:nth-child(10) {
                        stop-color:$colorRed !important;
                    }
                }
            }
            
        }
    }
    &--b {
        svg {
            width: 18px;
        }
    }
    &--c {
        svg {
            width: 15px;
        }
    }
}