.o-020 {
    background-color: $colorLightGrey;
    .container {
        @include toDesktop {
            max-width: 100%;
        }
    }
    &__headline {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 5px;
        h2 {
            margin: 0;
        }
        @include fromDesktop{
        margin-top: 52px;
        margin-bottom: 20px;
        }
    }
    &__moreWrapper {
        margin-top: 0;
        margin-bottom: 20px;
    }
    &__sliderWrapper {
        padding: 0;
        @include fromDesktop {
            // max-height: 600px;
            // margin: 0 -6px;
        }
        .slick-list {
            // padding: 0 18px !important;
            padding: 0 27px 0 9px !important;
            @include fromDesktop {
                padding: 2px !important;

                &.overflow {
                    overflow: visible;
                }
            }
        }
        .slick-dots {
            height: 35px;
            bottom: -35px;
            display: flex;
            justify-content: center;
            li {
                width: 7px;
                height: 7px;
                margin: 5px;
                &.slick-active {
                    width: 9px;
                    height: 9px;
                    margin: 4px 3px;
                }
                button {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .slick-arrow {
            height: 40px;
            width: 40px;
            display: none !important;
            z-index: 10;
            @include fromDesktop {
                display: inline-block !important;
            }
            &:before {
                color: $colorGrey;
                content: '';
            }
            &:hover {
                background-color: $colorLightGrey;
            }
        }
        .slick-slide {
            &.opacity {
                opacity: 0;
                &.slick-active {
                    opacity: 1;
                }
            }
        }
        
    }
    &__prevArrow, &__nextArrow {
        img, svg {
            height: 40px;
            width: auto;
            @include fillSVG($colorArrowGrey);
        }
     }
    &__prevArrow {
        left: -60px !important;
    }
    &__nextArrow {
        right: -60px !important;
    }
    &__slider {
        margin-bottom: 45px !important;
        display: flex;
    }
    &__sliderItem {
        padding: 10px 6px;
        flex: 0 0 90%;
        @include fromDesktop {
            flex: 0 0 33%;
        }
        &:focus, &:active {
            border: 0 solid transparent !important; 
            outline: none;
        }
    }
    .m-0060 {
        min-height: 100%;
        // margin-bottom: 5px;
        .a-00600 {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                max-height: 270px;
                @include fromPhone {
                    max-height: 170px
                }
                @include fromTablet {
                    max-height: 275px
                }
                @include fromDesktop {
                    max-height: 185px
                }
                @include fromWide {
                    max-height: 235px
                }
            }
        }
        &__image-wrapper {
            padding-bottom: 59%;
            overflow: hidden;
            .a-00600{
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                min-height: 100%;
            }
        }
    }
}

// Mobile TopOffer Carousel Styles
.slick-dots-container {
    max-width: 200px;
    overflow: hidden;
    bottom: -15px;
    margin: 0 auto;
    .slick-dots {
        display: block;
        white-space: nowrap;
        position: relative;
        bottom: 0;
        li {
            width: 7px;
            transition: all .2s ease-in-out;
            &.small {
                transform: scale(0.5);
            }
        }
    }
}

.o-022 {
    background-color: $colorWhite;
    &__sliderDots{
        &--3 {
            @include fromDesktop {
                display: none !important;
            }
        }
        &--2 {
            @include fromTablet {
                display: none !important;
            }
        }
        &--1,
        &--0 {
            display: none !important;
        }
        

    }
    .o-020 {
        &__headline {
            text-align: left;
            @include fromDesktop{
                margin-top: 105px;
                margin-bottom: 80px;
            }
            h2 {
                margin-bottom: 8px;
                @include fromDesktop{
                    margin-bottom: 8px;
                }
            }
        }
        &__sliderWrapper {
            padding: 0 10px;
            @include fromDesktop {
                padding: 0 48px;
            }
            .slick-arrow {
                height: 20px;
                width: 20px;
                display: inline-block !important;
                @include fromDesktop {
                    height: 40px;
                    width: 40px;
                    display: inline-block !important;
                }
            }
        }
        &__prevArrow, &__nextArrow {
            img, svg {
                vertical-align: top;
                height: 20px;
                @include fillSVG($colorBlack);
                @include fromDesktop {
                    height: 40px;
                }
            }
        }
        &__prevArrow {
            left: -5px !important;
            @include fromDesktop {
                left: -56px !important;
            }
        }
        &__nextArrow {
            right: -5px !important;
            @include fromDesktop {
                right: -56px !important;
            }
        }
        &__sliderItem {
            padding: 15px 10px;
            &:focus, &:active {
                border: 0 solid transparent !important; 
                outline: none;
            }
        }
    }
    .m-0065 {
        min-height: 100%;
        .a-00600 {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                max-height: 270px;
                @include fromPhone {
                    max-height: 170px
                }
                @include fromTablet {
                    max-height: 275px
                }
                @include fromDesktop {
                    max-height: 185px
                }
                @include fromWide {
                    max-height: 235px
                }
            }
        }
        &__image-wrapper {
            padding-bottom: 65.15%;
            .a-00600{
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                min-height: 100%;
            }
        }
        .a-00700--c {
            .a-00700__easing { 
                @include fromDesktop {
                    background-color: $colorDarkGrey;
                }
            }
            &.a-00700__wrapper {
                @include fromDesktop {
                    &:hover {
                        .a-00700 {
                            svg {
                                g, path, polygon {
                                    @include fromDesktop {
                                        fill: $colorWhite !important;
                                    }
                                }
                            }
                            .arrow, .text {
                                @include fromDesktop {
                                    color: $colorWhite;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}