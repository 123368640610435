.a-02100 {
    position: relative;
    label {
        position: absolute;
        top: 0;
        // bottom: 0;
        left: 0;
        width: 100%;
        padding: 14px 15px;
        margin-bottom: 0;
        height: 46px;
        font-weight: 400;
        color: $colorDarkGrey;
        font-size: 14px;
        line-height: 20px;
        transition:
            font-size 0.2s,
            height 0.2s,
            top 0.2s,
            bottom 0.2s,
            right 0.2s,
            left 0.2s;
    }
    input {
        width: 100%;
        height: 46px;
        background: transparent;
        position: relative;
        z-index: 1;
        padding: 5px 15px;
        outline: 0;
        border: 1px solid $colorFormBorder;
        border-radius: 0;
        box-shadow: 0;
        color: $colorRed;
        text-shadow: 0px 0px 0px $colorDarkGrey;
        -webkit-text-fill-color: transparent;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            color: black;
        }
        &:-webkit-autofill {
            -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
            -webkit-text-fill-color: $colorDarkGrey;
            &:focus {
                -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
                -webkit-text-fill-color: $colorDarkGrey;
                & ~ label {
                    z-index: 1;
                }
            } 
        }
        &.isValid {
            border: 1px solid $colorFormBorder;
            padding-top: 19px;
            & ~ label {
                color: black;
                @extend .labelActive;
                z-index: 1;
            }
        }
        &:focus {
            border: 1px solid black;
            padding-top: 19px;
            background-position: 0 0;
            & ~ label {
                color: black;
                @extend .labelActive;
            }
        }
        &.hasError {
            border: 1px solid $colorRed;
            padding-top: 19px;
            & ~ label {
                color: $colorRed;
                @extend .labelActive;
            }
        }
    }
    &--filter {
        width: 100%;
        input {
            &:focus {
                padding-top: 5px;
            }
            &::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
                -webkit-appearance: none; 
            }
        }
        .measure {
            font-size: 14px;
            position: absolute;
            right: 13px;
            top: 13px;
            color: $colorGrey;
            z-index: 1;
        }
    }
    &--indicator {
        input {
            padding: 5px 40px;
        }
        .indicator {
            position: absolute;
            top: 13px;
            left: 15px;
            font-size: 14px;
        }
    }   

    ngb-datepicker{
        .ngb-dp-month {
            .bg-light {
                background-color: #FFFFFF;
            }

            .ngb-dp-weekday {
                color: #333;
                font-style: normal;
                font-weight: 100 !important;
            }

            .ngb-dp-week {
                border-bottom: none;

                .ngb-dp-today{
                    border-color: rgb(237, 0, 0) !important;
                }

                .ngb-dp-day{
                    outline: none !important;
                    .bg-primary{
                        background-color:rgb(237, 0, 0) !important;
                        border-color: rgb(237, 0, 0) !important;
                        font-weight: 600;
                    }

                    .btn-light .ng-star-inserted{

                        &:focus{
                            outline: #000000;
                        }
                    }

                    [ngbDatepickerDayView]{
                        border-radius: 0rem;    
                        color: #575656;

                        &:hover{
                            color: #FFFFFF;
                            background-color: rgb(237, 0, 0);
                            border-color: rgb(237, 0, 0);
                        }
                    }   
                }
            }
        }
    }

    .ngb-dp-header{
        .ngb-dp-arrow{
            .ngb-dp-arrow-btn{
                color: #b1b2b3;
                span{
                    border-width: 2px 2px 0 0;
                }
            }
        } 
    }

    .input-group-calendar{
        display: flex;
        flex-wrap: nowrap;
        input {
            &.ng-valid {
                border: 1px solid $colorFormBorder;
                padding-top: 19px;
                & ~ label {
                    color: black;
                    @extend .labelActive;
                }
            }
        }
        .input-field {
            flex-basis: 85%
        }
        .input-icon {
            flex-basis: 15%
        }
        .input-group-append{    
            cursor: pointer; 
            border: 1px solid $colorFormBorder;
            border-left: 0;
            height: 46px;
            button{
                background-color: $colorWhite;
                // vertical-align: middle;
                display: block;
                border-radius: 0;
                border: none;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                
                span{
                    background-image: url('../Icons/relaunch/ic_calendar_datepicker_red.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 28px;
                    width: 28px;
                    position: relative; 
                    vertical-align: middle;
                }
            }
        }
    }
}


.mat-form-field{
    width: 100%;
    // .mat-form-field-infix{
    //     border: 1px solid rgba(87,86,86,.3);
    //     height: 45px;
    //     padding: 5px 15px;
    //     outline: 0;
    //     color: #ed0000;
    //     text-shadow: 0 0 0 #000;

    //     .mat-select-trigger{
    //         .mat-select-placeholder{
    //             color: #575656;
    //             padding: 14px 15px;
    //             font-size: 14px;
    //             height: 46px;
    //             top: 0;
    //             left: 0;
    //             margin-bottom: 0;
    //             font-weight: 300;
    //         }
    //     }
    // }
    
}

.mat-option{
    .mat-active{
        color: #ed0000 !important;
    }
}

.mat-focused{
    .mat-form-field-ripple{
        background-color: #575656 !important;
    }
}

.labelActive {
    height: auto;
    padding: 5px 15px 0;
    // z-index: 2;
    font-size: 10px;
    line-height: 16px;
    font-weight: bold;
}