.editableText {
    margin-bottom: 4px;
    ul {
        margin-left: 20px;
        li {
            word-wrap: break-word;
            hyphens: auto;
            color: $colorBlack;
            line-height: 18px;
            font-size:15px;
            @include fromDesktop(){
                font-size:16px;
            }
        }
    }
}

.textEdit {
    &__header {
        position: relative;
        height: 25px;
    }
    &__close {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 16px;
        font-size: 30px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
    }
    &__content {
        width: 1000px;
    }
    &__textLength {
        margin-top: 10px;
    }
    &__saveBtn {
        margin-top: 10px;
        float: right;
    }
} 
