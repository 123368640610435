.o-030 {
    position: relative;
  
    &-white {
      color: $colorWhite;
    }
  
    &-black {
      color: $colorBlack;
    }
  
    &.full-width {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  
    &-images {
      position: relative;
      margin: 0 auto;
      @media screen and (min-width: 992px) and (max-width: 1279px) {
        min-height: 430px;
        width: 100%;
        overflow-x: hidden;
        .desktop {
          min-height: 430px;
          img {
  
            min-height: 430px;
            object-fit: cover;
          }
        }
      }
      img {
        display: block;
        margin: 0 auto;
      }  
    }
  
    &-container {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 1920px;
      margin: 0 auto;
      @include toDesktop {
        justify-content: center;
      }
      @media screen and (min-width: 992px) and (max-width: 1279px) {
        justify-content: center;
      }
  
    }
  
    &-countdown {
      display: flex;
      flex-direction: column;
      margin-top: -10px;
      margin-right: 150px;
  
      @include toDesktop {
        margin: 0 18px;
      }
  
      @include toTablet {
        margin-top: -5px;
      }
      @media screen and (min-width: 992px) and (max-width: 1279px) {
        margin-right: auto;
        margin-left: auto;
      }
  
      &-headline {
        display: flex;
        justify-content: center;
        align-items: center;
  
        &-wrapper {
          display: flex;
          flex-direction: column;
        }
      }
  
      &-timer {
        display: grid;
        grid-template-columns: 1fr 72px 1fr 72px 1fr 72px 1fr 72px 1fr;
        padding: 24px 0 40px 0;
  
        @include toTablet {
          padding: 16px 0 25px 0;
          grid-template-columns: 1fr 17px 1fr 17px 1fr 17px 1fr 17px 1fr;
        }
  
        &-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
  
          &-label {
            font-family: $font-primary-regular;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            margin-top: 7px;
  
            @include toTablet {
              margin-top: 4px;
              font-size: 9px;
              line-height: 11px;
            }
          }
        }
  
        &-item-value, &-divider {
          font-family: $font-primary-regular;
          font-size: 60px;
          line-height: 72px;
          text-align: center;
  
          @include toTablet {
            font-size: 21px;
            line-height: 25px;
          }
        }
      }
    }
  
    &-goal {
      display: none;
      padding: 30px 0;
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      max-width: 654px;
      margin: 0 auto;
  
      @include toDesktop {
        font-size: 12px;
        line-height: 20px;
      }
  
      @include toTablet {
        padding: 15px 0;
      }
    }
  
    &-cta {
      &-white {
        border: 2px solid $colorWhite;
        span {
          color: $colorWhite;
        }
      }
  
      &-black {
        border: 2px solid $colorBlack;
        span {
          color: $colorBlack;
        }
      }
  
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 250px;
      padding: 15px;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
  
      @include toDesktop {
        min-width: 136px;
        padding: 9px;
      }
  
      span {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
  
        @include toDesktop {
          font-size: 12px;
          line-height: 14px;
        }
      }
  
      &-icon {
        display: flex;
        align-items: center;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
  
        @include toDesktop {
          right: 10px;
        }
  
        svg {
          @include toDesktop {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  
    &-font {
      margin: 0;
      padding: 0;
      line-height: normal;
      font-weight: normal;
  
      &-mobile {
        &-12 {
          @include toTablet {
            font-size: 12px;
          }
        }
        &-14 {
          @include toTablet {
            font-size: 14px;
          }
        }
        &-16 {
          @include toTablet {
            font-size: 16px;
          }
        }
        &-18 {
          @include toTablet {
            font-size: 18px;
          }
        }
        &-20 {
          @include toTablet {
            font-size: 20px;
          }
        }
        &-22 {
          @include toTablet {
            font-size: 22px;
          }
        }
        &-24 {
          @include toTablet {
            font-size: 24px;
          }
        }
        &-26 {
          @include toTablet {
            font-size: 26px;
          }
        }
        &-28 {
          @include toTablet {
            font-size: 28px;
          }
        }
        &-30 {
          @include toTablet {
            font-size: 30px;
          }
        }
        &-32 {
          @include toTablet {
            font-size: 32px;
          }
        }
        &-34 {
          @include toTablet {
            font-size: 34px;
          }
        }
        &-36 {
          @include toTablet {
            font-size: 36px;
          }
        }
        &-38 {
          @include toTablet {
            font-size: 38px;
          }
        }
        &-40 {
          @include toTablet {
            font-size: 40px;
          }
        }
        &-42 {
          @include toTablet {
            font-size: 42px;
          }
        }
        &-44 {
          @include toTablet {
            font-size: 44px;
          }
        }
        &-46 {
          @include toTablet {
            font-size: 46px;
          }
        }
        &-48 {
          @include toTablet {
            font-size: 48px;
          }
        }
      }
      &-tablet {
        &-12 {
          @include fromTablet {
            font-size: 12px;
          }
        }
        &-14 {
          @include fromTablet {
            font-size: 14px;
          }
        }
        &-16 {
          @include fromTablet {
            font-size: 16px;
          }
        }
        &-18 {
          @include fromTablet {
            font-size: 18px;
          }
        }
        &-20 {
          @include fromTablet {
            font-size: 20px;
          }
        }
        &-22 {
          @include fromTablet {
            font-size: 22px;
          }
        }
        &-24 {
          @include fromTablet {
            font-size: 24px;
          }
        }
        &-26 {
          @include fromTablet {
            font-size: 26px;
          }
        }
        &-28 {
          @include fromTablet {
            font-size: 28px;
          }
        }
        &-30 {
          @include fromTablet {
            font-size: 30px;
          }
        }
        &-32 {
          @include fromTablet {
            font-size: 32px;
          }
        }
        &-34 {
          @include fromTablet {
            font-size: 34px;
          }
        }
        &-36 {
          @include fromTablet {
            font-size: 36px;
          }
        }
        &-38 {
          @include fromTablet {
            font-size: 38px;
          }
        }
        &-40 {
          @include fromTablet {
            font-size: 40px;
          }
        }
        &-42 {
          @include fromTablet {
            font-size: 42px;
          }
        }
        &-44 {
          @include fromTablet {
            font-size: 44px;
          }
        }
        &-46 {
          @include fromTablet {
            font-size: 46px;
          }
        }
        &-48 {
          @include fromTablet {
            font-size: 48px;
          }
        }
      }
      &-desktop {
        &-12 {
          @include fromDesktop {
            font-size: 12px;
          }
        }
        &-14 {
          @include fromDesktop {
            font-size: 14px;
          }
        }
        &-16 {
          @include fromDesktop {
            font-size: 16px;
          }
        }
        &-18 {
          @include fromDesktop {
            font-size: 18px;
          }
        }
        &-20 {
          @include fromDesktop {
            font-size: 20px;
          }
        }
        &-22 {
          @include fromDesktop {
            font-size: 22px;
          }
        }
        &-24 {
          @include fromDesktop {
            font-size: 24px;
          }
        }
        &-26 {
          @include fromDesktop {
            font-size: 26px;
          }
        }
        &-28 {
          @include fromDesktop {
            font-size: 28px;
          }
        }
        &-30 {
          @include fromDesktop {
            font-size: 30px;
          }
        }
        &-32 {
          @include fromDesktop {
            font-size: 32px;
          }
        }
        &-34 {
          @include fromDesktop {
            font-size: 34px;
          }
        }
        &-36 {
          @include fromDesktop {
            font-size: 36px;
          }
        }
        &-38 {
          @include fromDesktop {
            font-size: 38px;
          }
        }
        &-40 {
          @include fromDesktop {
            font-size: 40px;
          }
        }
        &-42 {
          @include fromDesktop {
            font-size: 42px;
          }
        }
        &-44 {
          @include fromDesktop {
            font-size: 44px;
          }
        }
        &-46 {
          @include fromDesktop {
            font-size: 46px;
          }
        }
        &-48 {
          @include fromDesktop {
            font-size: 48px;
          }
        }
      }
    }
  }