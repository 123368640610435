.m-0210 {
    margin: 0 auto 40px auto;
    padding: 0 17px;
    @include fromTablet {
        padding: 0;
    }
    @include fromDesktop {
        margin-bottom: 80px;
    }
    &__headline {
        @include font-primary(h1);
            @include fromDesktop {
            margin-top: 60px;
            margin-bottom: 30px;
            max-width: 500px;
        }
    }
    .a-01600 {
        @include fromDesktop {
            padding: 30px 300px 30px 0;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .text-wrapper {
            align-items: start;
            flex-direction: column;
            justify-content: center;
            p {
                margin: 0;
            }
        }
        .text {
            @include fromDesktop {
                margin-left: 0;
                padding-bottom: 20px;
            }
        }
        .icon {
            @include fromDesktop {
                flex-basis: 200px;
                flex-shrink: 0;
                flex-grow: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                flex-direction: column;
            }
            svg {
                @include fromDesktop {
                    max-width: 110px;
                }
                &.icon_calculator {
                    @include fromDesktop {
                        max-width: 85px;
                    }
                }
            }
        }
    }
}