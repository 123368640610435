.a-01900 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 85px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    .icon {
        width: 40px;
        height: 32px;
        display: block;
        margin: 0 auto;
        svg {
            width: 100%;
            height: 100%;
            @include fillSVG($colorBlack);
            &.svg-icon-gebrauchtwagen {
                width: 28px;
            }
        }
    }

    .textIcon{
        width: 10px;
        display: none;
        
        svg{
            width: 100%;
            height: 100%;
        }
    }

    .text {
        color: $colorBlack;
        // font-size: 12px;
        // line-height: 16px;
        // font-weight: bold;
        // text-transform: uppercase;
        margin-top: 7px;
        text-align: center;
        @include font-primary(cta);
    }
    &:hover, &:focus {
        outline: 0;
    }
    &:hover {
        .icon {
            // background-color: $colorDarkGrey;
            svg {
                fill: $colorDarkGrey;
                @include fromDesktop {
                    @include fillSVG($colorDarkGrey);
                }
            }
        }

        .text {
            @include fromDesktop {
                color: $colorDarkGrey;
            }
        }
    }
    &.active {
        .icon {
            //background-color: $colorRed;
            svg {
                @include fillSVG($colorRed);
            }
        }

        .text {
            color: $colorRed;
            .textIcon{
                display: inline-block !important;
            }
        }

    }
    &.disabled {
        cursor: default;
        .icon {
            // background-color: $colorGrey;
            @include fillSVG($colorGrey);
        }
        .text {
            color: $colorGrey;
        }
    }
}

px-a01900 {
    @extend .a-01900;
    .a-01900 {
        display: block;
        max-width: 100%;
        border: 0;
        background-color: transparent;
    }
}