// Service
.service {
  &__hr {
    margin-top: 20px;
  }

  &__headline {
    @include fromDesktop {
      margin-top: 40px;
    }
  }
}

// Kontakt
.contact-form {
  padding: 23px 0;

  @include fromTablet {
    padding: 37px 0;
  }

  @include fromDesktop {
    padding: 74px 0;
  }

  &__required {
    font-size: 12px;
  }

  &__data-policy-link {
    font-size: 10px;

    @include fromDesktop {
      font-size: 14px;
    }
  }

  &__top-text {
    font-size: 10px;

    @include fromDesktop {
      font-size: 14px;
      text-align: center;
    }
  }

  &__second-wrapper {
    @include fromTablet {
      padding-top: 25px;
    }

    @include fromDesktop {
      padding-top: 85px;
    }
  }
}

// fahrzeug reservieren
.vehicleReservation {
  button.mat-mini-fab.mat-accent {
    position: absolute !important;
    right: -30px;
    background: $colorBlack;
    height: 20px;
    width: 20px;
    top: 14px;
    box-shadow: none;

    .mat-button-wrapper {
      display: block;
      padding: 0;
      line-height: 20px;
    }
  }

  &__tooltip {
    position: absolute;
    right: -30px;
    height: 20px;
    width: 20px;
    top: 14px;
    z-index: 10;
    background: transparent;
    border: 0 solid transparent;
  }

  .borderItem {
    border: 1px solid $colorBorderGray;
    padding: 15px;

    &--minHeight {
      min-height: 100%;
    }
  }

  .infoContainer {
    padding: 24px;
    padding-right: 33px;
  }

  .vehicleDetail-m0170 {
    padding-left: 25px;
    padding-bottom: 12px;
    padding-right: 8px;
    padding-top: 19px;

    .a-03300 {
      .description {
        font-size: 11px;
      }

      .type {
        font-size: 11px;
        font-weight: bold;
      }

      a {
        font-size: 11px;
      }
    }
  }

  .mat-button.mat-primary .mat-button-focus-overlay {
    background-color: transparent;
    border-radius: 0;
    color: black;

    span,
    p {
      color: black;
    }
  }

  .dataPolicyDisclaimer,
  .dataPolicyDisclaimer a {
    font-size: 12px;
    line-height: 18px;
    color: $colorDarkGrey;
  }

  .dataPolicyDisclaimer a {
    font-family: $font-primary-regular;
    font-weight: 400;
    text-decoration: underline;
  }

  .obligationText {}

  .copy {
    color: $colorDarkGrey;
  }

  .vehicleModel {
    // @include font-primary(28px);
    @include font-primary(h1);
  }

  .vehicleTitle {
    // @include font-primary(19px);
    @include font-primary(copy);
  }

  .vehiclePrice {
    // @include font-primary(49px, $colorBlack, bold);
    @include font-primary(price);
  }

  .vehiclePriceMwSt {
    @include font-primary(copy);

    @include toDesktop {
      font-size: 14px;
    }
  }

  .a-03700 {
    @include fromDesktop {
      margin-top: -20px;
    }

    &__nav__item__bullet .iconCheck {
      display: none !important;
    }

    &__nav__item__bullet {
      position: absolute !important;
      top: 0 !important;
    }

    &__nav__item__label {
      font-size: 12px;
      font-family: $font-primary-bold;
      color: transparent;
    }

    &__nav__item__label__line {
      top: 7px !important;
    }

    &__nav__item__line {
      margin-top: 7px;
    }

    &__nav__item {
      position: relative;
      text-align: center;

      .a-03700__nav__item__bullet {
        &:before {
          position: relative;
          width: 12px !important;
          height: 12px !important;
          border-radius: 6px !important;
          top: -2px !important;
          left: -2px !important;
        }
      }

      &.active {
        .a-03700__nav__item__label {
          color: $colorWhite;
        }

        .a-03700__nav__item__bullet {
          &:before {
            width: 18px !important;
            height: 18px !important;
            border-radius: 10px !important;
            top: -4px !important;
            left: -1px !important;
          }
        }
      }
    }

    &__nav__item__label__number {
      position: absolute;
      left: 0px;
      width: 16px;
      top: -2px;

      @include toDesktop {
        top: 2px;
      }
    }
  }

  &__headline {
    // font-size: 24px;
    // line-height: 29px;
    @include font-primary(h1);
    margin-top: 40px;
  }

  &__headlineSmall {
    @include font-primary(h1);
    margin-top: 40px;
  }

  &__subheadline {
    @include font-primary(copy);
    color: $colorDarkGrey;
  }

  // .a-02400 {
  //   .labelText {
  // font-size: 14px;
  //     font-family: $font-primary-bold;
  //     font-weight: 400;
  //   }
  // }
  .a-02100 {
    .mat-form-field-appearance-legacy {

      .mat-form-field-wrapper,
      .mat-form-field-infix {
        padding: 0 !important;
      }
    }

    .mat-form-field-label-wrapper {
      padding-top: 0;
      top: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    label.icon {
      position: absolute;
      width: 26px;
      height: 26px;
      padding: 0;
      left: 13px;
      top: 10px;

      svg {
        width: 26px;
        height: 26px;

        path {
          fill: $colorDarkGrey !important;
        }
      }

      &.hasError {
        svg {
          path {
            fill: $colorRed !important;
          }
        }
      }
    }

    input.withIcon {
      padding-left: 53px;

      &+label {
        padding-left: 53px;
      }
    }
  }

  .m-0170__detaillist px-a03300 {
    @include fromDesktop {
      margin-top: 29px;
      flex-basis: 30.9%;
      flex-grow: 1;
    }
  }

  .a-04000 {
    &--a {
      width: 100%;
      // min-height: 130px;
      background-color: $colorNavigationGray;
      color: darken($colorNavigationGray, 50%);
      // font-size: 13px;
      @include font-primary(copy);
      padding: 10px;
      position: relative;

      @include fromDesktop {
        padding: 15px 20px;
      }
    }

    .bold {
      font-family: $font-primary-bold;
      font-weight: 400;
    }

    strong {
      font-family: $font-primary-bold;
      font-weight: 400;
      text-transform: uppercase;
      // font-size: 12px;
    }

    .content {
      p {
        width: 90%;
        // font-size: 12px;
        // line-height: 18px;
        // @include fromDesktop {
        //     font-size: 14px;
        // }
        @include font-primary(copy);

        &:last-child {
          margin-bottom: 0;
        }
      }

      img,
      span {
        &.info {
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 30px;
          background-color: $colorBlack;
          color: $colorWhite;
          text-align: center;
          font-size: 26px;
          line-height: 29px;

          svg {
            vertical-align: top;
            // top: -2px;
            position: relative;
          }
        }
      }

      strong {
        &.tel {
          font-size: 12px;
          line-height: 18px;

          @include fromDesktop {
            font-size: 14px;
          }
        }
      }
    }

    hr {
      margin: 15px 0 15px 0;
      background-color: darken($colorNavigationGray, 5%);
    }

    .footer {

      img,
      span {
        &.edit {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 10px;

          svg {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      strong {
        &.description {
          color: $colorBlack;
          font-family: $font-primary-regular;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .form {
      .input {
        border: 1px solid darken($colorNavigationGray, 20%);
        margin: 15px 0 15px 0;
        background-color: $colorWhite;
        padding: 3px 10px;

        &.hasError {
          border: 1px solid $colorRed !important;

          &+label {
            color: $colorRed !important;
          }
        }

        span {
          font-size: 12px;
          line-height: 16px;
          color: $colorBlack;
          font-weight: bold;

          @include fromDesktop {
            font-size: 14px;
          }
        }

        input {
          border: 0;
          width: 100%;
          outline: none;
          padding: 0;
          font-size: 14px;
          line-height: 12px;
          position: relative;
          top: -2px;
        }
      }

      input {
        background-color: $colorWhite;
      }

      input[type="submit"] {
        background-color: transparent;
        border: 2px solid $colorBlack;
        text-transform: uppercase;
        padding: 10px;
        width: 100%;
        font-weight: bold;
      }
    }
  }

  .a-03900 {
    .wrap {
      padding: 9px 20px;
      border: 1px solid $colorGrey;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;
      max-height: 50px;

      input {
        margin-left: 1ch;
        letter-spacing: 1ch;
        display: inline-block;
        border: none;
        text-align: left;
        outline: none;
        caret-color: $colorRed;
        font-weight: bold;
        color: $colorDarkGrey;
        font-size: 20px;
        font-variant-numeric: tabular-nums;
        text-indent: 0.3ch;
        background: repeating-linear-gradient(90deg, $colorGrey 0, $colorGrey 1.6ch, transparent 0, transparent 2ch) 0 100% / calc(100% - 0.5ch) 1px no-repeat;

        &:focus {
          outline-color: transparent;
          outline-style: none;
        }

        @include fromDesktop {
          font-size: 24px;
        }
      }

      &.focus {
        border: 1px solid $colorBlack;
      }

      .icon {
        display: none;
        width: 19px;
        height: 19px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        @include fromDesktop {
          right: 25px;
        }

        &.iconValid {
          svg {
            top: -4px;
            position: relative;
          }
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &.hasError {
        border: 1px solid $colorRed;

        .icon {
          border-radius: 20px;
          background: $colorRed;
          padding: 5px;

          &.iconError {
            display: block;

            svg {
              width: 50%;
              height: 50%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              polyline {
                fill: $colorWhite !important;
              }
            }
          }
        }
      }

      &.isValid {
        .icon {
          &.iconValid {
            display: block;

            // svg {
            //     // @include fillSVG($colorStatusOne);
            // }
          }
        }
      }
    }
  }

  &__smsBtn {
    max-width: 100%;
    margin-top: 20px;
  }

  &__stickyBtn {
    border: 0 solid transparent;

    @include toDesktop {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1052;
      height: 50px;
      margin-bottom: 0;
      border-bottom: 2px solid $colorWhite !important;
      font-family: $font-primary-bold;
    }
  }

  .a-03800 {
    border: 0 solid transparent;
    line-height: 24px;

    @include fromDesktop {
      font-size: 14px;
    }
  }

  &__nextSteps {
    padding: 30px;
    background-color: $colorLightGrey;

    @include toDesktop {
      padding: 15px;
    }

    h2 {
      color: $colorDarkGrey;
      margin-bottom: 30px;

      @include toDesktop {
        margin-bottom: 15px;
      }
    }
  }

  &__nextStepWrapper {
    display: flex;
    align-items: center;
  }

  &__nextStepsIcon {
    width: 23px;
    height: 23px;
    flex-grow: 0;

    svg {
      width: 23px;
      height: 23px;

      path {
        fill: $colorDarkGrey;
      }
    }
  }

  &__nextStepsCopy {
    padding-left: 25px;
    flex-grow: 1;
    color: $colorDarkGrey;
    word-break: break-word;
    hyphens: auto;
  }

  &__completeRow {
    @media screen and (min-width: 992px) {
      min-height: 165px;
    }

    @media screen and (min-width: 1244px) {
      min-height: 215px;
    }

    align-items: flex-end;
    padding-bottom: 15px;

    h1 {
      margin-bottom: 20px;
    }
  }

  &__garantieImg {
    max-width: 159px;
    margin-bottom: 30px;

    @include toDesktop {
      margin-top: 20px;
    }
  }

  &__garantieHeadline {
    @include font-primary(copy);
    margin-bottom: 30px;
  }

  &__garantieCopy {
    @include font-primary(note);

    @include toDesktop {
      margin-bottom: 30px;
    }

    a {
      @include font-primary(note);
      text-decoration: underline;
    }
  }

  &__detailCol {
    @include toDesktop {
      margin-top: 30px;
    }
  }

  &__radio {
    input[type="radio"] {
      position: absolute;
      left: -9999px;

      &+label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 25px;
        display: inline-block;
        // color: #666;
        font-size: 12px;
        padding-left: 44px;
        font-weight: bold;
        text-transform: uppercase;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 25px;
          height: 25px;
          border: 1px solid $colorGrey;
          border-radius: 100%;
          background: $colorWhite;
        }

        &:after {
          content: "";
          width: 17px;
          height: 17px;
          background: $colorRed;
          position: absolute;
          top: 4px;
          left: 4px;
          border-radius: 100%;
          // transition: all 0.2s ease;
        }
      }

      &:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      &:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  &__radio+&__radio {
    margin-left: 50px;
  }

  &__radioLabel {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__radioGroup {
    display: flex;
    flex-direction: row;
  }
}

.step1 button,
.step2 button,
.step3 button {
  width: 100%;

  &[disabled] {
    opacity: 1;
    background-color: $colorGrey;
  }
}

//Fahrzeuge
.cars {
  background-color: $colorLightGrey;
}

.cars-detail {
  &__container {
    margin: 0 auto;
    max-width: 100%;
    padding: 20px 27px 20px 27px;

    @include fromPhone {
      max-width: $container-width-sm;
    }

    @include fromTablet {
      max-width: $container-width-md;
    }

    @include fromDesktop {
      padding: 70px 15px 70px 15px;
      max-width: $container-width-lg;
    }

    @include fromWide {
      max-width: $container-width-xl;
    }
  }

  &__headline {
    @include font-primary(h1);

    &:empty,
    &.empty {
      margin: 0 !important;
    }
  }

  &__notes {
    @include toDesktop() {
      padding-top: 11px;
    }

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      width: 100%;
    }

    @include font-primary(copy, $colorDarkGrey);

    ul {
      display: flex;
      list-style: none;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 0;

      li {
        flex-basis: 100%;
        margin-bottom: 17px;
        display: flex;
        @include font-primary(copy, $colorDarkGrey);

        &:first-child {
          margin-top: 12px;
        }

        @include fromDesktop {
          margin-bottom: 0;
          margin-top: 12px;
          flex-basis: 33%;
        }

        &::before {
          content: url(../Icons/relaunch/icon_check_highlights.svg);
          width: 13px;
          height: 13px;
          position: relative;
          top: -1px;
          display: block;
          margin-right: 5px;
          flex-shrink: 0;
          flex-grow: 0;
        }
      }
    }
  }
}

.step1 button,
.step2 button {
  width: 100%;
}

px-expander-list {
  display: block;
  width: 100%;
}

//OPV
.opv {
  :ng-deep .backLink {
    cursor: pointer;
  }

  @include fromDesktop {
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    z-index: 1;
    background-color: $colorWhite;
  }

  &__mobileHeadline {
    @include font-primary(h1);
    margin-top: 5px;
    margin-bottom: 20px;
  }

  &__mobileHeadlineTime {
    @include font-primary(h2);
    margin-top: 20px;
    margin-bottom: 0px;
  }

  &__nav {

    // font-family: 'LatoWeb', Helvetica, Arial, sans-serif;
    @include toDesktop {
      padding: 0;
    }

    .a-03700 {
      &__nav__item {

        // &.current {
        //     font-family: 'LatoWebBold', Helvetica, Arial, sans-serif;
        // }
        &__label {
          @include fromDesktop {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__wrapper {
    margin-bottom: 80px;
    // font-family: 'LatoWeb', Helvetica, Arial, sans-serif;
  }

  &__stepper {
    @include toDesktop {
      padding: 0;
    }

    .a-03700__nav__item.active .a-03700__nav__item__bullet .iconCheck {
      bottom: 3px !important;

      @include fromDesktop {
        bottom: 8px !important;
      }

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__content {
    overflow: hidden;

    @include toDesktop {
      flex-direction: column-reverse;
    }
  }

  &__contentCol {
    overflow: hidden;
  }

  &__contentColInner {
    min-height: 100%;
    position: relative;

    &>div {
      width: 100%;
    }
  }

  &__gradient {
    height: 20px;
    margin-left: -30px;
    margin-right: -30px;

    @include fromDesktop {
      margin-bottom: 80px;
    }

    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top,
      rgba(255, 255, 255, 1) 0%,
      rgba(243, 243, 243, 1) 5%,
      rgba(237, 237, 237, 1) 6%,
      rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(top,
      rgba(255, 255, 255, 1) 0%,
      rgba(243, 243, 243, 1) 5%,
      rgba(237, 237, 237, 1) 6%,
      rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(243, 243, 243, 1) 5%,
      rgba(237, 237, 237, 1) 6%,
      rgba(255, 255, 255, 0) 100%);
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;

    @include toDesktop {
      padding: 0 22px;
    }

    .opv__gradient {
      margin-bottom: 0;
    }
  }

  &__stickyWrapper {
    height: 80px;

    &.firstPage,
    &.lastPage {
      height: auto;

      .opv__gradient {
        margin-bottom: 0 !important;
      }
    }

    @include fromDesktop {
      height: 175px;

      &.firstPage {
        height: auto;

        .opv__gradient {
          margin-bottom: 65px;
        }
      }

      &.lastPage {
        height: auto;

        .opv__gradient {
          margin-bottom: 65px !important;
        }
      }
    }
  }

  &__modelImage {
    .a-00510 {
      img {
        @include fromDesktop {
          max-width: 100% !important;
          min-width: 100%;
        }
      }
    }
  }

  &__footnote {
    @include font-primary(tag);
    margin-top: 10px;
  }

  &__botNav {
    @include fromDesktop {
      position: fixed;
      display: flex;
      height: 118px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $colorLightGrey;
      z-index: 10;
      flex-direction: column;
      justify-content: center;
    }

    otv-a03800 {
      .a-03800 {
        @include toDesktop {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1055;
          padding: 15px 10px;
        }

        @include fromDesktop {
          margin: 15px 0;
        }

        font-family: $font-primary-regular;
      }
    }
  }

  .opv_envkv {
    strong {
      font-family: $font-primary-bold;
      font-weight: normal;
    }
  }

  &__bullet {
    &:nth-child(3) {
      @include fromDesktop {
        margin-top: 40px;
        display: block;
      }

      .a-04400 {
        &::before {
          @include fromDesktop {
            border-top: 1px solid $colorLightGrey;
            content: "";
            display: block;
            width: 100%;
            position: relative;
            bottom: 25px;
          }
        }

        .icon {
          @include fromDesktop {
            width: 40px !important;
            min-width: 40px !important;
            height: 40px !important;
          }
        }

        .wrap {
          @include fromDesktop {
            margin-left: 30px !important;
          }
        }
      }
    }

    &:last-child {
      .a-04400 {
        &:after {
          @include fromDesktop {
            border-bottom-color: transparent !important;
          }
        }
      }
    }

    .a-04400 {
      padding: 15px 0px !important;

      @include fromDesktop {
        padding: 25px 15px !important;
      }

      &:after {
        @include fromDesktop {
          border-bottom-color: $colorLightGrey !important;
          top: 25px !important;
        }
      }
    }

    &--returnDay {
      .a-04400 {
        &::before {
          @include toDesktop {
            border-top: 1px solid $colorLightGrey;
            content: "";
            display: block;
            width: 100%;
            position: relative;
            bottom: 15px;
          }
        }
      }
    }

    &--line {
      @include toDesktop {
        border-top: 1px solid $colorLightGrey;
      }
    }
  }

  .m-0380 {
    @include toDesktop {
      margin-left: -15px;
      margin-right: -15px;
    }

    &__required {
      @include toDesktop {
        display: none;
      }
    }

    h3 {

      // font-family: 'LatoWebBold', Helvetica, Arial, sans-serif;
      @include toDesktop {
        display: none;
      }
    }
  }

  &__contact {
    @include toDesktop {
      padding: 0;

      o-240 .wrap {
        padding: 25px 15px 0;
      }
    }
  }

  &__pickerMobileHeadline {
    margin-top: 10px;
    @include font-primary(h2);
  }

  &__pickerMobilePickerBtn {
    display: block;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  &__pickerDesktopHeadline {
    margin-left: 12px;
    font-size: 24px;
    margin-bottom: 0;
    line-height: 36px;

    &+.opv__footnote {
      margin-left: 12px;
      margin-bottom: 40px;
    }
  }

  &__backLink {
    display: block;
    padding: 10px;

    .text {
      text-transform: uppercase;
      text-align: center;
      @include font-primary(h4, $colorDarkGrey, bold);
      line-height: 20px;
      // color: $colorDarkGrey;
    }

    .icon {
      display: inline-block;
      margin-right: 5px;
      color: black;
      position: relative;

      svg {
        width: 15px;
        height: 15px;
        margin-bottom: 4px;
      }
    }

    &:hover {
      .text {
        color: $colorDarkGrey;
      }

      .icon {
        animation-name: moveBackLink;
        animation-duration: 0.2s;

        svg {
          @include fillSVG($colorDarkGrey);
        }
      }
    }
  }

  .o-290 {
    @include fromDesktop {
      margin-top: 85px;
    }
  }

  .m-0340 {
    .a-03800 {
      @include toDesktop {
        z-index: 1056 !important;
      }

      @include fromDesktop {
        display: none;
      }
    }
  }

  .noFootnote {
    .m-0380__required {
      display: none;
    }
  }

  .a-00800 {
    cursor: pointer;
  }

  .o-240 .phoneNote p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: $colorDarkGrey !important;
  }

  .opv__pickerDesktopHeadline,
  .a-00700 .text,
  .a-00800 .text,
  .a-01010 .text,
  .a-02000 .label,
  .a-02000 .linkWrapper a,
  .labelActive,
  .a-02400 .labelText,
  .a-03700__nav__item.current,
  .a-03800 a,
  .a-03900 .wrap input,
  .a-04000--a .form .input span,
  .a-04000--a .form input[type="submit"],
  .a-04200 .date-header,
  .a-04300,
  .a-04300 a,
  .m-0330 .row .wrap .headline,
  .o-240 .header .headline,
  .o-240 .backLink .text .o-250 .header .headline,
  .o-250 .wrap .header .headline,
  .o-250 .backLink .text .o-280__wrapper .header h1,
  .o-290 .headline,
  .backLink .text,
  .o-250 .header .subHeadline {
    font-family: $font-primary-regular !important;
    font-weight: 400 !important;
  }

  @include fromDesktop {

    // .a-04000--a .bold,
    .a-04000--a strong,
    .m-0380 h3 {
      font-family: $font-primary-regular !important;
      font-weight: 400 !important;
    }
  }

  .a-04000--a .bold,
  .o-250 .headline strong {
    font-family: $font-primary-bold !important;
    font-weight: 400 !important;
  }

  .a-04400 .a04400-head,
  .a-04200 .date-red,
  .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation,
  .ngb-dp-month-name,
  .o-250 .header .formHeadline {
    font-family: $font-primary-bold !important;
    font-weight: 400 !important;
  }

  // .a-03800 a {
  //     font-family: $font-primary-regular !important;
  // }
  .a-04100 ngb-datepicker .ngb-dp-header ngb-datepicker-navigation {
    height: 34px !important;

    .ngb-dp-arrow.right .ngb-dp-arrow-btn {
      left: auto !important;
      right: 0 !important;

      @include toDesktop {
        display: none;
      }
    }

    .ngb-dp-arrow-btn {
      left: 0 !important;
      opacity: 1 !important;

      @include toDesktop {
        display: none;
      }
    }
  }

  .o-260 {
    margin: 0 auto;

    .m-0330 otv-a04400:nth-child(3) .a-04400 .row {
      .icon {
        width: 25px;
        min-width: 25px;
        margin-right: 0px;
      }
    }

    @include toDesktop {
      .m-0020 {
        display: none;
      }
    }

    @include fromDesktop {
      .m-0330 otv-a04400:nth-child(3) .a-04400 .row {
        .icon {
          width: 30px;
          min-width: 30px;
          height: 25px;
          margin-right: 5px;
        }

        .wrap {
          margin-left: 10px !important;
        }
      }
    }
  }

  .ngb-dp-month-name {
    font-size: 14px !important;
    line-height: 18px !important;

    @include fromDesktop {
      font-size: 20px !important;
      line-height: 32px !important;
    }
  }

  .a-03700__nav__item__label {
    white-space: nowrap;
    word-break: keep-all;
  }

  .a-03800.disabled {
    color: $colorGrey;
    cursor: default;

    svg {
      @include fillSVG($colorGrey);
    }

    a {
      display: block;
      cursor: pointer;
    }

    div {
      min-width: 15px;

      &.prev {
        border-right: 1px solid $colorDarkGrey;
        padding-right: 10px;
        height: 20px;
        align-items: center;

        a {
          display: flex;
          height: 20px;
          align-items: center;
        }
      }

      &.wrap {
        display: flex;
        align-items: center;

        .text-right {
          margin-right: 15px;
        }
      }
    }
  }

  .a-02100 input.isFilled+label,
  .a-02100 input.isValid+label {
    color: $colorDarkGrey !important;
  }

  .backLink {
    display: block;
    padding: 16px 10px;
    margin: 15px 0;
    cursor: pointer;

    .text {
      // text-transform: uppercase;
      text-align: center;
      // font-size: 18px;
      // line-height: 20px;
      // color: $colorDarkGrey;
      @include font-primary(cta);
    }

    .icon {
      display: inline-block;
      margin-right: 5px;
      color: black;
      position: relative;

      svg {
        width: 15px;
        height: 15px;
        margin-bottom: 4px;
      }
    }

    &:hover {
      .text {
        color: $colorDarkGrey;
      }

      .icon {
        animation-name: moveBackLink;
        animation-duration: 0.2s;

        svg {
          @include fillSVG($colorDarkGrey);
        }
      }
    }
  }
}

.opv-rating {
  &__container {
    padding: 0 14px;

    @include fromDesktop {
      padding: 0;
      padding-top: 150px;
      padding-bottom: 180px;
    }
  }

  &__model-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &__last-col {
    @include fromWide {
      padding-left: 130px;
    }
  }

  &__headline {
    display: none;

    @include fromDesktop {
      display: block;
    }
  }

  &__question {
    @include font-primary(h1);
  }

  &__thanks {
    @include font-primary(h1);
    text-align: center;

    @include fromDesktop {
      text-align: left;
    }
  }

  &__not-rateable {
    @include font-primary(h1);
    text-align: center;

    @include fromDesktop {
      text-align: left;
    }
  }

  &__button-rating {
    background: none;
    border: 0;
    margin-right: 23px;
    margin-top: 5px;

    @include fromDesktop {
      margin-top: 15px;
    }

    svg {
      height: 36px;
      width: 34px;

      polygon {
        fill: $colorBorderGray !important;
      }
    }

    &.active {
      svg {
        polygon {
          fill: $colorRed !important;
        }
      }
    }
  }

  &__cta-button {
    position: fixed;
    bottom: 50px;
    width: 100vw;
    left: 0;
    z-index: 1;

    @include fromDesktop {
      position: relative;
      bottom: 0;
      width: auto;
    }

    .a-00800 {
      max-width: 100%;
    }
  }

  &__additional-info {
    display: none;
    margin-top: 25px;

    @include fromDesktop {
      display: block;
    }
  }

  &__hotline {
    @include font-primary(h4-bold);

    a {
      @include font-primary(h4-bold);
    }
  }

  &__icon {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    border: 3px solid $colorDarkGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 55px auto 350px auto;

    @include fromDesktop {
      margin: 55px auto;
    }

    &--thanks {
      border-color: $colorGreen;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &--notRateable {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.o-280 {
  @include fromDesktop {
    padding-bottom: 85px;
  }

  &__wrapper .header h1,
  .o-280 .a-00700 .text {
    font-family: $font-primary-regular !important;
    font-weight: 400 !important;
  }
}

@keyframes moveBackLink {
  0% {
    left: 0;
  }

  40% {
    left: -5px;
  }

  100% {
    left: 0;
  }
}

px-cars-overview {
  display: block;
  width: 100vw;
  left: -50vw;
  position: relative;
  margin-left: 50%;
  padding: 0 $column-padding-mobile;

  @include fromTablet {
    padding: 0 $column-padding-tablet;
  }

  @include fromDesktop {
    padding: 0;
    width: auto;
    left: 0;
    margin-left: 0;
  }
}

// Job Detailseiten
.jobDetail,
.detailPages {
  padding: 74px 0;

  .job-content {
    padding-bottom: 32px;
  }

  .goBack {
    padding-top: 20px;
  }

  .gcoCategory {
    padding-bottom: 20px;
  }

  .sidebar {
    padding-left: 40px;
  }

  .headline {
    margin-bottom: 1.1rem;
  }

  .subHeadline {
    text-transform: none;
    font-size: 20px;
  }

  .image {
    margin-bottom: 30px;
  }

  .sidebarTeaser {
    margin-bottom: 27px;
  }

  .date {
    margin-bottom: 0.5rem;
    font-size: 15px;
  }
}

// dankesseite

.thankyou {
  margin: 20px 0;
  padding: 162px 0;

  .thankText {
    padding: 30px;
  }
}

.imprint,
.dataSecurity {
  padding: 74px 0;

  .label {
    min-width: 11%;
    max-width: 100%;
    display: inline-block;
  }
  p strong, strong {
    font-family: $font-primary-bold;
  }
}

.imprint {
  ul {
    list-style-position: inside;
  }
}

.dataSecurity {

  ul,
  ol {
    margin-bottom: 16px;
    padding-left: 20px;
  }

  a {
    font-family: $font-primary-regular;
    font-weight: 400;
  }
}

// 404 Error Page
.error-page {
  &__wrapper {
    position: relative;
    height: 400px;
    width: 100%;
    margin: 40px 0;

    @include fromDesktop {
      margin: 50px 0;
      height: 500px;
    }

    &::after {
      content: "";
      background-image: url(../Images/404.jpg);
      display: block;
      background-size: cover;
      background-position: center;
      opacity: 0.6;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }
  }

  &__text {
    position: absolute;
    top: 56%;
    left: 20px;

    @include fromDesktop {
      width: 50%;
      top: 40%;
      right: 20px;
      left: auto;
    }
  }
}

.campaignDetail {

  .module-bulletList {
    .container {
      @include fromWide {
        max-width: 1920px;
      }

    }
  }

  strong,
  b {
    font-family: $font-primary-bold;
  }

  .marginTop {
    &--middle {
      // als "aditionalClasses" : "" in den Settings einfügen
      margin-top: 52px;
    }

    &--large {
      // als "aditionalClasses" : "" in den Settings einfügen
      margin-top: 75px;
    }
  }

  .marginBottom {
    &--middle {
      // als "aditionalClasses" : "" in den Settings einfügen
      margin-bottom: 52px;
    }

    &--large {
      // als "aditionalClasses" : "" in den Settings einfügen
      margin-bottom: 75px;
    }
  }

  .headlineTop {
    &.center {
      // als "aditionalClasses" : "" in den Settings einfügen
      text-align: center;
    }

    &.first {
      // als "aditionalClasses" : "" in den Settings einfügen
      margin: 75px 0 56px;
    }
  }

  .multicol {
    &--teaser {

      // als "aditionalClasses" : "" in den Settings einfügen
      .module-image {
        margin-bottom: 21px;
      }

      .module-content {
        margin-bottom: 52px;
      }
    }

    &--grey {
      background-color: $colorLightGrey;
      padding-top: 100px;
      padding-bottom: 100px;
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .module-content {
    @include font-primary(copy);
  }

  .module-image {
    .col-12 {
      display: flex;
      justify-content: center;
    }
  }

  .imageRight {
    padding-right: 0;

    @include fromDesktop() {
      padding-left: 115px;
    }

    @include fromTablet() {
      padding-left: 45px;
    }
  }

  .fullwidthContainer {
    padding: 0;
  }

  .module-fullwidthImage {
    .a-01100--inline {
      .text {
        @include font-primary(copy);
      }

      &:hover {
        .text {
          color: $colorDarkGrey;
        }
      }

      &:focus {
        .text {
          color: $colorRed;
        }
      }

      &.disabled {
        .text {
          color: $colorGrey;
        }
      }
    }
  }

  .module-textImageMultiCTA {
    .mobileContent {
      .contentwrapper {
        .fromTablet--flex {
          display: block !important;

          .emailLink {
            margin: 10px 0 !important;
          }
        }
      }
    }
  }
}

// opv-unsubscribe
.opv-unsubscribe__wrapper {
  padding: 90px 15px 140px;
}

px-teasers {
  width: 100%;
}

//PAZG Edit
#pazgEdit {
  .border-bottom {
    border: 0;
    border-bottom: 0 !important;
  }

  .form-group {
    &--uvp {
      display: flex;
      justify-content: flex-end;

      .input-group {
        width: auto;
      }

      .input-group-addon {
        padding: 0px 0 6px 15px;
      }
    }
  }

  .pazgForm {
    margin-top: 0;
  }

  .box {
    background-color: $colorNavigationGray;
    padding: 15px 15px 25px 15px;
  }

  .cta-col {
    display: flex;
    justify-content: space-between;
    padding: 30px 15px 90px 15px;
  }

  .cta {
    font-family: $font-primary-bold;
    font-size: 1rem;
    line-height: 1.5;
    border: 0;
    background: 0;
    color: white;

    &:hover {
      color: #212529;
    }
  }

  .pazg-reset {
    background-color: $colorRed;
    color: white;
  }
}

// Neuwagen edit Page
.neuwagen-edit {
  &__configuration-label {
    margin-top: 5px;
  }
}

.fixedSavebuttons {
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 20px 0 15px 0;
  max-width: 480px;
  margin: 0 auto;
  border: 1px solid;
}

// Gallery edit
.gallery-edit-grid {
  >div {
    margin-bottom: 30px;
  }

  .previewGalleryWrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.footnotes {

  strong,
  b {
    color: black;
    font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
    font-weight: normal;
  }
  a {
    font-family: $font-primary-regular;
    color: $colorRed;
    font-size: 15px;
    &:hover {
      font-family: $font-primary-regular;
      color: $colorRed;
      // text-decoration: underline;
    }
    @include fromDesktop {
      font-size: 16px;
    }
  }
}


.errorpage__row {
  .errorpage__imageWrapper img {
    display: block;
    margin: auto;
  }
  .errorpage__text {
    position: absolute;
    top: 50%;
    left: 44%;
  }

  &.mobile {
    .errorpage__text {
      position: absolute;
      top: 9%;
      left: 9%;

      @media screen and (min-width: 426px) {
        top: 25%;
        left: 44%;
      }
    }
  }
}