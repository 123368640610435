.c-0019 {
    width: 100%;
    overflow: hidden;

    &__content {
        padding: 30px 30px 0;
        @include fromPhone {
            padding: 50px 30px 0;
        }
        @include fromTablet {
            padding: 48px 40px 0;
        }
        @include fromDesktop {
            padding: 50px 50px 0;
        }
        @include fromDesktop {
            padding: 50px 80px 0;
        }
        &__subheadline h3 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0;
            @include fromPhone {
                font-size: 25px;
                line-height: 30px;
            }
            @include fromTablet {
                font-size: 30px;
                line-height: 36px;
            }
        }
        &__headline h2 {
            font-family: $font-primary-regular;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 20px;
            @include fromPhone {
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 40px;
            }
            @include fromTablet {
                font-size: 50px;
                line-height: 60px;
                margin-bottom: 47px;
            }
            @include fromDesktop {
                margin-bottom: 65px;
            }
        }
    }

    &__sliderWrapper {

        .slick-list {
            overflow: visible;
            margin: 0 0;
            @include fromTablet {
                margin: 0 -30px;
            }
            @include fromDesktop {
                margin: 0 -60px;
            }
        }

        .slick-slide {
            opacity: 40%;
            width: 100vw;
            margin: 0 0;
            transition: opacity 0.3s ease-in-out;
            @include fromTablet {
                max-width: 768px;
                margin: 0 30px;
            }
            @include fromDesktop {
                max-width: 950px;
                margin: 0 60px;
            }
            &.slick-current {
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }
        }

        .slick-active {
            opacity: 1;
        }

        .slick-next {
            position: absolute;
            top: calc((((100vw / 4) * 3) / 2) - 15px);
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background: rgba(16, 16, 16, 0.6);
            &:hover {
                background: rgba(16, 16, 16, 0.6);
                opacity: 0.4;
            }
            &:focus {
                background: rgba(16, 16, 16, 0.6);
            }
            z-index: 1;
            right: -27px;
            @include fromPhone {
                top: calc((((100vw / 4) * 3) / 2) - 37px);
                right: -39px;
                width: 75px;
                height: 75px;
            }
            @include fromTablet {
                top: calc((((768px / 4) * 3) / 2) - 37px);
                right: 30px;
            }
            @include fromDesktop {
                top: calc((((950px / 4) * 3) / 2) - 37px);
                right: 80px;
            }
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url(../Icons/relaunch/icon_arrow_weiss_right.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: 10px center;
                @include fromPhone {
                    background-size: 19px 19px;
                    background-position: 10px center;
                }
                @include fromTablet {
                    background-size: 19px 19px;
                    background-position: center center;
                }
            }
            &.slick-disabled {
                opacity: 0;
                cursor: default;
            }
        }

        .slick-prev {
            position: absolute;
            top: calc((((100vw / 4) * 3) / 2) - 15px);
            width: 55px;
            height: 55px;
            z-index: 1;
            border-radius: 50%;
            background: rgba(16, 16, 16, 0.6);
            &:hover {
                background: rgba(16, 16, 16, 0.6);
                opacity: 0.4;
            }
            &:focus {
                background: rgba(16, 16, 16, 0.6);
            }
            left: -27px;
            
            @include fromPhone {
                top: calc((((100vw / 4) * 3) / 2) - 37px);
                left: -39px;
                width: 75px;
                height: 75px;
            }
            @include fromTablet {
                top: calc((((768px / 4) * 3) / 2) - 37px);
                left: 30px;
            }
            @include fromDesktop {
                top: calc((((950px / 4) * 3) / 2) - 37px);
                left: 80px;
            }
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url(../Icons/relaunch/icon_arrow_weiss_left.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: 30px center;
                @include fromPhone {
                    background-size: 19px 19px;
                    background-position: 43px center;
                }
                @include fromTablet {
                    background-size: 19px 19px;
                    background-position: center center;
                }
            }
            &.slick-disabled {
                opacity: 0;
                cursor: default;
            }
        }

        .c-0019__sliderItem {
            padding: 0;
            max-width: 100vw;
            @include fromTablet {
                max-width: 768px;
            }
            @include fromDesktop {
                max-width: 950px;
            }
            .m-0330 {
                &__content {
                    padding: 20px 15px;
                    @include fromPhone {
                        padding: 25px 30px;
                    }
                    @include fromDesktop {
                        padding: 30px 40px;
                    }
                    .m-0065 {
                        &__headline {
                            font-family: $font-primary-regular;
                            font-size: 20px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            @include fromPhone {
                                font-size: 21px;
                                line-height: 25px;
                            }
                            @include fromTablet {
                                font-size: 25px;
                                line-height: 30px;
                            }
                        }
                        &__copy {
                            font-size: 15px;
                            line-height: 20px;
                            margin-bottom: 20px;
                            -webkit-hyphens: none;
                            hyphens: none;
                            @include fromPhone {
                                line-height: 26px;
                            }
                            @include fromTablet {
                                line-height: 30px;
                            }
                            @include fromDesktop {
                                font-size: 16px;
                                line-height: 30px;
                            }
                        }
                        &__price {
                            font-family: $font-primary-regular;
                            color: $colorBlack;
                            font-size: 20px;
                            line-height: 24px;
                            margin-bottom: 20px;
                            @include fromPhone {
                                font-size: 21px;
                                line-height: 25px;
                            }
                            @include fromTablet {
                                font-size: 25px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                &__image-wrapper {
                    position: relative;
                    .m-033__envkv {
                        position: static;
                        @include fromPhone {
                            position: absolute;
                            bottom: 0;
                            z-index: 10;
                        }
                    }
                }

            }

            
        }
        ul.slick-dots {
            position: static;
            padding: 0 0 15px; 
            @include fromTablet {
                padding: 15px 0 33px;
            }
            @include fromDesktop {
                padding: 46px 0 53px;
            }
            li {
               width: 15px;
               height: 15px;
               margin: 0 15px 0 0;
               &.slick-active, &:hover {
                   button {
                       background-color: #000;
                       border: 2px solid #000;
                   } 
               }
               button {
                   width: 15px;
                   height: 15px;
                   background-color: #D2D2D2;
                   border: 2px solid #D2D2D2;
                   &::before {
                       width: 15px;
                       height: 15px;
                   }
               } 
           } 
        }
    }

}
.campaignDetail .fullwidthContainer {
    overflow: hidden;
    margin-top: -1px;
}
.campaignDetail .module-slider .col-12 {
    padding: 0;
}
