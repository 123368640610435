
.m-0251 {
    padding: 15px 25px;
    h4 {
        font-size: 18px;
        font-weight: 700;
    }
    .a-00800 {
        margin-top: 20px;
    }
}