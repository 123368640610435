px-m0071 {
    height: 100%;
    display: block;
}

.m-0071 {
    height: 100%;
    @include toTablet {
        display: flex;
        flex-direction: column;
    }
    .content {
        padding: 15px 25px 25px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
        }
        @include toTablet {
            padding: 35px 25px 45px;
            background-color: $colorLightGrey;
            flex-grow: 1;
        }
        @include tablet {
            padding: 15px 0 25px;
        }
        @include fromDesktop {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .headline {
        margin-bottom: 20px;
        @include font-primary(h1);
        @include fromDesktop {
            margin-bottom: 25px;
        }
    }
    hr {
        @include toTablet {
            display: none
        }
    }
    .copy {
        @include font-primary(copy);
        margin-bottom: 0;
        //@include fromTablet {
            margin-bottom: 35px;
        //}
    }
    .innerConent {
        @include fromTablet {
            padding-left: 45px;
        }
        @include fromDesktop {
            padding-left: 115px;
        }
        b, strong {
            @include font-primary(false, false, bold)
        }
    }
    .innerImg {
        @include fromTablet {
            padding-right: 0 !important;
        }

    }
    .a-00800 {
        max-width: 100%;
        @include toDesktop {
            margin-bottom: 15px;
        }
        @include fromTablet {
            max-width: 267px;
        }
    }
    .a-00500 {
        img {
            @include toDesktop {
                width: 100%;
            }
        }
    }
}