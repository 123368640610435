.a-00100 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &--a {
        height: 51px;
        width: 47px;
        svg {
            max-width: 100%;
            height: auto;
        }
        @include fromDesktop {
            height: 60px;
            width: 56px;
        }
    }
	&--b {
        height: 50px;
        width: 164px;
        color: white;
        font-size: 12px;
        
        @include fromDesktop {
            height: 59px;
            width: 191px;
        }
        img {
            object-fit: contain;
            object-position: 0; 
            max-height: 60px;
            max-width: 100%;
            @include fromDesktop {
                height: 60px;
            }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: none;
            }
        }
    }
    &__image-ie {
        display: none;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
            height: 100px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            bottom: 0;
            background-position: 0 50%;
        }
    }
}