.o-060 {
    &__emission-wrapper {
        padding: 0 60px;
        strong, b{
            font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
            font-weight: normal;
        }
    }
    &__emission-text {
        @include fromDesktop {
            margin-top: 21px;
            font-size: 16px;
        }
    }
    &__emission-image {
        width: 100%;
        max-width: 360px;
        position: relative;
        left: -6px;
        @include fromDesktop {
            width: auto;
            left: 0;
            max-width: 100%;
        }
    }
}