.carousel {
  outline: none !important;
  .carousel-item  {
    opacity: 0;
    display: block;
    transition: opacity .3s ease-in-out;
    z-index: 0;
    background-color: $colorWhite;
    &.active {
      opacity: 1;
      z-index: 1;
    }
  }

  .carousel-control-prev, 
  .carousel-control-next {
      width: 50px;
      cursor: pointer;
      opacity: .35 !important;
      @include toWide {
          display: none;
      }
  }

  .carousel-control-prev {
      left: -60px;
      &:before {
          content: "";
          background-image: url(/typo3conf/ext/mitsubishi_partnerseiten/Resources/Public/Icons/relaunch/icon_arrow_schwarz_left.svg);
          width: 25px;
          height: 40px;
          display: block;
          background-repeat: no-repeat;
      }
      .carousel-control-prev-icon {
          display: none;
      }
  }

  .carousel-control-next {
      right: -60px;
      &:before {
          content: "";
          background-image: url(/typo3conf/ext/mitsubishi_partnerseiten/Resources/Public/Icons/relaunch/icon_arrow_schwarz_right.svg);
          width: 25px;
          height: 40px;
          display: block;
          background-repeat: no-repeat;
      }
      .carousel-control-next-icon {
          display: none;
      }
  }



  .carousel-indicators {
    height: 35px;
    bottom: -35px;
    display: flex;
    justify-content: center;
    li {
        width: 5px;
        height: 5px;
        margin-left: 5px;
        border-radius: 50%;
        background-color: white;
        border: 2px solid transparent;
        box-shadow: 1px 1px 6px 0 rgba(0,0,0,0.35);
        transition: all .3s ease-in-out;
        &.active {
            background-color: $colorRed;
            border: 2px solid $colorRed;
        }
    }
  }
}