.o-200 {
    margin-top: 20px;
    @include fromDesktop {
        margin-top: 70px;
    }
    &__headline  {
        @include font-primary(h1);
        @include fromDesktop {
            text-align: center;
            margin-bottom: 15px;
        }
    }
    &__subHeadline {
        @include fromDesktop {
            text-align: center;
            margin-bottom: 55px;
        }
    }
    &__teaserItem {
        margin-bottom:15px;
        @include fromDesktop {
            margin-bottom:80px;
            padding: 12px;
            flex: 0 0 435px;
            max-width: 435px;
        }
    }
    &__row {
        @include fromDesktop {
            justify-content: center;
        }
    }
}