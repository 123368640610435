.a-03800 {
    background: $colorBlack;
    color: $colorWhite;
    padding: 10px;
    display: block;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 20px;
    &__text {
        @include font-lato-bold(12px, $colorWhite, normal, 16px);
    }
    
    div {
        min-width: 15px;
        &.prev {
        border-right: 1px solid $colorDarkGrey;
        padding-right: 10px;
        height: 20px;
        align-items: center;
            a {
                display: flex;
                height: 20px;
                align-items: center;
            }
        }
        &.wrap {
        display: flex;
        align-items: center;
            .text-right {
                margin-right: 15px;
            }
        }
    }
    a {
        cursor: pointer;
    }
    .row {
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
    }

    .disabled {
        color: $colorGrey;
        cursor: default;
        svg {
            @include fillSVG($colorGrey);
        }
        a {
            display: block;
            cursor: pointer;
        }

        div {
            min-width: 15px;

            &.prev {
                border-right: 1px solid $colorDarkGrey;
                padding-right: 10px;
                height: 20px;
                align-items: center;

                a {
                    display: flex;
                    height: 20px;
                    align-items: center;
                }
            }

            &.wrap {
                display: flex;
                align-items: center;

                .text-right {
                    margin-right: 15px;
                }
            }
        }
    }
    .arrow,
    .iconClose {
        height: 15px;
        width: 15px;
        position: relative;

        svg {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    .disabled {
        color: $colorGrey;
        cursor: default;

        a {
            cursor: default;
        }

        svg {
            @include fillSVG($colorGrey);
        }
    }

    .iconClose {
        svg {
            @include fillSVG($colorWhite);
        }
    }
}
