.a-00600 {
    position: relative;
    .gradient {
        position: absolute;
        width: 100%;
        bottom: 0;
        background: $gradientImage;
        z-index: 2;
    }
    &--a {
        .gradient {
            height: 46%;
        }
    }
    &--b {
        .gradient {
            height: 42%;
        }
    }
    &--c {
        .gradient {
            height: 42%;
        }
        .galleryWrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: hidden;
        }
        .galleryElement {
            width: 50%;
            position: relative;
            z-index: 1;
            &:nth-child(2) {
                left: 1px;
            }
            &:nth-child(3) {
                top: 1px;
            }
            &:nth-child(4) {
                top: 1px;
                left: 1px;
            }
        }
    }
    &--d, &--e {
        .gradient {
            height: 58%;
        }
    }
}