.a-03500 {
    display: inline-flex;
    background-color: $colorRed;
    color: white;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 14px;	
    font-weight: bold;
}