.a-00700 {
    position:relative; //new
    // height: 50px;
    height: 100%; //new
    width: 100%;
    // background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 0; //new
    transition: all 500ms cubic-bezier(0.750, -0.005, 0.265, 0.995);
    transition-timing-function: cubic-bezier(0.750, -0.005, 0.265, 0.995);
    cursor: pointer;
    @include toDesktop { //new include
        &:hover {
            background-color: $colorDarkGrey;
        }
    }
    &:focus {
        // background-color: $colorRed;
    }
    &.disabled {
        background-color: $colorGrey;
    }
    .elementWrapper {
        display: flex;
        align-items: center;
    }
    .icon {
        margin-left: 20px;
        width: 36px;
        height: 23px;

        svg {
            width: 100%;
            height: 100%;
            @include fillSVG($white);
            @include fromDesktop {
                @include fillSVG($colorBlack);
            }
        }
    }
    .text {
        margin-left: 18px;
        color: $colorWhite;
        // font-size: 12px;
        // text-transform: uppercase;
        // padding-left: 15px;
        @include font-primary(cta);
        @include fromDesktop {
            color: $colorBlack;
        }
    }
    .arrow {
        float: right;
        margin-right: 17px;
        width: 9px;
        height: 24px;
        color: $colorWhite;
        svg {
            width: 100%;
            height: 100%;
            @include fillSVG($white);
            @include fromDesktop {
                @include fillSVG($colorBlack);
            }
        }
    }
    &__easing, .text, svg g, svg path, svg polygon { //new transition
        @include fromDesktop {
            transition: all 500ms cubic-bezier(0.750, -0.005, 0.265, 0.995);
            transition-timing-function: cubic-bezier(0.750, -0.005, 0.265, 0.995);
        }
    }
    &__easing { //new
        @include fromDesktop {
            position: absolute;
            width:0;
            top:0; /*Change 'top' to 'bottom' for an upwards transition*/
            height: 50px;
            background-color: $colorBlack;
        }
    }
    &__wrapper { //new
        position: relative;
        height: 50px;
        width: 100%;
        background-color: $colorBlack;
        transition: all 0.6s;
        @include fromDesktop {
            background-color: $colorWhite;
            &:hover {
                .a-00700 {
                    z-index: 10;
                    svg {
                        g, path, polygon {
                            fill: $colorBlack !important;
                            @include fromDesktop {
                                fill: $colorWhite !important;
                            }
                        }
                    }
                    &__easing {
                        width: 100%;
                    }
                    .arrow, .text {
                        color: $colorBlack;
                        @include fromDesktop {
                            color: $colorWhite;
                        }
                    }
                }
            }
        }
    }
    &--a {
        
    }
    &--b {
        max-width: 267px;
    }
    &--c {
        @include toDesktop {
            &:hover {
                background-color: $colorDarkGrey;
            }
        }
        .icon {
            svg {
                @include fillSVG($colorBlack);
                @include fromDesktop {
                    @include fillSVG($colorBlack);
                }
            }
        }
        .text {
            color: $colorWhite;
            @include fromDesktop {
                color: $colorWhite;
            }
        }
        .arrow {
            color: $colorWhite;
            svg {
                @include fillSVG($colorWhite);
                @include fromDesktop {
                    @include fillSVG($colorWhite);
                }
            }
        }
        .a-00700__easing { 
            @include fromDesktop {
                background-color: $colorWhite;
            }
        }
        &.a-00700__wrapper {
            background-color: $colorBlack;
            @include fromDesktop {
                background-color: $colorBlack;
                &:hover {
                    .a-00700 {
                        svg {
                            g, path, polygon {
                                fill: $colorBlack !important;
                                @include fromDesktop {
                                    fill: $colorBlack !important;
                                }
                            }
                        }
                        .arrow, .text {
                            color: $colorBlack;
                            @include fromDesktop {
                                color: $colorBlack;
                            }
                        }
                    }
                }
            }
        }
    }
    &--d {
        .icon {
            svg {
                @include fillSVG($colorWhite);
                @include fromDesktop {
                    @include fillSVG($colorWhite);
                }
            }
        }
        .text {
            color: $colorWhite;
            @include fromDesktop {
                color: $colorWhite;
            }
        }
        .arrow {
            color: $colorWhite;
            svg {
                @include fillSVG($colorWhite);
                @include fromDesktop {
                    @include fillSVG($colorWhite);
                }
            }
            
            
        }
        .a-00700__easing { 
            display: none;
        }
        &.a-00700__wrapper {
            background-color: $colorBlack;
            &:hover {
                background-color: $colorDarkGrey;
            }
            &:active {
                background-color: $colorRed;
                .arrow {
                    color: $colorWhite;
                    svg {
                        @include fillSVG($colorWhite);
                        @include fromDesktop {
                            @include fillSVG($colorWhite);
                        }
                    }
                }
            }
            &.disabled, &[disabled] {
                opacity: 1;
                background-color: $colorButtonDisabled;
            }
        }
    }
    &--e {
        .icon {
            svg {
                @include fillSVG($colorWhite);
                @include fromDesktop {
                    @include fillSVG($colorWhite);
                }
            }
        }
        .text {
            color: $colorWhite;
            @include fromDesktop {
                color: $colorWhite;
            }
        }
        .arrow {
            color: $colorWhite;
            svg {
                @include fillSVG($colorWhite);
                @include fromDesktop {
                    @include fillSVG($colorWhite);
                }
            }
        }
        .a-00700__easing { 
            display: none;
        }
        
        &.a-00700__wrapper {
            background-color: $colorBlack;
            @include fromDesktop {
                &:hover {
                    .a-00700 {
                        svg {
                            g, path, polygon {
                                fill: $colorBlack !important;
                                @include fromDesktop {
                                    fill: $colorBlack !important;
                                }
                            }
                        }
                        .arrow, .text {
                            color: $colorBlack;
                            @include fromDesktop {
                                color: $colorBlack;
                            }
                        }
                    }
                }
            }
            
            &:hover {
                background-color: $colorWhite;
            }
            &:active {
                background-color: $colorRed;
            }
            &.disabled, &[disabled] {
                opacity: 1;
                background-color: $colorButtonDisabled;
            }
        }
    }
}