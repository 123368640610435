.m-0105 {
    padding-bottom: 22px;
    width: 100%;
    @include fromDesktop {
        padding-bottom: 60px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .a-00600 {
        img {
            width: 100%;
        }
    }
    .image-wrapper {
        position: relative;
        
    }
    .text {
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 2;
        @include fromDesktop {
            position: relative;
            left: 0;
            bottom: 0;
        }
        p {
            color: white;
            margin: 0;
            @include fromDesktop {
                color: black;
            }
        }
        &__name {	
            // @include font-lato-bold(16px, white, normal, 18px);
            @include font-primary(h2, $colorWhite);
            margin-bottom: 5px !important;
            @include fromDesktop {
                margin-top: 22px !important;
                margin-bottom: 0 !important;
                // @include font-lato-bold(20px, black, normal, 30px);
            }
        }
        &__function {
            @include fromDesktop{
               height: 42px;
            }
            // font-size: 12px;	
            // line-height: 20px;
            // color: $colorDarkGrey;
            // @include fromDesktop {
            //     font-size: 16px;	
            //     line-height: 26px;
            // }
            @include font-primary(copy, $colorDarkGrey);
            @include toDesktop {
                    font-size: 12px; //font-hard
                }
            span {
                margin-right: 5px;
                & + span {
                    &:before {
                        content: '';
                        display: inline-block;
                        height: 10px;
                        width: 1px;
                        background-color: #fff;
                        top: 1px;
                        position: relative;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .separator {
        height: 20px;
        width: 1px;
        background-color: $colorGrey;
        @include fromDesktop {
            width: 100%;
            height: 1px;
        }
    }
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;
        margin-bottom: auto;

        @include fromDesktop {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: black;
            font-size: 12px;	
            @include font-primary(cta, $colorBlack);
            // @include font-primary(12px, black, normal, normal);
            @include fromDesktop {
                padding-top: 20px;
                padding-bottom: 20px;
                // @include font-primary(14px, black, normal, 18px); 
            }
            svg {
                height: 24px;
                width: 24px;
                margin-right: 7px;
                @include fromDesktop {
                    margin-right: 14px;
                    height: 20px;
                    width: 20px;
                }
                path {
                    fill: black !important;
                }
            }
            &:hover {
                color: $colorRed;
                svg {
                    path {
                        fill: $colorRed !important;
                    }
                }
            }
        }
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .m-0105 .image-wrapper {flex: 0 0 auto;}
}