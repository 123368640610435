.a-02800 {
    position: relative;
    margin-top: 2px;
    margin-left: 2px;
    left: -2px;
    @include fromDesktop {
        margin-top: 11px; 
    }
    
    span {
        // font-size: 12px;
        padding: 3px 7px;
        // color: $colorDarkGrey;
        background-color: $colorLightGrey;
        white-space: none;
        @include font-primary(tag);
        @include fromDesktop {
            padding: 5px 11px;
            // font-size: 14px;
        }
    }
}