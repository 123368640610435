.editMask{
    // general stylings for every editmask
    .headlineTop{
        font-family: AlphaWeb,Helvetica,Arial,sans-serif;
    }

    h2, .topbar{
        font-family: AlphaWeb,Helvetica,Arial,sans-serif;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 18px;
        padding-bottom: 8px;
        font-size: 24px;
    }

    .has-error{
        .jqte{
            border: 2px solid #ed0000;
        }

        .form-control{
            border: 2px solid #ed0000;
        }

        .radio-inline{
            border: 2px solid #ed0000;
        }
    }

    .bild{
        padding: 10px;
        background-color: $colorLightGrey;
    }

    .bild.has-error{
        border: 2px solid #ed0000;
        padding: 2px;
        border-radius: 5px;
    }

    //buttons
    .buttonSave{
        background-color: $colorEditButton;
        color: #ffffff;
        transition: 0.3s;
        margin-right: 6px;

        &:hover{
            background-color: #29aab380;
            border: 1px solid $colorEditButton;
        }
    }

    .buttonReset{
        background-color: #c2c2c2;
        color: #ffffff;
        transition: 0.3s;
        margin-right: 6px;

        &:hover{
            background-color: #c2c2c280;
            border: 1px solid #c2c2c2;
        }
    }
    .buttinInverted {
        background-color: #ffffff;
        color: $colorEditButton;
        border: 1px solid $colorEditButton;
        transition: 0.3s;
        margin-right: 6px;

        &:hover{
            color: #ffffff;
            background-color: #29aab380;
        }
    }
    // upload button oÄ
    .buttonDropzone{
        width: 100%;
        border: 1px solid black;
        border-color: #7d0008;
        background-color: #e3000f;
        color: #FFFFFF;
    }

    div[class^="js-dropzone"]{
        cursor:pointer;
    }

    .infoFileUpload{
        margin-top:20px;
        margin-bottom: 10px;
    }
    .alert-info{
        margin-top: 20px;
        border-color: #b0000c;
        background-color: rgba(227,0,15,0.05);
    }

    .icon-trash{
        background-image: url('../Icons/relaunch/edit_garbage.svg');
        display: inline-block;
        background-repeat: no-repeat;
        width: 1em;
        float: right;
        height: 1em;
        vertical-align: middle;
    }
    .preview {
        position: relative;
    }
    // overlay delete button for images
    .preview-delete{
        position: absolute;
        background: #FFFFFF;
        padding: 6px;
        border: 1px solid black;
        top: 0;
        right: 0;
        cursor: pointer;
        .icon-trash, .glyphicon-trash{
            background-image: url('../Icons/relaunch/edit_garbage.svg');
            display: inline-block;
            background-repeat: no-repeat;
            width: 1.4em;
            height: 1.4em;
            vertical-align: middle;
        }
    }
    
    // button imgages
    .imagesUploadBtn{
        text-align: center;
        margin-top: 20px;
        padding: 20px 0;
        background-color: $colorLightGrey;
    }

    #drop-edit-area{
        margin-bottom: 24px;
        background-color: $colorLightGrey;
        padding: 10px;
    }


    // DATENSCHUTZ HISTORIE
    del{
        color: red;
    }

    ins{
        color: green;
    }

    &--dse {
        .errors {
            p {
                background-color: #e3000f;
                color: white;
                padding: 10px 15px;
                border-radius: 5px;
            }
        }
    }

     .has-error {
         + label::before {
            border: 2px solid #ed0000;
        }
     }

}

// only for employee edit mask
.editEmployee{
    .alert-info{
        margin-top: 20px;
    }
}

// only greenmobility
.editGreenmobilityBox{
    .editGreenmobilityText{
        margin-bottom: 18px;
    }
    
}

// for all checkboxes
.checkbox{
    input{
        height: 25px;
        width: 25px;
    }

    input[type=checkbox]{
        opacity: 0;
        position: absolute;
        margin-left: 20px;
        
        &:checked+label:after{
            content: 'x';
            font-size: 22px;
            font-weight: 600;
            left: 0;
            position: absolute;
            margin: 1px 0 0 7px;
            top: 0;
        }
    }

    label{
        padding-left: 35px;
        height: 25px;
        position: relative;
        line-height: 20px;
        margin-bottom: 20px;
        
        &::before{
            height: 25px;
            width: 25px;
            content: '';
            border: 1px solid #CCC;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0;
        }
    }
}

// Über uns
.editPageSettings{
    .spaceBottom{
        margin-bottom: 20px;
    }
}

// BUTTONS
.pencilButton{
    button{
        border-color: #29aab3;
        background-color: #29aab380;
    }
    .pencil-icon{
        background-image: url('../Icons/relaunch/edit_pencil.svg');
        display: inline-block;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        vertical-align: middle;
    }
}

.teaserEdit__btn{
    
}

.pencil-icon, .glyphicon-pencil{
    background-image: url('../Icons/relaunch/edit_pencil.svg');
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

// Plus Zeichen 
.glyphicon-plus-sign, .plus-sign{
    background-image: url('../Icons/relaunch/edit_plus.svg');
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

// Icon Sortierung
.glyphicon-sort{
    background-image: url('../Icons/relaunch/edit_sort.svg');
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

.icon-print{
    background-image: url('../Icons/relaunch/edit_print.svg');
    display: inline-block;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}


// logout button und Icon
.logoutButton{
    background-color: #e3000f;
    border-color: #7d0008;

    .glyphicon-log-out{
        background-image: url('../Icons/relaunch/icon_suchen_weiss.svg');
        display: inline-block;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        vertical-align: middle;
    }
}

// center edit buttons outside box
.propertiesEditButton{
    margin-left: auto;
    max-width: 1220px;
    width: 100%;
    margin-right: auto;
    display: block;
    margin-top: 16px;
}

.googleEditButton{
    display: block;
    margin-top: 16px;
}

.btn-editMode{
    @include font-primary-bold();
    &--saveBtnCurrent {
        color: #29aab3;
        background-color: #fff;
        border-color: #29aab3;
    }
}

// alert
.alert-info{
    border-color: #b0000c;
    background-color: rgba(227,0,15,0.05);
}

// Kampagnen
.campagneEdit{
    margin-bottom: 20px;
    margin-top: 20px;
 

    h2{
        font-family: AlphaWeb,Helvetica,Arial,sans-serif;
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 8px;
        margin-bottom: 18px;
    }

    h5{
        font-weight: bold;
    }

    .campaign{
        margin-bottom: 30px;
    }

    .pencil-icon{
        background-image: url('../Icons/relaunch/icon_suchen_weiss.svg');
        display: inline-block;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        vertical-align: middle;
    }

    .buttonSelectCampaign{
        padding: .375rem;
        color: #b0000c;
        margin-top: 2px;
    }

    .box{
        background-color:rgba(0,0,0,0.1);
        padding: 10px 15px;

    }
}

// Neuwagen editieren
.neuwagenEdit{
    margin: 14px auto;
    h1, h2, h3{
        color: $colorBlack;
    }

    .btnPrint{
        background-color: $colorLightGrey;
    }

    .btnNewCar{
        border: 1px solid $colorBlack;
        background-color: $colorDarkGrey;
        color: #FFFFFF;
    }

    .newCar2Checkbox{
        margin:auto;
    }

    .alert{
        padding: 30px;
        border-radius: 0; 
        
    }
    span{
        font-size: 16px;
        color: #000;
    }

    .alert-default{
        background-color: $colorLightGrey;
        border: 1px solid $colorDarkGrey;
    }

    .panel-heading{
        background-color: $colorDarkGrey;
        padding: 10px;
        margin: 10px 0;
        
        span{
            color: #FFFFFF;
        }

        .panel-title{
            h4{
                color: #FFFFFF !important;
            }
        }

        .fahrzeugRow{
            align-items: center;
        }
    }

    .panel-collapse{
        margin-top: 20px;
    }

    .spaceBetween{
        margin-bottom: 10px;
    }

    .checkbox{
        label{
            font-size: 16px;
            &:before{
                background-color: #FFFFFF;
            }
        }
    }

    .glyphicon-more{
        background-image: url('../Icons/relaunch/icon_arrow_weiss_right.svg');
        display: inline-block;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        vertical-align: middle;
    }

    .glyphicon-arrow-down{
        background-image: url('../Icons/relaunch/icon_arrow_weiss_down.svg');
        display: inline-block;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        vertical-align: middle;
    }
    .neuwagenSortFilter {
        padding: 30px 0 10px;
        margin-top: 20px;
    }
    .filterIcons {
        padding: 6px 0;
        margin-right: 15px;
        img {
            width: auto;
            height: 24px;
        }
    }
    .filterRow {
        display: flex;
        flex-direction: row;
        &--end {
            justify-content: flex-end;
            flex-grow: 1;
        }
        .selectpicker {
            width: auto;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// Fahrzeug anlegen
.newCarsEdit{
    .optionForm{
        background-color: $colorLightGrey;
        padding: 12px;
    }
    .input-group{
        .input-group-addon{
            background-color: $colorDarkGrey;
        } 
    }
}

.editCampaigns{
    h5{
        font-weight: bold;
    }
}

// Gallery Sort^M
.sortGalleries{
        .sortableWrapper{
            background-color: $colorLightGrey;
            margin-bottom: 12px;
        }Ich 
        

        #slide-aktionen{
            margin-bottom: 12px;
        }
    

    .sortEmployee{
        .sortableWrapper{
            margin-bottom: 12px;
            background-color: $colorLightGrey;
        }
    }
}

.input-group{
    .input-group-addon{
        cursor: pointer;
        flex-basis: 20%;
        background-color: $colorLightGrey;
        border-radius: 0;
        border: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .iconCalendar{
            background-image: url('../Icons/relaunch/ic_calendar_datepicker_red.svg');
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            height: 28px;
            width: 28px;
            position: relative; 
            vertical-align: middle;
        }
    }
}


// Jquery ui datepicker in edit masken
.ui-datepicker{
    width: auto;
    max-width: 100%;
    padding: 0;
    background: none;
    background-color: white;
    .ui-datepicker-week-col {
       
    }

    td {
        width: 42px;
        height: 42px;
        max-width: 42px;
        max-height: 42üx;

        span,
        a {
            text-align: center;
        }
    }
    .ui-datepicker-year {
    }

    .ui-state-default, 
    .ui-widget-content .ui-state-default {
        border: none;
        padding: 10px 0;
        color: $colorBlack;
    }

    .ui-state-active,
    .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active {
        color: white !important;
        background-color: $colorDarkGrey;
        padding: 10px;
    }
    .ui-datepicker-header{
        background-color: $colorLightGrey;
        padding: 10px 10px;
    }

      .ui-state-disabled,
    .ui-widget-content .ui-state-disabled,
    .ui-widget-header .ui-state-disabled {
        opacity: 1;

        .ui-state-default {
            background: transparent;
            color: black !important;
        }
    }

    .ui-datepicker-week-end {
        .ui-state-default {
            color: #999999 !important;
        }
    }

    .ui-datepicker-title {
        color: $colorBlack;
        font-weight: bold;
        text-align: center;

    }
    
    .ui-datepicker-prev,
    .ui-datepicker-next {
        opacity: 1;
    }

    .ui-icon-circle-triangle-w {
        content: '<';
    }

    .ui-icon-circle-triangle-e,.ui-icon-circle-triangle-w  {
        color: $colorBlack;
        position: absolute;
        cursor: pointer;
    }

    
    .ui-icon-circle-triangle-e{
        right: 10px;
    }
    
    // .ui-icon-circle-triangle-w,
    // .ui-icon-circle-triangle-e{
    //     background: none;
    //     &::after {
    //         height: 16px;
    //         width: 16px;
    //         color: white;
    //         display: block;
    //         position: absolute;
    //         left: 50%;
    //         margin-left: -8px;
    //         top: 50%;
    //         margin-top: -8px;
    //         text-indent: 0;
    //         line-height: 15px;
    //         font-size: 26px;
    //     }
    // }

    .ui-datepicker-calendar {
        thead {
            background-color: #eaeaea;
            text-align: center;
        }

        tbody {
            background-color: white;
            text-align: center;
        }

        th{
            padding: 10px 0;
        }
    }
}

.edit-mask-panel{
    .buttonReset{
        background-color: #c2c2c2;
        color: #ffffff;
        transition: 0.3s;
        margin-right: 6px;

        &:hover{
            background-color: #c2c2c280;
            border: 1px solid #c2c2c2;
        }
    }

    .buttonSave{
        background-color: #29aab3;
        color: #ffffff;
        transition: 0.3s;
        margin-right: 6px;
        &:hover{
            background-color: #29aab380;
            border: 1px solid #29aab3;
        }
    }

    .m-0070{
        padding-top: 35px;
    }

    .teaserEdit__newTeaserRow{
        .editTeaser{
            top: 41px;
        }
    }

}

.teaserEdit {
    &__content {
        &--campaigns {
            width: 100vw;
            max-width: $container-width-xl;
            .grid-wrapper  {
                height: 680px!important;
            }
        }
    }
    &__createForm {
        p.error {
            margin: 0;
            color: $colorRed;
            font-size: 14px;
        }
    }
}

.checkedArrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    // width: 100%;
    // height: 100%;
    opacity: 0.5;
}

.currentHeroImage {
	position: relative;
	display: flex;
	justify-content: center;
    align-items: center;
    // max-height: 160px;
    img {
        width: 100%;
    }
}
.heroImage__newTeaserContent {
    position: relative;
    height: 100%;
    padding: 15px 15px 65px;
    border: 1px solid $colorEditBorder;
    .btn {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 15px;
        float: none;
        width: calc(100% - 30px);
    }
}

.iconHeroimageEditHacken {
	position: absolute;
    width: 33%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}


 .landingEdit {
     &__header {
         padding-top:20px;
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom:35px;
         h2 {
            font-weight: bold;
            border: none;
         }
         button {
             margin-right:10px;
         }
     }
     &__item {
        margin-bottom:20px;
        background-color:rgba(0,0,0,0.1);
     }

     &__itemImage{
         margin:0px;
         padding:0px;
     }

     &__itemButtons {
         padding-top:15px;
        button {
            margin-bottom:5px;
            float:right;
            margin-right:10px;
        }
     }

     &__itemDescription{
        padding-top:15px;
        h2:first-child {
            font-size:14px;
        }
     }
  
 }


.editLandingButton button{
    width: 100%;
    border-radius: 0px;
}

.landingChooseText {
    margin-bottom:30px;
}

.landingPageImage{
    padding: 0px 20px 0 20px;
}
.langingType {
    margin-bottom:50px;
}
.landingButton {
    width:150px;
}

.mainFormHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    width:100%;
    margin-bottom:40px;

    .form-group{
        margin-right:50px;
    }
    @include toTablet(){
        flex-direction: column !important;
    }
    .teaserEdit__itemChecboxLabel{
        @include toTablet(){
            margin: 20px auto;
        }
    }
}


.imageTextForm, .carForm, .richTextForm, .buttonForm, .mainForm {
    .errorForm{
        color: #e3000f;
        font-size:12px;
        margin:0px;
        padding:0px;
    }
}

.langingPageStickyBar {
    position:sticky;
    bottom:0px;
    padding: 10px;
    width:100%;
    display: flex;
    justify-content: flex-end;
    background-color: white;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    margin-top:20px;
    z-index: 1;
    @include toTablet(){
        bottom: 50px;
    }
}



.textImageArea {
    display: flex;
}

.landingTypeImage {
    position: absolute;
    right:0px; 
    padding-right: 10px;
    padding-top: 80px;
}

@media screen and (max-width: 770px) {
    .landingTypeImage2, .landingTypeImageButton, .landingTypeImage, .landingTypeImageCar1, .landingTypeImageCar2, .landingTypeImageRichText, .landingTypeEmployee, .landingTypeImageRichText3 {
        display: none;
    }
}
.landingTypeImage2, .landingTypeImageButton, .landingTypeImageCar1, .landingTypeImageCar2, .landingTypeImageRichText, .landingTypeEmployee, .landingTypeImageRichText3 {
    position: absolute;
    right: 0px;
}
.landingTypeImage2 img{
    position: relative;
    bottom:300px;
    padding-right:10px;
}
.landingTypeImageButton img {
    position: relative;
    bottom:180px;
    padding-right:10px;
}
.landingTypeImageCar1 img {
    position: relative;
    top: -30px;
    padding-right:10px; 
}
.landingTypeImageCar2 img {
    position: relative;
    top: -450px;
    padding-right:10px; 
}
.landingTypeImageRichText img {
    position: relative;
    top: -240px;
    padding-right:10px;    
}
.landingTypeEmployee img {
    position: relative;
    bottom: 130px;
    padding-right:10px;    
}
.landingTypeImageRichText3 img {
    position: relative;
    top: -240px;
    padding-right:10px; 
}



px-edit-button {
    &[identifier="campaignslider"] {
        position: absolute;
        left: 15px;
        z-index: 10;
    }
}
