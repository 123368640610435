.a-02000 {
    width: 100%;
    @include fromDesktop {
        max-width: 360px;
    }
    .label {
        color: black;
        font-size: 10px;
        font-weight: bold;
    }
    .linkWrapper {
        margin-top: 5px;
        border: 1px solid $colorFormBorder;
        padding: 4px;
        display: flex;
        flex-direction: row;
        a, .input-label{
            height: 36px;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            &:active, &.active {
                background-color: $colorDarkGrey;
                color: white;
            }
        }
        .link {
            height: 36px;
            width: 50%;
        }
        .input-label {
            width: 100%;
            height: 100%;
        }
        input[type="radio"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 36px;
            width: 100%;
            max-width: 145px;
            @media screen and (min-width: 350px) {
                max-width: 175px;
            }
            &:checked + .input-label {
                background-color: $colorBlack;
                color: white;
            }
        }
    }
}