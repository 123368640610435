.a-02500 {
    display: flex;
    justify-content: center;
    height: 42px;
    border: 1px solid $colorBorderGray;
    background: white;
    .budgetModifier {
        width: 43px;
        color: $colorRed;
        font-size: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        box-shadow: none;
        border: none;
        cursor: pointer;
        &--minus {
            border-right: 1px solid $colorBorderGray;
        }
        &--plus {
            border-left: 1px solid $colorBorderGray;
        }
        &--deactivated {
            color: $colorBorderGray;
        }
        &:focus, &:hover {
            outline: 0;
        }
        &.triggerAnimation {
            animation: triggerAnimation .2s ease-in-out;
        }
    }
    .textWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 86px);
        padding-top: 8px;
        .price {
            @include font-primary(h1);
            margin-top: 2px;
            @include fromDesktop{
                font-size: 18px; 
                line-height: 22px;
            }
        }
        
    }
    .description {
        // font-size: 10px;
        @include font-primary(note);
        line-height: 7px;
        color: $colorDarkGrey;
    }
}

@keyframes triggerAnimation {
    0% {background-color: white;}
    25% {background-color: $colorBorderGray;}
    100% {background-color: white;}
}