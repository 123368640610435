.o-190 {
    margin-top: 0;
    &__card-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        @include fromTablet {
            grid-column-gap: 15px;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 1fr;
        }
        @include fromDesktop {
            grid-column-gap: 24px;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    .card-wrapper {
        margin-bottom: 12px;
        background-color: $colorLightGrey;
    }
    .collapse {
        width: 100% !important;
    }
    .button-more {
        cursor: pointer;
        width: 100%;
        text-align: center;
        border: 0;
        border-top: 1px solid black;
        margin-top: 16px;
        background: none;
        padding: 25px 0;
        outline: 0 !important;
        // text-transform: uppercase;
        // @include font-lato-bold(14px, white, normal, 16px);
        @include font-primary(cta,  $colorWhite);
        @include fromDesktop {
            margin-top: 42px;
        }
        svg {
            width: 18px;
            height: 12px;
            margin-left: 5px;
            position: relative;
            top: -1px;
        }
        .content-shown {
            svg {
                transform: rotate(180deg)
            }
        }
        .content-collapsed {
            display: none;
        }
        &.collapsed {
            .content-collapsed {
                display: block;
            }
            .content-shown {
                display: none;
            }
        }
    }
}