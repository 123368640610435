.a-02700 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &__icon {
        svg {
            width: 13px;
            height: 13px;
            position: relative;
            top: -1px;
        }
    }
    &__text {
        margin-left: 5px;
        @include font-primary(copy, $colorDarkGrey);
        text-align: left;
    }
}

px-a02700 {
    display: inline-block;
}