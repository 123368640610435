.a-01300 {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    border-bottom: 1px solid $colorGrey;
    .elementWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        // background: white;
        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .cta {
        margin-left: 12px;
        text-transform: uppercase;
        text-align: left;
        // @include font-lato-bold(12px, $colorWhite, normal, 16px);
        @include font-primary(cta);
    }
    &:hover {
        .cta {
            color: $colorGrey;
        }
        .icon {
            // background: $colorGrey;
            svg {
                @include fillSVG($colorGrey);
            }
        }
    }
    &:focus {
        .cta {
            color: $colorRed;
        }
        .icon {
            // background: $colorRed;
            svg {
                @include fillSVG($colorRed);
            }
        }
    }
    &.disabled {
        .cta {
            color: $colorDarkGrey;
        }
        .icon {
            // background: $colorDarkGrey;
            svg {
                @include fillSVG($colorDarkGrey);
            }
        }
    }
}