.a-00510 {
    &--a {
        img {
        }
    }
    &--b {
        img {
        }
    }
    &--c {
        img {
            width: 116px;
            height: 105px;
            object-fit: contain;
        }
    }
    &--d {
        img {
        }
    }
}