.m-0165 {
    padding-bottom: 20px;
    @include fromDesktop {
        padding-bottom: 34px;
    }
    &__technical-details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 19px;
        @include fromDesktop {
            margin-top: 5px;
        }
        px-a03350 {
            flex-basis: 100%;
            @include fromTablet {
                flex-basis: 25%;
            }
            @include fromDesktop {
                margin-top: 12px;
            }
        }
        .a-03350 {
            flex-basis: 50%;
            @include fromTablet {
                flex-basis: 25%;
            }
        }
    }
    &__break {
        flex-basis: 100%;
        height: 0;
    }
}