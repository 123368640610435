.c-0014 {
    position: relative;
    width: 100%;

    .a-00500 {
        img {
            width: 100%;
            margin-top: -1px;
            @include fromDesktop {
                object-fit: cover;
                height: 811px;
            }
            @include fromWide {
                object-fit: cover;
                height: 940px;
                object-position: 50% 15%;
            }
        }
    }
    &__content {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;

        &_inner {
            padding: 0 15px 30px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 26px;

            @include fromPhone {
                padding: 0 30px 30px;
                gap: 30px;
            }

            @include fromTablet {
                padding: 0 40px 40px;
            }

            @include fromDesktop {
                padding: 0 50px 40px;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }

            @include fromWide {
                padding: 0 80px 50px;

            }

            &_text {

                h1 {
                    color: $colorWhite;
                    font-size: 25px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                    @include fromPhone {
                        font-size: 40px;
                        margin-bottom: 15px;
                    }
                    @include fromTablet {
                        font-size: 50px;
                        margin-bottom: 15px;
                    }
                    @include fromDesktop {
                        font-size: 50px;
                    }
                }
                .span-price {
                    color: $colorWhite;
                    font-size: 16px;
                    line-height: 19px;
                    @include fromPhone {
                        font-size: 25px;
                        line-height: 30px;
                    }
                    @include fromDesktop {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }
            }

            &_btn {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                justify-content: flex-end;
                margin-top: 0;
                height: 100%;
                gap: 14px;
                @include fromTablet {
                    gap: 20px;
                }
                .a-01000--c {
                    width: 250px;
                    height: 42px;
                    @include fromPhonePlus {
                        width: 220px;
                        height: 42px;
                    }
                    @include fromTablet {
                        width: 260px;
                        height: 50px;
                    }
                    &:hover {
                        background-color: transparent;
                        opacity: 0.4;
                    }
                    .text,
                    span {
                        text-transform: none;
                        font-size: 12px;
                        line-height: 14px;
    
                        @include fromTablet {
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
    }
}