.m-0205 {
    padding: 11px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    px-a03350 {
        flex-basis: 100%;
        @include fromPhone {
            flex-basis: 50%;
        }
        @include fromTablet {
            flex-basis: 25%;
        }
    }
    // .a-03350 {
    //     flex-basis: 50%;
    //     @include fromTablet {
    //         flex-basis: 25%;
    //     }
    // }
}