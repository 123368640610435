.m-0120 {
    background-color: $colorWhite;
    display: block;
    width: 100%;
    & + .m-0120 {
        margin-top: 12px;
    }
    &__content-wrapper {
        display: flex;
        height: 105px;
    }
    &__picture {
        border-right: 1px solid $colorLightGrey;
    }
    &__text {
        display: flex;
        padding: 0 10px 0 19px;
        justify-content: center;
        flex-direction: column;
        h3 {
            @include font-primary(h1);
        }
    }
    &__price {
        margin-bottom: 0;
        // @include font-lato-bold(14px, $colorBlack, normal, 18px);
        @include font-primary(price, $colorDarkGrey);
    }
    &__offers {
        margin-bottom: 0;
        // @include font-lato-regular(14px, $colorBlack, normal, 20px);
        @include font-primary(copy, $colorDarkGrey);
    }
    &__arrow {
        display: flex;
        width: 16px;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: auto;
        svg {
            opacity: .5;
        }
        div {
            width: 16px;
            height: 10px;
            position: relative;
            top: -5px;
        }
    }
    &__collapse-container {
        padding: 12px 12px 0 12px;
        background-color: $colorDarkGrey;
        @include fromTablet {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
        }
        @include fromDesktop {
            display: block;
        }
    }

    // If molecule m-0060
    px-m0060 {
        display: block;
        @media screen and (min-width: 635px) {
            max-width: 49%;
        }
        @include fromTablet {
            margin-top: 12px;
        }
        @include fromDesktop {
            max-width: none;
            margin-top: 0;
        }
        & + px-m0060 {
            margin-top: 12px;
            // @include fromTablet {
            //     margin-top: 0;
            // }
        }
        &:nth-child(3n) {
            margin-top: 12px;
        }
    }
    .m-0060 {
        & + .m-0060 {
            margin-top: 12px;
        }
    }
}