.o-090 {
    background-color: $colorBlack;
    @include fromDesktop {
        padding-top: 75px;
    }
    &__headline {
        @include font-primary(h1, $colorWhite);
    }
    .column{
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
        &__second {
            .a-03000 {
                margin-top: 20px;
            }
        }
        &__third{
            @include fromDesktop {
                padding-top: 38px;
            }
        }
    }
    &__contact-column {
        .a-01300 {
            .cta {
                font-size: 14px;
            }
        }
    }
    &__spacer {
        margin-top: 20px;
    }
    .headline-col {
        padding-left: 15px;
        padding-right: 15px;
        @include fromDesktop {
            margin-top: 0; 
            text-align: left;
            padding-left: 25px;
            padding-right: 25px;
            font-size: 20px;
            line-height: 20px;
        }
    }
    .a-03000 {
        @include fromDesktop {
            display: none;
        }
    }
    .a-00800, .a-01000 {
        width: auto;
        max-width: 100%;
        margin-top: 5px;
        margin-left: 25px;
        margin-right: 25px;
        @include fromDesktop {
            margin-top: 10px;
        }
    }
    .a-01000 {
        margin-top: 15px;
        @include fromDesktop {
            margin-top: 25px;
        }
    }
    .lastRow {
        padding-top: 20px;
        padding-bottom: 25px;
        margin: 0 10px;
        @include fromDesktop {
            padding-bottom: 80px;
        }
    }
    .button {
        height: 24px;
        margin-bottom: 15px;
        // color: $colorWhite;
        // font-size: 12px;
        // font-weight: bold;
        // line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        @include font-primary(cta, $colorWhite);
        &+ .button {
            border-left: 1px solid $colorGrey;
        }
    }
    .copyright {
        // color: $colorWhite;
        // font-size: 10px;
        // line-height: 14px;
        text-align: center;
        @include font-primary(note, $colorWhite);
    }
    .someIcons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        @include fromDesktop {
            margin-top: 10px;
        }
        a {
            display: block;
            margin-left: 12px;
            margin-right: 12px;
        }
        &__wrapper {
            width: 100%;
        }
    }
}