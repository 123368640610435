.a-00800 {
    height: 44px;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    border: 0;
    cursor: pointer;
    @include fromDesktop {
        max-width: 267px;
    }
    &:hover {
        background-color: $colorDarkGrey;
    }
    &:active {
        background-color: $colorRed;
    }
    &.disabled, &[disabled] {
        opacity: 1;
        background-color: $colorButtonDisabled;
    }
    .elementWrapper {
        display: flex;
        align-items: center;
    }
    .text {
        text-transform: uppercase;
        padding: 0 15px;
        @include font-primary(cta);
        color: $colorWhite;
        // @include font-lato-bold(12px, white, normal, normal);
        // @include fromDesktop {
        //     font-size: 14px;
        // }
    }
    &--a {

    }
    &--b {
        max-width: 267px;
    }
    &--c {
        max-width: 267px;
        background-color: $colorWhite;
        border: 1px solid $colorBlack;
        .text {
            color: $colorBlack;;
        }
        &:hover {
            background-color: $colorWhite;
            .text {
                color: $colorDarkGrey;
            }
        }
    }
    &--mobile-sticky {
        position: fixed !important;
        bottom: 50px;
        z-index: 1;
        @include fromTablet {
            bottom: 59px;
        }
        @include fromDesktop {
            position: relative !important;
            bottom: 0;
        }
    }
}