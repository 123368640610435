.o-050 {

    &.is-inactive {
      opacity: 0.4;
    }

    & + .o-050 {
        margin-top: 25px;
    }

    &__tirelabel {
        font-size: 12px;
        color: #000 !important;
        display: block;
        margin-top: 10px;
        margin-left: 12px;
        svg {
            width: 11px;
            height: 10px;
            margin-left: 4px;
            top: -1px;
            position: relative;
        }
    }

    &__car-model {
        @include font-primary(h1);
        // @include font-primary-bold(18px, $colorBlack, 400, 22px);
        // @include fromDesktop {
        //     @include font-primary-bold(28px, $colorBlack, 400, 30px);
        // }
    }
    &__car-model-description {
        @include font-primary(copy);
        // @include font-primary-regular(14px, $colorBlack, 400, 20px);
        // @include fromDesktop {
        //     @include font-primary-regular(16px, $colorBlack, 400, 26px);
        // }
    }
    &__emission-wrapper {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            > .col-lg-6 {
                min-height: 1px;
                > img {
                    width: 100%;
                    max-height: 150px;
                    min-height: 1px;
                }
            }
        }
        strong, b{
            font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
            font-weight: normal;
        }
    }
    &__emission-button {
        width: 42px;
        min-width: 42px;
        height: 42px;
        background-color: $colorRed;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            height: 19px;
            width: 15px;
            path {
                fill: $colorWhite !important;
            }
        }
    }
    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
    }
    &__modal-headline {
        font-family: $font-primary-bold;
        font-size: 18px;
        color: $colorBlack;
    }
    &__modal-close {
        background-color: $colorRed !important;
        border-radius: 50%;
        opacity: 1 !important;
        width: 42px;
        height: 42px;
        position: absolute;
        bottom: -21px;
        right: -21px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 13px;
            height: 13px;
            polyline {
                fill: $colorWhite !important;
            }
        }
    }
    &__rate-info {
        margin-top: 7px;
        @include font-primary(tag, $colorBlack);
        a {
            // font-size: 14px !important;
            @include font-primary(tag, $colorRed);
        }
    }
    .text-content-wrapper {
        padding: 20px 12px 25px 12px;
        @include fromDesktop {
            display: flex;
            height: 100%;
            max-width: 360px;
            margin: 0 auto;
            padding: 20px 0 60px;            
            flex-direction: column;
            justify-content: center;
        }
    }
    h1 {
        margin-bottom: 3px;
    }
    .amount {
        margin-bottom: 0; 
        // font-family: $font-primary-bold;
        // @include fromDesktop {
        //     font-size: 28px;
        //     line-height: 33px;
        // }
        @include font-primary(price);
        &--red {
            color: $colorRed;
        }
        sup{
            font-size: 10px;
            top: -8px;
            @include fromDesktop {
                top: -15px;
            }
        }
        
        .o-050__finance-price, .o-050__finance-price a {
            @include font-primary(price);
        }
    }
    .addition {
        @include font-primary(tag, $colorRed);
        // color: $colorRed;
        // font-size: 12px;
        // line-height: 17px;
        // font-family: $font-primary-regular;
        // @include fromDesktop {
        //     font-size: 14px;
        //     line-height: 19px;
        // }
        &--rate {
            color: $colorDarkGrey;
        }
    }
    .a-00800 {
        margin-top: 16px;
        @include fromDesktop {
            margin-top: 36px;
            max-width: 100%;
        }
    }
    .a-00800--c {
        max-width: 100%;
        @include fromDesktop {
            margin-top: 20px;
        }
    }
    .verticalLine {
        border-left: 1px solid $colorLightGrey;
    }
    .priceWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-wrap: nowrap;
        @include fromDesktop {
            margin-top: 9px;
        }
    }
    .price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 46%;
        &--new-car {
            .amount {
                color: $colorRed;
            }
        }
    }
    &__reservation {
        .a-00800 {
            background-color: $colorRed;
        }
    }
    &__testdrive {
        .a-00800 {
            @include fromDesktop {
                margin-top: 20px;
            }
        }
    }
}
