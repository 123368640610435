.a-00400 {
    width: 30px;
    height: 30px;
    background-color: $colorRed;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        color: white;
        line-height: 10px;
        svg {
            width: 100%;
            height: 100%;
            max-width: 13px;
            max-height: 13px;
            @include fillSVG($colorWhite);
        }
    }
}