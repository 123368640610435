.m-0190 {
    .bold {
        font-weight: bold;
    }
    .value {
        word-wrap: break-word;
    }
    .value_col,
    .title-col {
        font-size: 14px;
        line-height: 20px;
        @include fromDesktop {
            font-size: 16px;
            line-height: 28px;
        }
    }
    .title-col {
        @include fromDesktop {
            margin-bottom: 0 !important;
        }
    }
    .value_col {
        text-align: right;
        @include fromDesktop {
            text-align: left;
        }
    }
}