.m-0051 {
    .mobileContent {
        .innerImg {
            @include toTablet {
                width: 100%;
            }
            img {
                @include toTablet {
                    width: 100%;
                }
            }
        }
    }
    &__seperator {
        border-top: 1px solid $colorLightGrey;
    }
    &__container {
        width: 100%;
        @include fromTablet {
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            max-width: $container-width-lg;
        }
        @include fromDesktop {
            padding: 0 $column-padding-desktop;
        }
        @include fromWide {
            max-width: $container-width-xl;
        }
    }
    &__wrapper {
        padding: 0;
    }
    .content {
        padding: 15px 25px 25px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
        }
        @include fromTablet {
            padding: 49px 0px;
        }
    
        @include toTablet(){
            padding: 15px 25px 9px;
        }
    }
    .headline {
        margin-bottom: 7px;
        @include font-primary(h1);
        // @include font-alpha-regular(18px, $colorBlack, normal, 22px);
        @include fromTablet {
            margin-bottom: 25px;
            // @include font-alpha-regular(28px, $colorBlack, normal, 38px);
        }
    }
    .copy {
        @include font-primary(copy);
        // font-size: 14px;
        // line-height: 20px;
        margin-bottom: 15px;
        @include fromTablet {
            // font-size: 16px;
            // line-height: 26px;
            margin-bottom: 35px;
        }
    }
    .desktopContent {
        // @include fromTablet {
        //     margin-right: -25px;
        // }
        // @include fromDesktop {
        //     margin-right: -15px;
        // }
    }
    .innerConent {
        @include fromTablet {
            padding-right: 45px;
        }
        @include fromDesktop {
            padding-right: 115px;
        }
    }
    .innerImg {
        // @include fromTablet {
        //     padding-right: 0 !important;
        // }

    }
    .envkv{
        color: #575656;
        font-size: 14px;
    }
    .a-00800 {
        max-width: 100%;
        @include fromTablet {
            max-width: 267px;
        }
    }
    .a-00500 {
        img {
            width: 100%;
        }
    }
    
    &--a{
        
        &__container-wrapper {
            > .row {
                &:nth-child(even) {
                    .desktopContent {
                        @include fromTablet {
                            flex-direction: row-reverse;
                            margin-left: -25px;
                            margin-right: -15px;
                        }
                    }
                    .innerConent {
                        @include fromTablet {
                            padding-left: 45px;
                            padding-right: 15px;
                        }
                        @include fromDesktop {
                            padding-left: 115px;
                        }
                    }
                    .innerImg {
                        @include fromTablet {
                            padding-left: 0 !important;
                            padding-right: 15px;
                        }
                        @include fromDesktop {
                            padding-right: 12px;
                        }
                    }
                }
            }
        }
    }
   
    &--b {
        .copy {
            margin-bottom: 0;
            @include fromTablet {
                margin-bottom: 35px;
            }
            @include toDesktop(){
                font-weight: bold;
                color: #575656;
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
        .price{
            font-size: 24px;
            margin-bottom: 20px;
            @include toDesktop(){
                text-align: right;
                font-size: 18px;
            }
        }
        .headline{
            @include fromDesktop(){
                margin-bottom: 10px;
            }
            @include toDesktop(){
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        hr{
            margin-top: 30px;
            margin-bottom: 10px;
            @include toDesktop(){
                margin-top: 12px;
            }
        }
        .content{
            padding-left: 0;
            padding-right: 0;
            @include toTablet(){
                padding-left: 11px;
                padding-right: 11px;
            }
        }
    }

    &--c{
        .innerConent{
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
        .headline{
            margin-bottom: 10px;
        }
        .copy{
            margin-bottom: 28px;
        }
        .phoneContainer{
            display: flex;
            margin-bottom: 15px;
            .phone{
                @include font-primary(copy);
            }
            .phoneIcon{
                margin-right: 20px;
                svg{
                    height: 22px;
                }
            }
        }
        .mailContainer{
            display: flex;
            .mail{
                @include font-primary(copy);
                text-transform: uppercase;
            }
            .mailIcon{
                margin-right: 20px;
                svg{
                    height: 22px;
                }
            }
        }
    }
    &__wrapper:nth-child(even) {
        .desktopContent {
            @include fromTablet {
                flex-direction: row-reverse;
                margin-left: -25px;
                margin-right: -15px;
            }
        }
        .innerConent {
            @include fromTablet {
                padding-left: 45px;
                padding-right: 15px;
            }
            @include fromDesktop {
                padding-left: 115px;
            }
        }
        .innerImg {
            @include fromTablet {
                padding-left: 0 !important;
                padding-right: 15px;
            }
            @include fromDesktop {
                padding-right: 12px;
            }
        }
    }
}