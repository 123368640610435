
.m-0252 {
    margin: 22px 25px;
    h4 {
        font-size: 14px ;
        font-weight: 700;
    }
    &--d{
        margin: 22px 0px;
        .imageContentContainer{
            position: relative;
            margin-bottom: 13px;
        }
        .contentOverlay{
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(0deg, rgba(0,0,0,0.7852184819240196) 0%, rgba(0,0,0,0.25860783766631656) 100%);
            padding-left: 19px;
            padding-top: 10px;
        }
        .headline{
            margin-bottom: 1px;
            color: $colorWhite;
        }
        .copy{
            margin-bottom: 15px;
            color: $colorWhite;
        }
        .phoneContainer{
            display: flex;
            a{
                font-weight: bold;
                text-transform: uppercase;
                color: $colorBlack;
            }
            .phoneIcon{
                margin-right: 20px;
                svg{
                    height: 22px;
                }
            }
        }
        .mailContainer{
            display: flex;
            a{
                font-weight: bold;
                text-transform: uppercase;
                color: $colorBlack;
            }
            .mailIcon{
                margin-right: 20px;
                svg{
                    height: 22px;
                }
            }
        }
        .content{
            display: flex;
            justify-content: space-between;
        }
        .seperator{
            color: #CCCCCC;
            font-size: 26px;
        }
    }
}