
.m-0250 {
    @include fromDesktop {
        height: 100px;
        padding: 20px 0 0;
        overflow: visible;
    }
    &__icon-link-list {
        a:last-child {
            border: 0;
        }
        .col-12 {
            a {
                border-bottom: 1px solid $colorGrey;
            }
        }
    }
    &__ul {
        @include fromDesktop {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            flex: 1;
            list-style: none;
            margin: 0;
        }
        .changeMargin{
            margin-left: 15px;
        }
        a {
            @include fromDesktop {
                // color: $colorBlack;
                // text-transform: uppercase;
                // font-size: 14px;
                @include font-primary(copy, $colorBlack);
                letter-spacing: 0.35px;
                // line-height: 19px;
                white-space: nowrap;
                margin-left: 35px;
            }
        }
        li {
            @include fromDesktop {
                &.item-neuwagen {
                    min-width: 84px;
                }
                &.item-fahrzeuge {
                    min-width: 84px;
                }
                &.item-gebrauchtwagen {
                    min-width: 151px;
                }
                &.item-aktionen {
                    min-width: 109px;
                }
                &.item-greenmobility {
                    min-width: 152px;
                }
                &.item-service {
                    min-width: 93px;
                }
                &.item-ueberuns {
                    min-width: 106px;
                }
                &.item-kooperationen {
                    min-width: 154px;
                }
            }

            line-height: normal;
            overflow-y: visible;
            position: relative;
            @include fromDesktop {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                padding-bottom: 20px;
            }
            &:first-child {
                a {
                    @include fromDesktop {
                        margin-left: 0;
                    }
                }
            }
            a {
                &:hover {
                    font-family: mmc-bold, Helvetica, Arial, sans-serif;
                    font-weight: bold;
                }
                &.active {
                    font-family: mmc-bold, Helvetica, Arial, sans-serif;
                    position: relative;

                    &:after {
                        position: absolute;
                        content: '';
                        border-color: #ed0000;
                        border-bottom-width: 3px;
                        border-style: solid;
                        bottom: -20px;
                        left: -5px;
                        right: -5px;
                        height: 3px;
                    }
                }
            }
        }
    }
    ul.m-0250__dropdownBody {
        display: none;
        position: absolute;
        top: 100%;
        right: -5px;
        padding: 20px 20px 0;
        background-color: $colorWhite;
        z-index: 100;
        li {
            @include font-primary(copy, $colorBlack);
            letter-spacing: 0.35px;
            white-space: nowrap;
            margin-left: 0;
        }
    }
    &__dropdown {
        &:hover  .m-0250__dropdownBody {
            display: block;
        }
    }
    &__dropdownMobileBody.hidden{
        display: none;
    }
    .a-01200.open {
        .arrow {
            transform: rotate(-90deg);
        }
    }
}