.m-0140 {
    display: flex;
    .a-01900 {
        max-width: 100%;
        flex: 1 0 50%;
        border-right: 1px solid $colorBorderGray;
        &:last-child {
            border-right: 1px solid transparent;
        }
    }
}