.o-040 {
    &__background {
        background-color: $colorDarkGrey;
        padding-bottom: 12px;
    }
    &__cars-count {
        margin-top: 90px;
        font-size: 16px;
        padding: 0 15px;
    }
    &__desktop-wrapper {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 22px 1fr 22px 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 22px;
        padding: 22px 5px 0 5px;
        /* IE11 Hack at the bottom */
        &--top {
            padding-top: 0;
        }
    }
    &__more-filter-wrapper {
        padding: 0 5px;
        button {
            padding: 25px 0;
            background: none;
            border: 0;
            @include font-lato-bold(14px, $colorBlack, normal, 16px);
            svg {
                width: 16px;
                position: relative;
                top: -2px;
                left: 2px;
            }
        }
        .more-cars {
            .isExpanded {
                display: inline-block;
            }
            .isNotExpanded {
                display: none
            }
            &[aria-expanded="true"] {
                .isExpanded {
                    display: none;
                }
                .isNotExpanded {
                    display: inline-block
                }
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .m-0120__arrow {
        margin-right: 30px;
    }
    &--placeholder {
        .o-040__desktop-wrapper {
            -ms-grid-columns: 1fr;
            grid-template-columns: 1fr;
            /* IE11 Hack at the bottom */
            @include fromPhone {
                -ms-grid-columns: 1fr 22px 1fr;
                grid-template-columns: 1fr 1fr;
                /* IE11 Hack at the bottom */
            }
            @include fromDesktop {
                -ms-grid-columns: 1fr 22px 1fr 22px 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                /* IE11 Hack at the bottom */
            }
        }
        .m-0060 {
            animation: none !important;
            transform: scale(1) !important;
            &:hover {
                animation: none !important;
                transform: scale(1) !important;
            }
            .a-00800 {
                max-width: 100%;
            }
        }
    }
}

 /* IE11 */
 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
 .o-040 {
    &__desktop-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: -11px;
        margin-right: -22px;
    
        px-m0060 {
            max-width: 100%;
            flex: 1 0 100%;
            padding: 0 11px 22px;
            .m-0060 {
                min-height: 100%;
                height: 100% !important;
            }
            .m-0060__image-wrapper {
                flex: 0 0 auto
            }
        }

        @include fromPhone {
            px-m0060 {
                max-width: 50%;
                flex: 1 0 50%;
            }
        }

        @include fromDesktop {
            px-m0060 {
                max-width: 33%;
                flex: 1 0 33%;
            }
        }
    }
}
}