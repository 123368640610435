.m-0220 {
    .contentBox {
        padding: 0 25px;
        background-color: $colorWhite;
        @include fromDesktop {
            padding: 0 15px;
        }
        &.gmap_wrapper{
            font-size: 10px;
            padding: 10px;
            margin: 0;
        }
        &.approach {
            @include toDesktop {
                margin-bottom: 25px;
            }
        }
       
        .headline {
            @include font-primary(h1);
            // font-size: 18px;
            // line-height: 20px;
            margin-bottom: 10px;
            // font-weight: bold;
            @include fromDesktop {
                // font-size: 28px;
                // line-height: 38px;
                // font-weight: 700;
                margin-bottom: 40px;
                margin-top: 40px;
            }
        }
        .subheadline {
            // line-height: 20px;
            // font-size: 14px;
            // font-weight: 700;
            @include font-primary(copy, $colorBlack, bold);
            @include fromDesktop {
            //     font-size: 16px;
            //     line-height: 24px;
                margin-bottom: 5px;
                margin-top: 15px;
            }
            &--margin-bottom {
                margin-bottom: -10px;
                display: block;
            }
            &--noMargin {
                margin: 0;
                // line-height: 24px;
            }
            &--big {
                // @include fromDesktop {
                //     font-size: 20px;
                //     line-height: 32px;
                // }
                @include font-primary(h2);
            }
        }
        .copy {
            // line-height: 20px;
            // @include fromDesktop {
            //     font-size: 16px;
            //     line-height: 26px;
            // }

            &.verkauf, &.service {
                margin: 0;
                padding-top: 0;
            }
            &.openingTime {
                line-height: 24px;
                margin: 0;
                padding-top: 0;
                font-variant-numeric: tabular-nums;
                // -moz-font-feature-settings: "tnum";
                // -webkit-font-feature-settings: "tnum";
                font-feature-settings: "tnum";
                @include fromDesktop {
                    line-height: 26px;
                }
            }
        }

        .hours{
            margin-bottom: 5px;
            display: block;
            & + .hours {
                margin-top: -5px;
            }
        }
        .link {
            @include font-primary(copy, $colorBlack);
            // @include fromDesktop {
            //     font-size: 16px;
            //     line-height: 26px;
            //     font-weight: 400;
                // color: $colorDarkGrey;
            //     font-family: $font-primary-regular;
            // }
        }
        .title {
            font-size: 16px;
            @include fromTablet {
                font-size: 14px;
                    
            }   
        }

        
        .break_word {
            word-wrap: break-word;   
        }

        .openingHours {
            margin-bottom: 10px;

            .dayCol {
                padding-right: 0;
            }
            .timeCol {
                padding-left: 0;
            }
        }
    }
    
    .someIcons {
        display: flex;
        @include toDesktop {
            margin-top: -5px;
            margin-bottom: 10px;
        }
        a {
            display: block;
            margin-right: 25px;
        }
    }

    .standort {
        font-size: 12px;
        @include fromDesktop {
            font-size: 16px;                    
        }
    }

    .standort_wrapper {
        margin-top: 20px;
        margin-bottom: 15px;
        &--filiale {
            margin-top: 15px;
            .someIcons, .subheadline--soMe {
                display: none;
            }
        }
        @include fromDesktop {
            margin-bottom: 35px;                 
        }
    }

    .googleMaps {
        // background-color: red;
    }

    .gmap_container {
        margin-bottom: 25px;
        background-color: $colorWhite;
    }

    .separator {
        border-top: 1px solid #9E9FA0;
        margin-top: $colorArrowGrey;
    }

    .a-01500 {
        border-bottom: 0px solid transparent;
        @include fromDesktop {
            justify-content: center;
            align-items: center;
            // .text {
            //     font-size: 14px;
            // }
            .arrow {
                margin-left: 10px;
            }
        }
    }

    .a-00800 {
        max-width: 100%;
    }
}

