.m-0070 {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $colorLightGrey;
    min-height: 100%;
    // margin-bottom: 25px;
    transition: opacity 0.7s, transform 0.8s;
    transition-delay: 0.5s;
    &.active {
        // opacity: 0;
        // transform: scale(1.4, 1.4)
    }
    &__container {
        padding-top: 12px;
        @include fromTablet {
            padding-top: 40px;
        }
        @include fromDesktop {
            padding-top: 80px;
        }
        .btn-editMode {
            display: none;
            @include fromDesktop {
                display: block;
            }
        }
    }
    &__wrapper {
        margin-bottom: 12px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex-basis: 100%;
        }
        @include column-grid;
        @include fromTablet {
            margin-bottom: 24px;
            padding: 0;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex-basis: 49.2%;
            }
        }
        @include fromDesktop {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex-basis: 32.2%;
            }
        }
    }
    &__teaser-wrapper {
        display: -ms-grid;
        display: grid;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        @include fromTablet {
            grid-column-gap: 24px;
            grid-auto-rows: 1fr;
            -ms-grid-columns: 1fr 24px 1fr;
            grid-template-columns: 1fr 1fr;
        }
        @include fromDesktop {
            -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between
        }

        &--auto-rows {
            grid-template-rows: auto;
            grid-auto-rows: max-content;
        }
    }
    // &__container {
    //     .row .col-md-4 {
    //         margin-bottom: 15px;
    //         @include fromDesktop {
    //             margin-bottom: 25px;
    //         }
    //     }
    // }
    .img {
        flex: 0 0 0;
        &__arrow {
            position: absolute;
            right: 20px;
            bottom: 19px;
            color: $colorWhite;
            width: 12px;
            transition: right 0.6s;
            z-index: 5;
            svg {
                @include fillSVG($colorWhite);
            }
        }
        &__link {
            position: relative;
            display: block;
            width: 100%;
            min-height: 152px;
            background-color: black;
        }
    }
    &:hover {
        .img__arrow {
            right: 13px;
            animation-name: m-0070Arrow;
            animation-duration: 0.6s;
        }
    }
    .headline-top {
        margin-bottom: 5px;
    }
    .headline {
        margin-bottom: 10px;
        @include font-primary(h1);
    }
    .copy {
        margin-bottom: 10px;
        // color: $colorDarkGrey;
        @include font-primary(copy);
        // @include fromDesktop {
        //     font-size: 16px;
        //     line-height: 26px;
        // }
    }
    .contentWrapper{
        flex: 1 0 auto;
        max-height: 100%;
        display: flex;
    }
    .content {
        width: 100%;
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include fromDesktop {
            padding: 20px 25px 15px;
        }
    }
    hr {
        margin: 10px 0 5px;
    }
    .title {
        margin-bottom: 5px;
        color: $colorBlack;
        @include font-primary(copy-bold);
        line-height: 18px;
    }
    .price {
        text-align: right;
        @include font-primary(copy);
    }
    .price-amount {
        @include font-primary(price);
    }
    .envkv {
        color: $colorDarkGrey;
        font-size: 14px;
        line-height: 18px;
        @include fromDesktop {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .employee{
        strong {
            @include font-primary(h2);
            font-weight: 400 !important;
        }
    }
    px-a00900 {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        .a-00900 {
            min-width: 100%;
        }
    }
    .a-00600--d {
        max-height: 224px;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
    &--d {
        // margin-bottom: 12px;
        .content {
            justify-content: flex-start;
        }
    }
    > px-edit-button {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}
.cdk-overlay-pane.edit-mask-panel {
    overflow: auto;
    max-height: 100%;
}
.teaserEdit {
    &__btn {
        margin-bottom: 10px;
    }
    &__header {
        position: relative;
        height: 25px;
    }
    &__close {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 16px;
        font-size: 30px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
    }
    &__content {
        ngb-tabset {
            position: relative;
            display: block;
        }
        .nav-tabs {
            .nav-item {
                position: relative;
                border-radius: 0;
                &:after {
                    content: '';
                    // height: 100%; 
                    width: 1px;
                    position: absolute;
                    right: 0;
                    top: 20px; 
                    bottom: 20px;
                    background-color: $colorEditBorder; 
                }
                &:last-child {
                    &:after {
                        background-color: $colorWhite;
                    }
                }
                .nav-link {
                    position: relative;
                    padding-top: 20px;
                    padding-bottom: 24px;
                    border-color: $colorWhite;
                    border-bottom-color: $colorEditBorder;
                    color: $colorBlack;
                    &.active {
                        border-bottom-color: $colorWhite;
                        &:after {
                            content: '';
                            height: 4px; 
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: $colorRed; 
                        }
                    }
                    &:hover {
                        color: $colorBlack;
                    }
                }
            }
        }
    }
    &__tabContent {
        border: 1px solid $colorEditBorder;
        border-top-color: transparent;
        .grid-wrapper {
            position: relative;
            width: 1199px;
            max-width: 1199px;
            height: 900px !important;
            overflow-y: auto;

        }
        .grid-wrapper-hero {
            position: relative;
            width: 1199px;
            max-width: 1199px;
            height: 600px !important;
            overflow: auto; 

        }
        &--newTeaser, &--oldTeaser {
            padding: 10px;
            min-height: 615px;
        }
        &--oldTeaser {
            border-color: transparent;
        }
    }
    &__gridScrollWrapper {
        max-height: 700px;
        overflow-y: auto;
    }
    &__gridScrollWrapperMin {
        max-height: 700px;
        min-height: 700px;
        overflow-y: auto;
    }
    &__activateBtn {
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__grid {
        background-color: transparent;
    }
    &__item {
        // display: flex !important;
        // flex-direction: column;
        // justify-content: space-between;
        background-color: $colorLightGrey;
        overflow-y: auto;
    }
    &__itemContent {
        display: flex;
        height: 100%;
        padding-top: 35px;
        flex-direction: column;
        justify-content: space-between;
    }
    &__itemImg {
        max-height: 197px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
            width: 100%;
        }
    }
    &__itemForm {
        padding: 10px;
    }
    &__itemChecboxLabel {
        vertical-align: middle;
        margin-bottom: 10px;
    }
    &__itemChecbox {
        position: relative;
        display: inline-block;
        top: 2px;
        width: 16px;
        height: 16px;
    }
    &__itemInput + &__itemInput {
        margin-top: 10px;
    }
    &__editTeaser {
        position: absolute;
        width: 16px;
        top: 8px;
        right: 35px;
        line-height: 19px;
        cursor: pointer;
    }
    &__removeTeaser {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 16px;
        height: 16px;
        font-size: 30px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
    }
    &__headline {
        padding: 10px 16px 10px 10px;
    }
    &__saveBtn {
        margin-top: 10px;
        float: right;
    }
    &__stepper {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    &__stepperLink {
        display: none;
        color: $colorEdit;
        &.active {
            display: block;
        }
    }
    &__stepperItem {
        padding: 10px 10px 10px 0;
    }
    &__newTeaserWrapper {
        padding-bottom: 47px;
    }
    &__newTeaserRow {
        margin-left: 0;
        max-height: 693px;
        overflow-y: auto;
    }
    &__newTeaser {
        padding: 20px 15px 0 0;
    }
    &__newTeaserContent {
        display: flex;
        height: 100%;
        padding: 15px;
        border: 1px solid $colorEditBorder;
        flex-direction: column;
        justify-content: space-between;
    }
    &__chooseBtn {
        display: block;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
    }
    &__createForm {
        min-height: 530px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // max-height: 693px;
    }
    &__create {
        margin-top: 20px;
        min-height: 600px;
    }
    &__createImgUp {
        margin-bottom: 20px;
        // display: block;
        position: relative;
        display: flex;
        flex-direction: column;
        .custom-file-input {
            cursor: pointer;
        }
        .custom-file-label {
            &::after {
                content: 'Durchsuchen...' !important;
            }
        }
        .btn-editMode {
            display: block;
        }
        &.error {
            .custom-file-label {
                border-color: $colorRed;
                border-width: 2px;
                &::after {
                    max-height: 100%;
                }
            }
        }
    }
    &__createPreview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .a-00600 {
            img {
                width: 100%;
            }
        }
        .m-0070 {
            min-height: 0;
        }
    }
    &__createFormRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 15px;
        .form-group {
            textarea, select, input[type=text]{
                &.error {
                    border-color: $colorRed;
                    border-width: 2px;
                }
            }
        }
        .btn-editMode {
            &.error {
                border-color: $colorRed;
                border-width: 2px;
            }
        }
        .custom-file-input.error + label {
            border-color: $colorRed;
            border-width: 2px;
            &:after {
                border-bottom: 2px solid #ed0000;
            }
        }
     }
    &__createBtn {
        &.disabled {
            opacity: .65;
        }
    }
    &__createVehicleWrapper {
        padding: 15px 15px 0;
        border: 1px solid $colorEditBorder;
        border-top-color: transparent;
        input[type=radio]{
            margin-right: 5px;
            & + label {
                display: flex;
            }
        }
    }
    &__createVehicleListItem {
        display: flex;
    }
    &__createVehicleImg {
        max-width: 20%;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-self: center;

        img {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    &__createVehicleInfo {
        max-width: 80%;
        margin: 0;
    }
    &__croppedImg {
        max-height: 410px;
        & + .btn {
            position: absolute;
            width: 41px;
            top: 0;
            right: 0; 
            font-size: 28px;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
        }
    }
    &__imgCropper {
        max-height: 420px;
        .cropper img {
            max-width: 474px;
            max-height: 410px;
        }
        & + .btn {
            margin-left: auto;
            width: 115px;
            width: max-content;
        }
    }
    &__saved {
        width: 100%;
        margin-top: 150px;
    }
    &__savedIcon {
        width: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    &__savedText {
        text-align: center;
    }

    
}

@keyframes m-0070Arrow {
    0%      {  right: 20px; }
    50%     {  right: 10px; }
    75%     {  right: 15px; }
    100%    {  right: 13px; }
}
