.c-0015 {
    position: relative;
    width: 100%;

    .video_wrapper {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        height: 61.5vw;
        width: 100%;

        .video_trigger {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            z-index: 9;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
        }

        .video_layer {
            position: relative;
            display: block;
            height: inherit;
            width: inherit;
            margin: auto;

            .cmplazypreviewiframe {
                width: 100% !important;
                height: 100% !important;
                background-color: transparent !important;
                background-image: none !important;
                text-align: center !important;
            }
            
            #ytplayer {
                width: 100%;
                height: 100%;
            }
        }
    }
    .c-0015__envkv {
        position: static;
        width: 100%;
        @include fromTablet {
            position: absolute;
            bottom: 0;
        }
    }
    // .a-00500 {
    //     .embed-responsive-16by9::before {
    //         padding-top: 0;
    //     }
    // }
    // .a-00500 {
    //     .embed-responsive-16by9::before {
    //         content: none
    //     }
    // }
}