.a-00900 {
    height: 44px;
    width: 100%;
    // max-width: 267px;   
    background-color: black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: $colorDarkGrey;
    }
    &:focus {
        background-color: $colorRed;
    }
    &.disabled {
        background-color: $colorGrey;
    }
    .elementWrapper {
        display: flex;
        align-items: center;
    }
    .icon {
        width: 18px;
        position: absolute;
        left: 20px;
        &.calendar_datepicker_footer_weiss {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 22px;
                top: 9px;
            }
        }
        svg {
            width: 100%;
            height: 100%;
             @include fillSVG($white);
        }
    }
    .cta, .text {
        @include font-primary(cta);
        color: white !important;
    }
    &.big {
        @include fromTablet {
            max-width: 286px;
            margin: 0 auto;
        }
        @include fromDesktop {
            max-width: 480px;
        }
    }
    &--a {
        
    }
    &--b {
        background-color: white;
        .icon {
            /* START - Only for Preview purpose - REMOVE if not needed anymore*/
            width: 20px;
            height: 20px;
            background: black;
            /* END - Only for Preview purpose - REMOVE if not needed anymore*/
        }
        .cta, .text {
            color: black !important;
        }
        &:hover {
            background-color: white;
            .cta, .text {
                color: $colorDarkGrey !important;
            }
        }
    }
}