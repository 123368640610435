.module-bulletList {
    .container {
        max-width: 100%;
    }
}
.c-0020 {
    position: relative;
    .o-070__sections-desktop {
        margin: 0;
        padding: 25px 15px;
            @include fromPhone {
                padding: 45px 40px;
            }
            @include fromTablet {
                padding: 50px 40px;
            }
            @include fromDesktop {
                padding: 55px 55px;
            }
            @include fromExtraWide {
                padding: 55px 80px;
            }
        .m-0160 h3.cars-detail__headline {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 26px;
            @include fromPhone {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 44px;
                max-width: 630px;
            }
            @include fromDesktop {
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 47px;
                max-width: 951px;
            }
        }
        .m-0160__bulletlist {
            &--campaign {
                row-gap: 20px;
                display: grid;
                grid-template-columns: repeat(1, minmax(0, 337px));
                @include fromPhone {
                    gap: 25px;
                    row-gap: 25px;
                    grid-template-columns: repeat(2, minmax(0, 281px));
                    // column-gap: 91px;
                    max-width: 653px;
                    justify-content: space-between;
                }
                @include fromDesktop {
                    // column-gap: unset;
                    max-width: 1172px;
                    justify-content: space-between;
                    grid-template-columns: repeat(3, minmax(0, 324px));
                }
                @include fromWide {
                    max-width: unset;
                    grid-template-columns: repeat(4, minmax(0, 324px)); 
                }
                px-a02700 {
                    max-width: 337px;
                    width: 100%;
                    margin: 0;
                    @include fromPhone {
                        max-width: 281px;
                    }
                    @include fromDesktop {
                        max-width: 324px;
                    }
                    .a-02700__text {
                        font-size: 15px;
                        line-height: 18px;
                        @include fromDesktop {
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }
            }
        }
    }
}