.m-0290 {
    background-color: $colorBlack;
    padding: 0 25px;
    .a-01300 {
        max-width: 100%;
        &:last-child {
            border-bottom: 0 solid transparent;
        }
        .cta {
            color: white;
            padding: 0 15px;
        }
    }
}