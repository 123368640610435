.m-0100 {
    padding-left: 25px;
    @include fromDesktop {
        padding-left: 0;
    }
    .wrapper {
        display: flex;
        margin-bottom: 20px;
        @include fromDesktop {
            margin-top: 22px;
            margin-bottom: 0;
        }
    }
    .headline {
        margin-bottom: 0;
        @include font-primary(h1);
        // font-size: 18px;
        // font-weight: bold;
        // line-height: 22px;
        // @include fromDesktop {
        //     font-size: 28px;
        //     line-height: 38px;
        // }
    }
    .ratingSum {
        height: 66px;
        margin-right: 15px;
        margin-top: 15px;
        @include fromDesktop {
            height: 74px;
        }
    }
    .ratingWrapper {
        display: inline-block;
        margin-top: 15px;
        .externalLink {
            // font-size: 14px;
            // font-weight: 400;
            // line-height: 20px;
            @include font-primary(copy);
            color: $colorDarkGrey;
            @include fromDesktop {
                display: block;
                margin-top: 7px;
                font-size: 15px;
            }
        }
    }
    .starList {
        list-style: none;
        margin-bottom: 0;
        li {
            float: left;
        }
    }
}