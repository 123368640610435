.m-0030 {
    @include fromTablet {
        display: flex;
        flex-direction: row;
    }
    @include fromDesktop {
        display: block;
    }
    .a-00700__wrapper {
        @include fromTablet {
            
        }
        
        & + .a-00700__wrapper {
            &:before {
                content: '';
                height: 1px;
                display: block;
                width: 100%;
                background-color: $colorGrey;
                left: 0px;
                position: relative;
                z-index: 1;
                @include fromTablet {
                    display: none;
                }
                @include fromDesktop {
                    display: block;
                }
            }
        }
    }
}

