.a-03100 {
    display: flex;
    align-items: center;
    background-color: $colorButtonGrey;
    color: black;
    height: 30px;
    width: 100%;
    span {
        font-size: 10px;
        font-weight: bold;
        margin-left: 12px;
    }
}