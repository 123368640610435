.c-0016 {
    width: 100%;
    background-color: #000000;

    &__content {
        text-align: center;
        padding: 15px 0;
        @include fromPhone {
            padding: 30px 0;
        }
        @include fromTablet {
            padding: 45px 0;
        }
        @include fromDesktop {
            padding: 53px 0;
        }

        &_inner {
            padding: 25px 15px 0;
            @include fromPhone {
                padding: 30px 15px 0;
            }
            @include fromTablet {
                padding: 45px 15px 0;
            }
        }

        &_headline {
            color: $colorWhite;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 0;
            padding: 0 15px;
            @include fromPhone {
                font-size: 21px;
                line-height: 25px;
            }
            @include fromDesktop {
                font-size: 25px;
                line-height: 30px;
            }
        }
        &_price_wrap {
            margin-bottom: 0;
        }
        &_price_for {
            color: $colorWhite;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 0;
            @include fromPhone {
                font-size: 19px;
                line-height: 23px;
            }
            @include fromDesktop {
                font-size: 21px;
                line-height: 25px;
            }
        }
        sup {
            font-size: 10px;
            line-height: 13px;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 50%;
            width: 13px;
            height: 13px;
            display: inline-block;
            position: relative;
            top: -16px;
            left: 3px;
        }
        &_price_total {
            color: $colorWhite;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 4px;
            @include fromPhone {
                font-size: 28px;
                line-height: 34px;
                margin-bottom: 1px;
            }
            @include fromDesktop {
                font-size: 31px;
                line-height: 37px;
                margin-bottom: 3px;
            }
        }
        &_copyText {
            color: $colorWhite;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0px;
            @include fromPhone {
                font-size: 14px;
                line-height: 17px;
            }
            @include fromDesktop {
                font-size: 16px;
                line-height: 19px;
            }
        }
        &_text {
            color: $colorWhite;
            font-family: $font-primary-bold;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            padding: 15px 0;
            margin-bottom: 0;
            @include fromPhone {
                font-size: 19px;
                line-height: 23px;
                padding: 15px 0 11px;
            }
            @include fromDesktop {
                font-size: 21px;
                line-height: 25px;
                padding: 15px 0 13px;
            }
        }
    }
}
