// /* Webfont: Lato-Bold */
// @font-face {
//   font-family: 'LatoWebBold';
//   src:  url('../Fonts/Lato/Lato-Bold.woff2') format('woff2'),
//         url('../Fonts/Lato/Lato-Bold.woff') format('woff');
//   font-style: normal;
//   font-weight: normal;
//   text-rendering: optimizeLegibility;
// }

// /* Webfont: Lato-Regular */
// @font-face {
//   font-family: 'LatoWeb';
//   src:  url('../Fonts/Lato/Lato-Regular.woff2') format('woff2'),
//         url('../Fonts/Lato/Lato-Regular.woff') format('woff');
//   font-style: normal;
//   font-weight: normal;
//   text-rendering: optimizeLegibility;
// }

// /* Webfont: Alpha-Regular */
// @font-face {
//   font-family: 'AlphaWeb';
//   src:  url('../Fonts/Alpha/lineto-alpha-regular.woff2') format('woff2'),
//         url('../Fonts/Alpha/lineto-alpha-regular.woff') format('woff');
//   font-style: normal;
//   font-weight: normal;
//   text-rendering: optimizeLegibility;
// }

// /* Webfont: Alpha-Bold */
// @font-face {
//   font-family: 'AlphaWebBold';
//   src:  url('../Fonts/Alpha/lineto-alpha-bold.woff2') format('woff2'),
//         url('../Fonts/Alpha/lineto-alpha-bold.woff') format('woff');
//   font-style: normal;
//   font-weight: normal;
//   text-rendering: optimizeLegibility;
// }

/* Webfont: Alpha-Regular */
@font-face {
  font-family: 'mmc-regular';
  src:  url('../Fonts/Mmc/MMC-Regular.woff2') format('woff2'),
        url('../Fonts/Mmc/MMC-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

/* Webfont: Alpha-Bold */
@font-face {
  font-family: 'mmc-bold';
  src:  url('../Fonts/Mmc/MMC-Bold.woff2') format('woff2'),
        url('../Fonts/Mmc/MMC-Bold.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}