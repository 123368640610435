.a-01600 {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    &__collapse {
    }
    .elementWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .icon {
        width: 24px;
        height: 24px;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    .text {
        margin-left: 12px;
        color: black;
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        @include font-primary(cta);
    }
    .arrow {
        display: flex;
        align-items: center;
        color: black;
        width: 16px;
        height: 100%;
        svg {
            width: 100%;
            height: 9px;
        }
    }
    &[aria-expanded="true"] {
        .arrow {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &:hover {
        .text, .arrow {
            color: $colorDarkGrey;
        }
        .icon {
            // background: $colorDarkGrey;
        }
    }
    &:focus {
        .text, .arrow {
            color: black;
        }
        .icon {
            // background: black;
        }
    }
    &.disabled {
        .text, .arrow {
            color: $colorGrey;
        }
        .icon {
            // background: $colorGrey;
        }
    }
}

px-a01600 {
    border-bottom: 1px solid $colorGrey;
    display: block;
}