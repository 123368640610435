.new-flag {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 50px;
    padding-top: 6px;
    padding-left: 4px;
    overflow: hidden;
    @include fromPhone {
        width: 128px;
        height: 62px;
        padding-top: 10px;
        padding-left: 6px;
    }
    @include fromDesktop {
        width: 100px;
        height: 50px;
        padding-top: 6px;
        padding-left: 4px;
    }
    @include fromWide {
        width: 128px;
        height: 62px;
        padding-top: 10px;
        padding-left: 6px;
    }
    
    &__slope {
      background-color: #ed0000;
      transform: skew(25deg);
      transform-origin: top;
      position: absolute;
      width: 100%;
      height: 100%;
      left: -28px;
      top: 0;
    }
  
    p {
      color: white;
      z-index: 2;
      position: relative;
    }
  
    &__headline {
        font-size: 28px;
        margin-bottom: 4px;
        @include fromPhone {
            font-size: 41px;
            margin-bottom: 8px;
        }
        @include fromDesktop {
            font-size: 28px;
            margin-bottom: 4px;
        }
        @include fromWide {
            font-size: 41px;
            margin-bottom: 8px;
        }
    }
    
    &__subline {
        font-size: 10px;
        @include fromPhone {
            font-size: 11px;
        }
        @include fromDesktop {
            font-size: 10px;
        }
        @include fromWide {
            font-size: 11px;
        }
    }

    &--large {
        @include fromPhone {
            width: 200px;
            height: 106px;
            padding-top: 24px;
        }
        @include fromDesktop {
            width: 157px;
            height: 84px;
            padding-top: 17px;
            padding-left: 8px;
        }
        @include fromWide {
            width: 200px;
            height: 106px;
            padding-top: 24px;
        }

        .new-flag__slope {
            @include fromPhone {
                left: -50px;
            }
            @include fromDesktop {
                left: -40px;
            }
            @include fromWide {
                left: -50px;
            }
        }

        .new-flag__headline {
            @include fromPhone {
                font-size: 68px;
                margin-bottom: 27px;
            }
            @include fromDesktop {
                font-size: 52px;
                margin-bottom: 16px;
            }
            @include fromWide {
                font-size: 68px;
                margin-bottom: 27px;
            }
        }

        .new-flag__subline {
            @include fromPhone {
                font-size: 20px;
            }
            @include fromDesktop {
                font-size: 15px;
            }
            @include fromWide {
                font-size: 20px;
            }
        }
    }
  }