.a-02200 {
    position: relative;
    label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 14px 15px;
        height: 46px;
        font-weight: 300;
        color: $colorDarkGrey;
        font-size: 14px;
        line-height: 20px;
        transition:
            font-size 0.2s,
            height 0.2s,
            top 0.2s,
            bottom 0.2s,
            right 0.2s,
            left 0.2s;
    }
    textarea {
        width: 100%;
        max-height: 450px;
        background: transparent;
        position: relative;
        z-index: 1;
        padding: 8px 15px;
        outline: 0;
        border: 1px solid $colorFormBorder;
        box-shadow: 0;
        color: $colorRed;
        text-shadow: 0px 0px 0px $colorDarkGrey;
        -webkit-text-fill-color: transparent;
        min-height: 185px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            color: black;
        }
        &.isValid {
            border: 1px solid $colorFormBorder;
            padding-top: 19px;
            & + label {
                color: black;
                @extend .labelActive;
            }
        }
        &:focus {
            border: 1px solid black;
            padding-top: 19px;
            & + label {
                color: black;
                @extend .labelActive;
            }
        }
        &.hasError {
            border: 1px solid $colorRed;
            padding-top: 19px;
            & + label {
                color: $colorRed;
                @extend .labelActive;
            }
        }
    }
    &--opv-rating {
        textarea {
            &.ng-dirty {
                border: 1px solid black;
                padding-top: 19px;
                & + label {
                    color: black;
                    @extend .labelActive;
                }
            }
        }
    }
}

.labelActive {
    height: auto;
    padding: 5px 15px 0;
    // z-index: 2;
    font-size: 10px;
    line-height: 16px;
    font-weight: bold;
}