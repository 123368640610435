.o-010 {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    &__container {
        width: 100%;
        max-width: none;       
        @include fromTablet {
            padding: 0; 
        }
        @include fromDesktop {
            max-width: $container-width-lg;
            padding: $column-padding-desktop;
        }
        @include fromWide {
            max-width: $container-width-xl;
        }
    }
    .content {
        background-color: $colorWhite;
        width: 100%;
        border-bottom: 1px solid $colorBorderGray;

        @media (max-width: 1072px) {
            left: 90px;
        }


        @include fromDesktop {
            position: absolute;
            width: 411px;
            border-bottom-color: transparent;
            top: 215px;
           
        }
        &--a {
            padding: 25px 25px 20px 25px;
            @include fromDesktop {
                padding: 25px;
            }
        }
        &--b {}
    }
    hr {
        border-top-color: $colorBorderGray;
        margin: 20px -25px;
    }
    .m-0020 {
        .a-00500 {
            text-align: center;
            height: 180px;
          //  left: 50%;
            overflow: hidden;
            @include fromPhone {
                height: 250px;
            }
            @include fromTablet {
                height: 320px;
            }
            @include fromDesktop {
                height: 600px;
            }
            img.img-fluid {
                display: inline-block;
                position: relative;
                width: auto;
                transform: translateX(-50%);
                margin-left: calc(50%);
                max-width: none;
                height: 100%;

                @include fromExtraWide{
                    width: 100%;

                }
            }
            @include fromExtraWide{
                @media all and (-ms-high-contrast:none)
                {
                *::-ms-backdrop, img.img-fluid {
                     max-height: none;
                     height: auto;
                    } /* IE11 */
                }
            }
        }
        .wrapper {
            // display: block;
            position: static;
            @include fromDesktop {
                // width: 100%;
                // max-width: 960px;
                // margin-left: auto;
                // margin-right: auto;
                .a-00300 {
                    padding-left: 0;
                    left: auto;
                    top: 34px;
                    bottom: unset;
                    @include fromDesktop {
                        top: 71px;
                    }
                    span {
                        // font-size: 24px;
                        // line-height: 32px;
                        // color: white;
                        // background-color: $colorRed;
                    }
                }
                
            }
            @media (max-width: 1072px) {
                .wrapper-headline {
                    left: 90px ;
                }
            }
            // @include fromWide {
            //     max-width: 1140px;
            // }
            
        }
    }
    .m-0140 {
        margin-bottom: 15px;
    }
    .a-02500 {
        margin-bottom: 15px;
    }
    .a-00900 {
        max-width: 100%;
    }
    &--b{
        @include fromDesktop {
            .m-0020 .wrapper .a-00300 {
                top: 80px;
            }
        }
    }
}


