.o-080 {
    padding: 20px 0 25px;
    background-color: $colorLightGrey;
    @include fromDesktop {
        padding: 68px 0 80px;
    }
    .slick {
       &-arrow {
        height: 40px;
        width: 40px;
           &:before {
                height: 40px;
                width: 40px;
                font-size: 40px;
                line-height: 38px;            
                color: $colorArrowGrey;
           }
       }
       &-next, &-prev {
        &:before {
            content: '';
        }
        img, svg {
            height: 100%;
            width: auto;
            @include fillSVG($colorArrowGrey);
            vertical-align: top;
        }
       }
       &-next {
            right: -85px;
       }
       &-prev {
            left: -85px;
       }
       &-list{
          transition: height 1s;
          padding: 0 27px 0 9px !important;
          @include fromDesktop {
          padding: 0 !important;
          }
          .slick-slide {
               // margin: 0 6px;
               &:focus, a {
                outline: none;
              }
          }
          
       }
       &-dots {
          height: 40px;
          bottom: -40px;
          display: flex;
          justify-content: center;
          li {
              width: 7px;
              height: 7px;
              margin: 5px;
              &.slick-active {
                  width: 9px;
                  height: 9px;
                  margin: 4px 3px;
              }
              button {
                  width: 100%;
                  height: 100%;
              }
          }
      }
    }
    .sliderCol {
     padding: 0;
     @include fromDesktop {
          padding: 0 15px;
     }
    }
}