.a-03400 {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid $colorGrey;
    &.empty {
        pointer-events: none;
        height: 25px;
    }
    &__collapse {
        border-bottom: 1px solid $colorGrey;
        padding-bottom: 15px;

        .subheadline {
            line-height: 18px;
            font-size: 14px;
            font-weight: 700;
            margin-top: 15px;
            margin-bottom: 5px;
        }

        .openingTime {
            margin: 0;
        }
        
        .timeCol {
            .openingTime {
                font-size: 12px;
                line-height: 22px;
            }
        }
    }
    .elementWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .text {
        font-size: 10px;
        color: $colorDarkGrey;
        font-weight: 400;
        br {
            display: none;
        }
        strong {
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            margin-right: 7px; 
            &.open {
                color: $colorGreen;
            }
            &.closing {
                color: $colorYellow;
            }
            &.closed {
                color: $colorRed;
            }
        }
    }
    .arrow {
        display: flex;
        align-items: center;
        color: black;
        transform: rotate(90deg);
        transition: transform .2s ease-in-out;
    }
    &:hover {
        .text {
            color: $colorDarkGrey;
        }
    }
    &[aria-expanded='true'] {
        .arrow {
            transform: rotate(270deg);
        }
    }
}