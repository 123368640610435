.c0014__teaser {
    &__container-wrapper {
        &_inner {
            position: relative;

            .image-primitive {
                px-lazy-image {
                    img {
                        width: 100%;
                        @include fromPhonePlus {
                            object-fit: cover;
                            height: 290px;
                        }
                        @include fromDesktop {
                            object-fit: cover;
                            height: 425px;
                        }
                    }
                }
            }

            .c0014__teaser_content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                .c0014__teaser_inner {
                    padding: 0 15px 30px;

                    @include fromPhone {
                        padding: 0 30px 30px;
                    }

                    @include fromTablet {
                        padding: 0 40px 30px;
                    }

                    @include fromDesktop {
                        padding: 0 50px 50px;
                    }

                    @include fromWide {
                        padding: 0 80px 50px;
                    }



                    .c0014__teaser_text {
                        width: 500px;
                        max-width: 100%;

                        @include fromWide {
                            width: 100%;
                        }

                        .c0014__teaser_subheadline {
                            color: $colorWhite;
                            font-size: 20px;
                            line-height: 1.2;
                            margin-bottom: 0;
                            @include fromPhone {
                                font-size: 25px;
                            }

                            @include fromDesktop {
                                font-size: 30px;
                                line-height: 36px;
                            }
                        }

                        h2.c0014__teaser_headline {
                            color: $colorWhite;
                            font-family: $font-primary-regular;
                            font-size: 25px;
                            line-height: 1.2;
                            margin-bottom: 30px;
                            word-break: break-word;
                            hyphens: none;

                            @include fromPhone {
                                font-size: 40px;
                            }

                            @include fromDesktop { 
                                font-size: 50px;
                                margin-bottom: 80px;
                            }
                        }
                    }

                    .c0014__teaser_btn {
                        .a-01000--c {
                            width: 250px;
                            height: 42px;
                            @include fromPhonePlus {
                                width: 220px;
                                height: 42px;
                            }
                            @include fromTablet {
                                width: 260px;
                                height: 50px;
                            }
                            &:hover {
                                background-color: transparent;
                                opacity: 0.4;
                            }
                            .text, span {
                                text-transform: none;
                                font-size: 12px;
    
                                @include fromTablet {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                }

            }
        }
    }
}