.o-100 {
    .collapse {
        width: 100%;
    }
    .button-more {
        cursor: pointer;
        width: 100%;
        text-align: center;
        border: 0;
        border-top: 1px solid $colorGrey;
        margin-top: 0;
        background: none;
        padding: 25px 0;
        outline: 0 !important;
        text-transform: uppercase;
        // @include font-lato-bold(14px, black, normal, 16px);
        @include font-primary(cta,  $colorBlack);
        svg {
            width: 18px;
            height: 12px;
            margin-left: 5px;
            position: relative;
            top: -1px;
            polygon {
                fill: black !important;
            }
        }
        .content-shown {
            svg {
                transform: rotate(180deg);
            }
        }
        .content-collapsed {
            display: none;
        }
        &.collapsed {
            .content-collapsed {
                display: block;
            }
            .content-shown {
                display: none;
            }
        }
    }
    &__headline {
        @include font-primary(h1, $colorWhite);
        // color: $colorWhite;
        margin-bottom: 16px;
    }
    &__content-wrapper-desktop {
        max-width: 357px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
    &__background {
        background-color: $colorBlack;
        @include fromDesktop {
            background-color: $colorDarkGrey;
        }
    }
    &--car-detail {
        padding-top: 21px;
        padding-bottom: 27px;
        padding-left: 14px;
        padding-right: 14px;
        max-width: 400px;
        margin: 0 auto;
        @include fromDesktop {
            margin-top: 40px;
            padding-top: 70px;
            padding-bottom: 80px;
            padding-left: 0;
            padding-right: 0;
            max-width: none;
            margin: 0;
        }
        .text {
            p {
                color: $colorWhite;
            }
        }
        .buttons {
            a {
                color: $colorWhite;
                svg path {
                    fill: $colorWhite !important;
                }   
            }
        }
    }
    .a-00600 {
        .gradient {
            @include fromDesktop {
                display: none;
            }
        }
    }
}