.m-0090 {
    &__wrapper {
        display: flex;
        padding-top: 25px;
        background-color: $colorWhite;
        justify-content: center;
        @include fromDesktop {
            padding-top: 30px;
        }
    }
    &__col {
        max-width: 50%;
        
        @include fromDesktop {
            flex-basis: 225px;
            max-width: 255px;
            max-height: 50px;
        }
        &--big {
            flex-basis: 375px;
            max-width: 375px; 
        }
        &--rating {
            @include fromDesktop {
                
            }
        }
        &:nth-child(2){
            @include fromDesktop {
                padding-left: 25px;
            }
        }
    }
    &__wrapperButton {
        padding-bottom: 25px;
        background-color: $colorWhite;
        @include fromDesktop {
            padding-bottom: 30px;
        }
    }
    &__separator {
        position: absolute;
        top: 12.5%;
        right: 0;
        width: 2px;
        height: 75%;
        border: 1px solid $colorButtonGrey; 
        @include fromDesktop {
            top: 0;
            height: 100%;
        }
    }
    &__ratingSum {
        margin-right: 10px;
    }
    .rating {
        // font-size: 60px;
        // line-height: 40px;
        @include font-primary(rating);
    }
    &__rating {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .a-02600 {
            width: 126px;
            @include fromDesktop {
                width: 130px;
                .star {
                    & ~ .star {
                        margin-left: 8px;
                    }
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
            a {
                position: relative;
                top: 10px;
            }
        }
    }
    &__google {
        display: block;
        width: 126px;
        margin-top: 10px;
        @include font-primary(copy, $colorDarkGrey);
        @include fromDesktop {
            width: 130px;
        }
    }
    &__hours, &__google {
        // color: #3c3c3c;
        // font-size: 14px;
        // font-weight: 400;
        // font-family: $font-primary-regular;
        @include font-primary(copy, $colorDarkGrey,);
        line-height: 19px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        br {
            display: none;
        }
        @include fromDesktop {
            height: 50px;
        }
    }
    // &__col--big {
    //     @include fromDesktop {
    //         .m-0090__hours {
    //             font-size: 14px;
    //         }
    //     }
    // }
    &__hours {
        @include fromDesktop {
            padding-left: 35px;
        }
        strong {
            @include font-primary(h2);
        }
        &.noGoogleReviews {
            text-align: center;
            @include fromDesktop {
                padding-left: 0;
            }
        }
        &--toMonday {
            @include fromDesktop {
                padding-left: 0;
            }
        }
    }
    
    &__buttonWrapper {
        display: flex;
        margin-top: 25px;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        .a-00800 {
            max-width: 100%;
        }
        @include fromDesktop {
            margin-top: 30px;
        }
        .a-00800 {
            max-width: 286px;
        }
        &--big {
            @include fromDesktop {
                .a-00800 {
                    max-width: 480px;
                }
            }
        }
    }
    
    .rating {

        &-wrapper {
            padding: 15px;
            background-color: $colorWhite;
            margin-top: 10px;
        }
        .js-openingState {
            
        }
        
        &-separator {
            position: absolute;
            top: 12.5%;
            right: 0;
            width: 2px;
            height: 75%;
            border: 1px solid $colorButtonGrey;
        }
        &-a-02600 {
            position: relative;
            height: 24px;
        }

        &-hours, &-google {
            color: #3c3c3c;
            font-size: 14px
        }

        &-hours {
            font-weight: bold;
        }
    }


}