.m-0170 {
    &__detaillist {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: -6px;
        @include fromDesktop {
            margin-top: -3px;
        }
        px-a03300 {
            flex-basis: 50%;
            margin-top: 21px;
            @include fromTablet {
                margin-top: 29px;
                flex-basis: 33%;
                max-width: 33%;
                .a-03300__wrapper {
                    word-break: break-word;
                }
            }
            @include fromDesktop {
                
            }
        }
        .a-03300 {
            flex-basis: 50%;
            @include fromTablet {
                flex-basis: 33%;
            }
        }
    }
}