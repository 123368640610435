.a-01100 {
    .text {
        text-transform: uppercase;
        text-align: center;
        margin-top: 4px;
        @include font-primary(cta);
        color: $colorBlack;
    }
    .icon {
        display: inline-block;
        margin-left: 5px;
        color: black;
        position: relative;
        svg {
            width: 8px;
            height: 12px;
            margin-bottom: 2px;
        }
    }
    &:hover {
        .text {
            color: $colorDarkGrey;
        }
        .icon {
            animation-name: move;
            animation-duration: .2s;
            svg {
                @include fillSVG($colorDarkGrey);
            }
        }
    }
    &:focus {
        .text {
            color: $colorRed;
        }
        .icon {
            svg {
                @include fillSVG($colorRed);
            }
        }
    }
    &.disabled {
        .text {
            color: $colorGrey;
        }
        .icon {
            svg {
                @include fillSVG($colorGrey);
            }
        }
    }
}

@keyframes move {
    0%      {  left: 0 }
    40%     {  left: 5px }
    100%    {  left: 0 }
}