.m-0080 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    z-index: 1051;
    @include fromDesktop {
        width: auto;
        z-index: 1;
    }
    > a:last-child {
        @include fromDesktop {
            padding-right: 0;
        }
    }
}
