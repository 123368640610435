.m-0010 {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 72px;
    justify-content: center;
    z-index: 100;
    @include fromDesktop {
        padding: 10px 27px;
    }
    @include fromDesktop {
        height: 100px;
        padding: 20px 0;
    }
    .a-00100 {
        height: 100%;
    }
    .seperator {
        display: block;
        height: 100%;
        width: 2px;
        background-color: $colorBorderGray;
        margin-left: 27px;
        margin-right: 25px;
    }
}