// Viewport Mixins
// $phone-width: 576px;
// $tablet-width: 768px;
// $desktop-width: 992px;
// $wide-width: 1244px;
// $extra-wide-width: 1920px;

@mixin fromPhone {
    @media (min-width: #{$phone-width}) {
        @content;
    }
}
@mixin fromPhonePlus {
    @media (min-width: #{$phone-width + 1}) {
        @content;
    }
}

@mixin fromTablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin fromDesktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin fromWide {
    @media (min-width: #{$wide-width}) {
        @content;
    }
}
@mixin fromExtraWide {
    @media (min-width: #{$extra-wide-width}) {
        @content;
    }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin extendedTablet {
  @media (min-width: #{$phone-width + 1}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin toPhone {
    @media (max-width: #{$phone-width - 1px}) {
        @content;
    }
}
@mixin toPhonePlus {
    @media (max-width: #{$phone-width}) {
        @content;
    }
}

@mixin toTablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin toDesktop {
    @media (max-width: $desktop-width) {
        @content;
    }
}

@mixin toWide {
    @media (max-width: #{$wide-width - 1px}) {
        @content;
    }
}

@mixin toExtraWide {
    @media (max-width: #{$extra-wide-width - 1px}) {
        @content;
    }
}


@mixin fillSVG($color) {
    g, path, polygon, rect {
        fill: $color !important;
    }
}

@mixin fullWidth {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin reset-fullWidth {
    width: auto;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
}

@mixin column-grid {
    padding-left: $column-padding-mobile;
    padding-right: $column-padding-mobile;
    @include fromTablet {
        padding-left: $column-padding-tablet;
        padding-right: $column-padding-tablet;
    }
    @include fromDesktop {
        padding-left: $column-padding-desktop;
        padding-right: $column-padding-desktop;
    }
}

@mixin row-grid {
    margin-left: $row-margin-mobile;
    margin-right: $row-margin-mobile;
    @include fromTablet {
        margin-left: $row-margin-tablet;
        margin-right: $row-margin-tablet;
    }
    @include fromDesktop {
        margin-left: $row-margin-desktop;
        margin-right: $row-margin-desktop;
    }
}

//Font Mixins

// Lato  ||| REPLACED WITH NEW MMC FONT !!!!!!!!
@mixin font-lato-regular($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-primary-regular;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}
@mixin font-lato-bold($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-primary-bold;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

// Alpha ||| REPLACED WITH NEW MMC FONT !!!!!!!!
@mixin font-alpha-regular($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-primary-regular;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}
@mixin font-alpha-bold($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-primary-bold;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

/// primary
@mixin font-primary-regular($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-primary-regular;
    @if $size == hero {
        font-size: 20px; 
        line-height: 28px;
        @include fromDesktop {
            font-size: 40px;
        }
    } @else if $size == h1 {
        font-size: 18px; 
        line-height: 22px;
        @include fromDesktop {
            font-size: 28px;
            line-height: 38px;
        }
    } @else if $size == h2 {
        font-family: $font-primary-bold;
        font-size: 15px; 
        line-height: 29px;
        @include fromDesktop {
            font-size: 33px;
        }
    } @else if $size == h3 {
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 20px;
        }
    } @else if $size == h4 {
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 18px;
        }
    } @else if $size == copy {
        font-size: 15px; 
        line-height: 21px;
        @include fromDesktop {
            font-size: 18px;
        }
    } @else {
        @if $size { font-size: $size; }
    }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

@mixin font-primary-bold($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-primary-bold;
    @if $size == hero {
        font-size: 20px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 40px;
        }
    } @else if $size == h1 {
        font-size: 18px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 28px;
            line-height: 38px;
        }
    } @else if $size == h2 {
        font-size: 15px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 33px;
        }
    } @else if $size == h3 {
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 20px;
        }
    } @else if $size == h4 {
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 18px;
        }
    } @else if $size == copy {
        font-size: 15px; 
        line-height: 21px;
        @include fromDesktop {
            font-size: 18px;
        }
    } @else {
        @if $size { font-size: $size; }
    }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

@mixin font-primary($size: false, $colour: false, $weight: false,  $lh: false) {
    @if $size == hero {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 20px; 
        line-height: 28px;
        color: $colorBlack;
        @include fromDesktop {
            font-size: 30px;
            line-height: 40px;
        }
    } @else if $size == h1 {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 18px; 
        line-height: 22px;
        color: $colorBlack;
        @include fromDesktop {
            font-size: 28px;
            line-height: 38px;
        }
    } @else if $size == h2 {
        font-family: $font-primary-bold;
        font-weight: 400;
        font-size: 15px; 
        line-height: 29px;
        color: $colorBlack;
        @include fromDesktop {
            font-size: 20px;
        }
    } @else if $size == h3 {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 20px;
        }
    } @else if $size == h4 {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 18px;
        }
    } @else if $size == h4-bold {
        font-family: $font-primary-bold;
        font-weight: 400;
        font-size: 14px; 
        line-height: normal;
        @include fromDesktop {
            font-size: 18px;
        }
    } @else if $size == copy {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 15px; 
        line-height: 21px;
        color: $colorBlack;
        word-wrap: break-word;
        hyphens: auto;
        @include fromDesktop {
            font-size: 16px;
        }
    } @else if $size == cta {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 12px; 
        line-height: 16px;
        text-transform: uppercase;
        @include fromDesktop {
            font-size: 14px;
        }
    } @else if $size == ctasmall {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 11px; 
        line-height: 16px;
        text-transform: uppercase;
        @include fromDesktop {
            font-size: 12px;
        }
    } @else if $size == price {
        font-family: $font-primary-bold;
        font-weight: 400;
        font-size: 14px; 
        @include fromDesktop {
            font-size: 24px;
        }
    } @else if $size == top-offer-price {
        font-family: $font-primary-bold;
        font-weight: 400;
        font-size: 18px; 
        color: $colorBlack;
        @include fromDesktop {
            font-size: 24px;
        }
    } @else if $size == copy-bold {
        font-family: $font-primary-bold;
        font-weight: 400;
        font-size: 15px; 
        line-height: 21px;
        @include fromDesktop {
            font-size: 16px;
        }
    } @else if $size == tag {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 12px; 
        line-height: 18px;
        color: $colorDarkGrey;
        @include fromDesktop {
            font-size: 14px;
        }
    } @else if $size == note {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 11px; 
        line-height: 18px;
        color: $colorDarkGrey;
        @include fromDesktop {
            font-size: 12px;
        }
    } @else if $size == rating {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 60px;
        line-height: 45px;
        color: $colorRed;
    } 
    @else if $size == subHeadline {
        font-family: $font-primary-regular;
        font-weight: 400;
        font-size: 30px; 
        text-transform: uppercase;
        @include fromDesktop {
        }
    } @else if $size == ctaHotline {
        font-family: $font-primary-bold;
        font-weight: 400;
        font-size: 12px; 
        line-height: 15px;
        @include fromDesktop {
            font-size: 16px;
            line-height: 21px;
        }
    }
    @else {
        @if $size { font-size: $size; }
    }
    @if $colour { color: $colour; }
    @if $weight == normal {
        font-family: $font-primary-regular;
        font-weight: 400;
    } @else if $weight == bold {
        font-family: $font-primary-bold;
        font-weight: 400;
    } @else {
        @if $weight { font-weight: $weight; }
    }
    @if $lh { line-height: $lh; }
}
