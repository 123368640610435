.enkv-box {
    position: relative;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    @include toPhonePlus {
        margin-top: -1px;
    }

    &__inner {
        position: relative;
        width: 100%;
        // Max height must be defined so that transition works
        max-height: 400px;
        overflow: hidden;
        transition: max-height 0.1s ease-in-out;

        &.closed {
            max-height: 0;

            +.enkv-box__arrow {
                transform: rotate(180deg);

                &--black {

                    svg,
                    svg path {
                        fill: $colorBlack !important;
                    }
                }
            }
        }
    }

    &__content {
        padding: 18px;
        @include fromPhonePlus {
            padding: 9px 48px 9px 20px;
        } 
        @include fromDesktop {
            padding: 13px 80px 13px 30px;
        }
        white-space: normal;

        &--bg {
            &-transpartent {
                background-color: #202020;
                @include fromPhonePlus {
                    background-color: rgba(28, 28, 28, 0.6);
                } 
            }
        }

        p {
            color: $colorWhite;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0;

            @include fromTablet {
                font-size: 16px;
                line-height: 21px;
            }
            strong, b {
                font-family: $font-primary-bold;
                font-weight: normal;
            }
        }
    }

    &__arrow {
        position: absolute;
        display: flex;
        height: 100%;
        min-height: 40px;
        width: 40px;
        bottom: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        transition: transform 0.5s ease-in-out;
        cursor: pointer;

        @include toPhonePlus {
            display: none;
        }

        &:hover {
            opacity: 0.4;
        }

        svg {
            width: 16px;
            height: 16px;
            transition: fill 0.5s ease-in-out;

            @include fromTablet {
                width: 22px;
                height: 22px;
            }

            path {
                transition: fill 0.5s ease-in-out
            }
        }
    }
}