.m-0020 {
    position: relative;
    width: 100%;
    @include toDesktop {
        overflow: hidden;
        max-width: 100%;
    }
        .a-00300 {
            position: absolute;
            left: 0;
            bottom: 0;
            padding-left: 15px;
            padding-right: 27px;
            padding-bottom: 27px;
            @include fromDesktop {
                padding-left: 0;
            }
        }
        .wrapper {
            display: block;
            position: relative;
            @include fromTablet {
                width: 100%;
                max-width: 720px;
                margin-left: auto;
                margin-right: auto;
            }
            @include fromDesktop {
                max-width: $container-width-lg;
            }
            @include fromWide {
                max-width: $container-width-xl;
            }
            
        }
    &--b {
        .wrapper {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 2;
        }
        .arrow {
            color: white;
            float: right;
            padding-right: 27px;
            padding-bottom: 23px;
            font-weight: bold;
        }
    }
    &--a, &--b {
        .icon {
            svg {
                
            }
        }
        .a-00500 {
            text-align: center;
            /*
            @include toDesktop {
                position: relative;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
            @include toTablet {
                left: 25%;
                height: 310px;
            }
            img.img-fluid {
                display: inline-block;
                @include toDesktop {
                    width: auto;
                }
                @include toTablet {
                    max-width: unset;
                    height: 310px;
                }
            }
            */
        }
    }
    &--c {
        .a-00500 {
            text-align: center;
          //  left: 50%;
            overflow: hidden;

            @include toTablet {
                height: 180px;
            }
            @include toDesktop {
                position: relative;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }

            img.img-fluid {
                display: inline-block;
                @include toTablet{
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    transform: translateX(-50%);
                    margin-left: calc(50%);
                    max-width: none;
                    height: 180px;
                    object-fit: cover;
                }
                @include fromTablet{
                    height: auto;
                    max-width: 100%;
                }
                @include fromExtraWide{
                    object-fit: cover;
                    width: 100%;
                    max-height: 600px;
                }
            }
        }
    }
    &--d {
        .a-00500 {
            text-align: center;

            @include toDesktop {
                position: relative;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
            @include toTablet {
                left: 25%;
                height: 104px;
            }
            img.img-fluid {
                display: inline-block;
                @include toDesktop {
                   
                }
                @include toTablet {
                    max-width: unset;
                    height: 104px;
                }

                @include fromExtraWide{
                    object-fit: cover;
                    width: 100%;
                    max-height: 300px;
                }
            }
        }
    }
}

