.m-0060 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $colorWhite;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.12);
    transition: opacity 0.4s, transform 0.4s;
    height: 100%;
    @include fromDesktop {
        max-width: 380px;
    }
    & > a {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    a {
        &:focus {
            box-shadow: 0 0 0 0 transparent;
            border-color: transparent;
            outline-color: transparent;
        }
    }
    &:hover {
        @include fromDesktop {
            animation-name: m-0060scale;
            animation-duration: 0.4s;
            transform: scale(1.04, 1.04);   
        }
    }
    &.active {
        transition: opacity 0.7s, transform 0.8s;
        opacity: 0;
        transform: scale(1.4, 1.4);
        // transition-delay wird über js gesteuert (020.ts)
    }

    &__tirelabel {
        font-size: 12px;
        color: #000 !important;
        display: block;
        margin-top: 10px;
        svg {
            width: 11px;
            height: 10px;
            margin-left: 4px;
            top: -1px;
            position: relative;
        }
    }

    &__image-wrapper {
        position: relative;
        .a-00600 {
            z-index: 0;
        }
    }
    &__image-description-wrapper {
        position: absolute;
        left: 100%;
        margin-left: -20px;
        bottom: 2px;
        width: 50%;
    }
    &__image-description {
        display: block;
        transform: rotate(270deg);
        transform-origin:0 0;
        @include font-primary(8px, $colorDarkGrey, normal, 11px);
    }
    &__arrow {
        position: absolute;
        bottom: 18px;
        right: 12px;
        width: 16px;
        height: 10px;
        z-index: 5;
        svg {
            bottom: 16px;
            position: relative;
        }
        @include toDesktop {
            z-index: 0;
        }
    }
    &__content {
        justify-self: flex-end;
        align-self: flex-end;
        padding: 10px 15px 12px;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul{
            list-style-position: inside;
        }
    }
    &__model {
        margin: 0;
        @include font-primary(h1);
    }
    &__title {
        margin: 2px 0 0;
        @include font-primary(copy);
        color: $colorBlack;
    }
    &__tags {
        .a-02800 {
            display: inline-block;
        }
    }
    &__envkv {
        margin-top: 11px;
        @include fromDesktop {
            margin-top: 17px;
        }
        span {
            @include font-primary(copy);
            color: $colorDarkGrey;
            // @include font-lato-regular(14px, $colorDarkGrey, normal, 20px);
            // @include fromDesktop {
            //     font-size: 16px;
            // }
            strong, b{
                font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
                font-weight: normal;
            }
        }
    }
    &__envkvItem {
        @include font-primary(copy);
        color: $colorDarkGrey;
        &:first-child {
            margin-right: 10px;
        }
    }
    .a-03000 {
        margin-top: 15px;
        border-width: 1px;
    }
    &__price {
        text-align: right;
        margin-top: 5px;
        .h1alternative {
            @include font-primary(price);
        }
    }
    .a-00400 {
        position: absolute;
        top: 0;
        right: 20px;
    }
    &--c, &--d {
        .a-00400 {
            position: absolute;
            right: 15px;
            top: 0;
        }
        .m-0060__price {
            .h1alternative {
                color: $colorRed !important;
            }
        }
    }
    &--f {
        .gradient {
            height: 50%;
        }
        .editButtonWrapper{
            position: absolute;
            z-index: 1;
        }
        .m-0060__model {
            margin-bottom: 5px;
            @include fromDesktop {
                margin-bottom: 10px;
            }
        }
        .m-0060__TextContainer{
            max-height: 150px;
            overflow: hidden;
        }

        .CropActive{
            position: relative;
            .overlay{
                position: absolute;
                height: 45px;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.55) , #ffffff);
                width: 100%;
                bottom: 0;
                opacity: 0.4;
            }
        }
    }
    &--no-car-fallback {
        & > a {
            height: auto;
            display: block;
        }
        .m-0060__content {
            flex-grow: 0;
        }
    }
}

@keyframes m-0060scale {
    0%      {  transform: scale(1, 1); }
    50%     {  transform: scale(1.06, 1.06); }
    100%    {  transform: scale(1.04, 1.04); }
}
// @keyframes m-0060scale {
//     0%      { opacity: 1; transform: scale(1, 1); }
//     100%    { opacity: 0; transform: scale(1.4, 1.4); }
// }

.m-0065 {
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    &:hover {
        @include fromDesktop {
            animation-name: m-0060scale;
            animation-duration: 0.4s;
            transform: scale(1.04, 1.04);   
        }
    }
    &__image-wrapper {
        position: relative;
        .a-00600 {
            z-index: 0;
            .gradient {
                background: transparent !important;
            }
        }
    }
    &__badge {
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 89px;
        width: 89px;
        top: 0;
        right: 0;
        background-color: $colorBlack;
        color: $colorWhite;
        border-radius: 50%;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        background-color: #E4E4E5;
        padding: 5px 15px 15px;
        @include fromDesktop {
            padding: 15px; 
            margin-top: -1px;
        }
    }
    &__produktNr {
        font-size: 10px;
        line-height: 21px;
        margin-bottom: -2px;
        @include fromDesktop {
            line-height: 12px;
        }
    }
    &__headline {
        font-weight: normal;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 21px;
        @include fromDesktop {
            font-size: 20px;
            line-height: 25px;
        }
    }
    &__list {
        ul {
            padding-left: 20px;
            font-size: 10px;
            line-height: 20px;
            margin-bottom: 15px;
            @include fromDesktop {
                margin-bottom: 25px;
            }
            li {
                margin-bottom: 10px;
                span {
                    font-size: 13px;
                    line-height: 20px;
                    vertical-align: top;
                    @include fromDesktop {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    &__price {
        font-size: 20px;
        line-height: 21px;
    }
    .a-00700--c {
        margin-top: 15px;
        @include fromDesktop {
            margin-top: 25px;
        }
    }
}