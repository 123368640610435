.m-0040 {
    @include fromDesktop {
        margin-bottom: 40px;
    }
    &__carousel-wrapper {
        position: relative;
        @include fullWidth;
        @include fromDesktop {
            @include reset-fullWidth;
        }
    }
    &__carousel-outer {
        width: 100%;
        overflow: hidden;
    }
    &__carousel-inner {
        width: auto;
        display: flex;
        flex-wrap: nowrap;
        touch-action: pan-y !important;
        transition: transform .4s ease-in-out;
        @include fromDesktop {
            touch-action: auto !important;
        }
    }
    &__carousel-slide {
        min-width: 100%;
        display: inline-block;
        position: relative;
        background-color: $colorGrey;
        img {
            max-width: none;
            width: 100%;
        }
    }
    &__image-description-wrapper {
        position: absolute;
        left: 100%;
        margin-left: -20px;
        bottom: 2px;
        width: 50%;
        @include fromTablet {
            margin-left: -37px;
            bottom: 10px;
        }
    }
    &__image-description {
        display: block;
        transform: rotate(270deg);
        transform-origin:0 0;
        @include font-primary(8px, $colorDarkGrey, normal, 11px);
        @include fromTablet {
            @include font-primary(10px, $colorDarkGrey, normal, 14px);
        }
    }
    &__gallery-line {
        background-color: $colorWhite;
        position: absolute;
        z-index: 3;
        &--vertical {
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
        }
        &--horizontal {
            width: 100%;
            height: 1px;
            top: 50%;
            left: 0;
        }
    }
    .a-00400 {
        position: absolute;
        right: 12px;
        top: 0;
        z-index: 1;
        @include fromDesktop {
            width: 40px;
            height: 40px;
            right: 27px;
            svg {
                max-width: 19px;
                max-height: 19px;
            }
        }
    }
    .gallery-icon {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 16px;
        cursor: pointer;
        p {
            color: white;
        }
        .icon-row {
            display: flex;
            justify-content: center;
            span {
                width: 8px;
                height: 8px;
                background-color: white;
                margin-bottom: 3px;
                &:last-child {
                    margin-left: 3px;
                }
            }
        }
    }

    .carousel-indicators {
        z-index: 3;
        @include fromDesktop {
            bottom: -40px;
        }
        li {
            border-radius: 50%;
            height: 6px;
            width: 6px;
            opacity: 1;
            border: 0;
            box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.41);
            @include fromDesktop {
                height: 7px;
                width: 7px;
                background-color: $colorGrey;
                margin-right: 5px;
                margin-left: 5px;
            }
            &.active {
                background-color: $colorRed;
                height: 8px;
                width: 8px;
                @include fromDesktop {
                    height: 9px;
                    width: 9px;
                }
            }
        }
    }
    .carousel-control {
        display: none;
        @include fromDesktop {
            display: flex;
            width: 20px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        &-prev {
            left: -30px;
        }
        &-next {
            right: -30px;
        }
        svg {
            width: 20px;
        }
    }
    
    .galleryItem {
        display: block;
        float: left;
        max-width: 50%;
        //background-color: $colorBlack;
        overflow: hidden;
        // box-sizing: border-box;
        // @include fromDesktop {
        //     height: 208px;
        // }
        // @include fromDesktop {
        //     height: 208px;
        // }
        .galleryPlaceholder{
            img {
                opacity: 0;
            }
        }
    }

    .swiper-button {
        display: none;
        background-size: 20px;
        @include fromDesktop {
            display: block;
        }
        &-prev {
            left: -30px;
            background-image: url(../Icons/relaunch/icon_arrow_schwarz_left.svg)
        }
        &-next {
            right: -30px;
            background-image: url(../Icons/relaunch/icon_arrow_schwarz_right.svg)
        }
        svg {
            width: 20px;
        }
    }
    .swiper-pagination {
        left: 90px;
        right: 90px;
        bottom: 20px;
        @include fromDesktop {
            bottom: 10px;
        }
        span {
            border-radius: 50%;
            height: 6px;
            width: 6px;
            background-color: $colorWhite;
            opacity: 1;
            border: 0;
            box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.41);
            margin-right: 5px;
            margin-left: 5px;
            @include fromDesktop {
                height: 7px;
                width: 7px;
                background-color: $colorGrey;
                margin-right: 5px;
                margin-left: 5px;
            }
            &.swiper-pagination-bullet-active {
                background-color: $colorRed;
                height: 8px;
                width: 8px;
                @include fromDesktop {
                    height: 9px;
                    width: 9px;
                }
            }
        }
    }
    &--centeredImages {
        .m-0040 {
            &__carousel-slide {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                max-height: 387px;
                img {
                    max-height: 100%;
                    object-fit: scale-down;
                }
            }
            &__carousel-wrapper {
                @include toDesktop {
                    padding-top: 56.25%;
                }
            }
            &__carousel-outer {
                @include toDesktop {
                    position:absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &__carousel-inner {
                @include toDesktop {
                    max-width: 100%;
                    max-height: 100%;
                }
                .a-00600, px-a00600, px-lazy-image {
                    @include toDesktop {
                        display: block;
                        max-width: 100%;
                        height: 100%;
                    }
                }
                .a-00600 {
                    position: initial;
                    .gradient {
                        left: 0;
                    }
                }
                .a-00600 px-lazy-image {
                    @include toDesktop {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.m-0041 {
    .gallery-icon {
        bottom: 15px;
        left: auto;
        right: 15px;
        svg {
            width: 24px;
            height: 24px;
            fill: #BFBFBF !important;
            polygon, path {
                fill: #BFBFBF !important;
            }
        }
    }
}

.px-m-0041-modal-overlay {
    .modal-dialog {
        @include toDesktop {
            margin: 75px 0 0;
        }
        @include fromPhone {
            max-width: 991px;
        }
        @include fromDesktop {
            margin-top: 220px;
            max-width: 914px;
        }
    }
    .modal-header {
        border-bottom: 0 solid transparent;
        .close {
            padding: 15px;
            line-height: 24px;
            font-size: 48px;
            opacity: 1;
            span {
                position: relative;
                top: -5px;
            }
        }
    }
    .modal-body {
        padding: 0 54px;
    }
    
    .carousel-control {
        opacity: 1;
        @include toDesktop {
            display: flex;
            width: 20px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        &-prev {
            left: -39px;
            @include toDesktop {
                left: 15px;
            }
        }
        &-next {
            right: -39px;
            @include toDesktop {
                right: 15px;
            }
        }
        svg {
            width: 24px;
            height: auto;
        }
    }
    .m-0040 {
        &__carousel-slide {
            background-color: transparent;
        }
        &__carousel-inner {
            .a-00600 {
                .gradient {
                    display: none;
                }
            }
        }
        &--centeredImages {
            .m-0040__carousel-wrapper {
                padding-top: 0;
            }
            .m-0040__carousel-outer {
                position: relative;
            }
        }
        .carousel-indicators {
            position: relative;
            margin-top: 15px;
            @include fromDesktop {
                bottom: 0;
            }
        }
    }
}
