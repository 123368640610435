.m-0300 {
    position: fixed;
    top: 50%;
    right: 0;
    padding: 10px 0 10px 13px;
    margin-top: -43px;
    // transform: translateY(-50%);
    background-color: $colorRed;
    z-index: 2;
    @include toDesktop {
        position: absolute;
        top: 0;
        padding: 5px 0 5px 13px;
        // transform: translateY(0%);
        margin-top: 0;
    }
    @media (max-width: 340px) {
        padding: 5px 0 5px 5px;
    }
    &--b {
        // transform: translateY(55%);
        margin-top: 49px;
        @include toDesktop {
            // transform: translateY(105%);
            margin-top: 92px;
        }
    }
    .wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        transition: width 2s;
    }
    .headerIcon {
        width: 60px;
        height: 60px;
        margin-right: 13px;
        cursor: pointer;
        @include toDesktop {
            width: 45px;
            height: 45px;
            margin-right: 13px;
        }
        @media (max-width: 340px) {
            width: 30px;
            margin-right: 5px;
        }
        svg {
            width: 100%;
            height: 100%;
            @include fillSVG($colorWhite)
        }
    }
    .linkWrapper {
        display: flex;
        width: 195px;
        height: 67px;
        flex-direction: column;
        margin-right: 10px;
        margin-left: 5px;
        transition: width 2s;
        overflow: hidden;
        @include toDesktop {
            width: 160px;
            height: 57px;
            margin-top: 5px;
            margin-right: 20px;
            margin-left: 0;
        }
        @media (max-width: 340px) {
            margin-left: 10px;
            margin-right: 5px;
        }
        &.hidden {
            margin-right: 0;
            margin-left: 0;
            width: 0;
            // display: none;
        }
    }
    .link {
        display: block;

    }
    .headline {
        display: block;
        width: 195px;
        margin-bottom: 3px;
        background-color: transparent;
        // @include font-primary(copy, $colorWhite, bold);
        @include font-primary(ctaHotline, $colorWhite);
        @include toDesktop {
            margin-bottom: 0;
        }
    }
    .a-01100 {
        display: block;
        width: 190px;

        .text {
            @include font-primary(ctasmall, $colorWhite);
            text-transform: none;
        }
        .icon {
            // color: $colorWhite;
            // svg {
            //     @include fillSVG($colorWhite)
            // }
            display: none;
        }
    } 
    &.hide {
        display: none;
    }
}