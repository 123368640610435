.margin {
    &--none {
        margin: 0;
    }
    &__top {
        &--small {
            margin-top: 20px;
        }
        &--medium {
            margin-top: 20px;
            @include fromDesktop {
                margin-top: 55px;
            }
        }
        &--large {
            margin-top: 30px;
            @include fromDesktop {
                margin-top: 70px;
            }
        }
    }
    &__bottom{
        &--small {
            margin-bottom: 20px;
        }
        &--medium {
            margin-bottom: 20px;
            @include fromDesktop {
                margin-bottom: 55px;
            }
        }
        &--large {
            margin-bottom: 30px;
            @include fromDesktop {
                margin-bottom: 70px;
            }
        }
    }
}

.flex{
    &__direcRow{
        position: relative;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        &--justStart{
            justify-content: flex-start;
        }
        &--justEnd{
            justify-content: flex-end;
        }
        &--justBetw{
            justify-content: space-between;
        }
        &--alignEnd{
            align-items: flex-end;
        }
    }
    &__direcCol{
        position: relative;
        display:flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        &--justStart{
            justify-content: flex-start;
        }
        &--justEnd{
            justify-content: flex-end;
        }
        &--justBetw{
            justify-content: space-between;
        }
        &--justCen{
            justify-content: center;
        }
        &--alignEnd{
            align-items: flex-end;
        }
    }
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.background {
    background-color: transparent;
    &--grey {
        background-color: $colorLightGrey;
    }
    &--dark-grey {
        background-color: $colorDarkGrey;
    }
    &--white {
        background-color: $colorWhite;
    }
    &--black {
        background-color: $colorBlack;
    }
}

.white {
    color: white;
}

.col-narrow {
    @include fromDesktop {
        padding-left: 45px;
        padding-right: 45px;
    }
}

.no-break {
    white-space: nowrap;
}

.uppercase{
    text-transform: uppercase;
}

.strong{
    @include font-lato-bold();
}

.campaign-buttons {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-bottom: 3px;
        &:nth-child(2) {
            background-color: $colorRed;
            margin-bottom: 40px;
            @include fromDesktop {
                margin-left: 15px;
            }
        }
    }
}