.m-0160 {
    padding-bottom: 20px;
    @include fromDesktop {
        padding-bottom: 34px;
    }
    &__bulletlist {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 12px;
        @include fromDesktop {
            margin-top: 5px;
        }
        px-a02700 {
            flex-basis: 50%;
            &:nth-child(even) {
                text-align: right;
            }
            @include fromTablet {
                flex-basis: 33%;
                &:nth-child(even) {
                    text-align: inherit;
                }
                &:nth-child(3n+2) {
                    text-align: center;
                }
                &:nth-child(3n+3) {
                    text-align: right;
                }
            }
            @include fromDesktop {
                margin-top: 12px;
            }
        }
        .a-02700 {
            flex-basis: 50%;
            @include fromTablet {
                flex-basis: 33%;
            }
        }
        &--campaign {
            px-a02700 {
                flex-basis: 100%;
                @include fromTablet {
                    flex-basis: 100%;
                }
                @include fromDesktop {
                    flex-basis: 33%;
                }
            }
        }
    }
}