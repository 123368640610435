.m-0110 {
    position: relative;
    height: auto;
    // max-height: 193px;
    background-color: $colorWhite;
    padding: 15px;
    transition: height 1s;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
    margin: 0 6px 10px;
    @include fromDesktop {
        padding: 20px 25px 20px;
        // max-height: 272px;
        font-size: 16px;
    }
    &:focus, &:active {
        border: 0 solid transparent !important; 
    }
    .author, .relativTime {
        // font-size: 14px;
        // line-height: 18px;
        @include font-primary(copy);
    }
    .relativTime {
        color: $colorDarkGrey;
        margin-bottom: 2px;
        @include fromDesktop {
            margin-bottom: 10px;
            line-height: 22px;
        }
    }
    .a-02600 {
        margin-top: 4px;
        margin-bottom: 8px;
        @include fromDesktop {
            position: absolute;
            right: 25px;
            top: 20px;
            margin-top: 0;
        }
    }
    p {
        margin: 0;
        @include font-primary(copy);
        // @include fromDesktop {
        //     font-size: 16px;
        //     line-height: 26px;
        // }
    }
    .moreBtn {
        // position: absolute;
        // right: 15px;
        // bottom: 15px;
        cursor: pointer;
        // color: $colorDarkGrey;
        @include font-primary(cta);
        color: $colorBlack;
        // font-weight: bold;
        // font-size: 12px;
        // line-height: 16px;
        // @include fromDesktop {
            // right: 25px;
            // bottom: 20px;
            // font-size: 14px;
            // line-height: 24px;
        // }
    }
}