.a-01700 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 85px;
    .icon {
        width: 40px;
        height: 32px;
        background-color: $colorRed;
        display: block;
    }
    .text {
        color: black;
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 15px;
        text-align: center;
    }
    &:hover, &:focus {
        outline: 0;
    }
    &:hover {
        .icon {
            background-color: $colorDarkGrey;
        }
        .text {
            color: $colorDarkGrey;
        }
    }
    &:focus {
        .icon {
            background-color: $colorRed;
        }
        .text {
            color: black;
        }
    }
    &.disabled {
        cursor: default;
        .icon {
            background-color: $colorGrey;
        }
        .text {
            color: $colorGrey;
        }
    }
}