.a-02900 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    span {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: white;
        border: 2px solid transparent;
        display: block;
        margin-left: 7px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        transition: all .3s ease-in-out;
        &.active, &.slick-active {
            background-color: $colorRed;
            border: 2px solid $colorRed;
        }
    }
}
ul.slick-dots li {
    width: 6px;
    height: 6px;
    margin-left: 7px;
    transition: all .3s ease-in-out;
    button{
        width: 6px;
        height: 6px;
        padding: 0;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        border-radius: 50%;
        background-color: white;
        border: 2px solid transparent;
        &:before {
            content: '';
        }
    }
    &.slick-active {
        button{
            background-color: $colorRed;
            border: 2px solid $colorRed;
        }
    }
}