.c-0018 {
    width: calc(100% + 3px);
    display: flex;
    flex-direction: column;
    @include fromTablet {
        flex-direction: row;
    }

    .image-primitive {
        width: 100%;
        margin-top: -2px;
        margin-bottom: -2px;
        margin-right: -1px;
        margin-left: -1px;
        
        img {
            width: 100%;
        }
        @include fromTablet {
            width: 50%;
        }
    }
}