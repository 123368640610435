.m-0130 {
    &-seperator {
        border-right: 1px solid #ccc;
    }

    &-segment {
        padding: 10px 0px;
        
        p {
            font-family: 'Open Sans',sans-serif;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }

        &-wrapper {
            position: relative;
        }
    }

    &-icon-wrapper {
        width: 40px;
        height: 40px;
        background-color: red;
        margin-bottom: 10px;
    }

    &-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

    }
}