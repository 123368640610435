.a-00200 {
    padding-left: 17px;
    height: 39px;
    width: 320px;
    background-color: $colorButtonGrey;
    display: flex;
    align-items: center;
    flex-direction: row;
    border: none;
    box-shadow: none;
    &__arrow {
        font-size: 18px;
        color: $colorArrowGrey;
        font-weight: bold;
    }
	&__text {
        font-size: 12px;
        color: black;
        font-weight: bold;
        margin-left: 8px;
    }
}