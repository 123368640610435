.a-03600 {
    flex-grow: 1;
    flex-basis: 100%;
    background-color: $colorNavigationGray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    // transition: background-color $transition-time;
    position: relative;
    padding-top: 9px;
    padding-bottom: 4px;
    @include fromTablet {
        height: 59px;
        padding-top: 13px;
        padding-bottom: 9px;
    }
    @include fromDesktop {
        flex: 0 0 auto;
        background: transparent;
        flex-direction: row;
        height: 60px;
        padding: 0 40px;
        justify-content: center;
        align-items: center;
    }
    &::after {
        content: '';
        width: 1px;
        height: 34px;
        display: block;
        background-color: $colorDarkGrey;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -17px;
        transition: background-color .3s ease-in;
        @include fromDesktop {
            background-color: $colorBlack;
            width: 2px;
            height: 20px;
            margin-top: -10px;
        }
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
    &:focus {
        outline: none;
    }
    &.hideAfter {
        &::after {
            display: none;
        }
    }
    button {
        border: 0;
        padding: 0;
        background: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        @include fromDesktop {
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        &:focus {
            outline: none;
        }
    }
    .text {
        // @include font-lato-bold(10px, $colorBlack, normal, 14px);
        @include font-primary(note, $colorBlack);
        text-transform: uppercase;
        text-align: center;
        @include fromDesktop {
            margin-left: 10px;
            white-space: nowrap;
            color: $colorWhite;
            // font-size: 14px;
            // line-height: 15px;
        }
        &--active {
            display: none;
            color: $colorWhite;
        }
    }

    .icon {
        width: 24px;
        height: 21px;
        margin: 0 auto;
        @include fromDesktop {
            width: 20px;
            height: 18px;
            line-height: 18px;
            margin-right: 10px;
        }
        svg {
            width: 100%;
            height: 100%;
            //background-color: $colorBlack;
            @include fromDesktop {
                //background-color: $colorWhite;
                @include fillSVG($colorWhite);
            }
        }
        &--active {
            display: none;
            svg {
                @include fillSVG($colorWhite);
                polyline {
                    fill: $colorWhite !important;
                }
            }
        }
    }
    &.active {
        background-color: $colorDarkGrey;
        .text {
            display: none;
            &--active {
                display: inline;
            }
        }
        .icon {
            display: none;
            &--active {
                display: block;
            }
        }
        
        .placeholderIcon {
            background-color: $colorWhite;
        }
    }
    &.isDisabled {
        pointer-events: none; 
    }
}


