body {
    min-width: 320px;
    overflow-x: hidden;
    @include font-primary(copy);
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
}

.hero {
    @include font-primary(hero);
    // @include fromDesktop {
    //     font-size: 40px;
    // }
}

h1, .h1, .h1alternative {
    @include font-primary(h1);
    // @include font-primary-regular(h1, $colorBlack, normal, 22px);
    // @include fromDesktop {
    //     font-size: 28px;
    //     line-height: 38px;
    // }
}

h2, .h2 {
    @include font-primary(h2);
    // @include font-primary-bold(15px, $colorBlack, normal, 29px);
    // @include fromDesktop {
    //     font-size: 33px;
    // }
}

h3, .h3 {
    font-size: 14px;
    line-height: normal;
    @include fromDesktop {
        font-size: 20px;
    }
}

h4, .h4 {
    font-size: 14px;
    line-height: normal;
    @include fromDesktop {
        font-size: 18px;
    }
}

h5, .h5 {
    font-size: 14px;
}

h6, .h6 {
    font-size: 14px;
}

//Copy
p {
    word-wrap: break-word;
    hyphens: auto;
    // @include font-primary-regular(copy, $colorBlack, normal, 21px);
    @include font-primary(copy);
    &:empty {
        margin: 0;
        padding: 0;
    }
}

//CTA
.cta {
    // text-transform: uppercase;
    @include font-primary(cta);
    // &--alternative {
    //     font-size: 14px;
    // }
}

.price {
    @extend h2;
    font-size: 14px;
}

//Tag
.tag {
    @include font-primary(tag);
    // @include font-primary-regular(12px, $colorDarkGrey, normal, 18px);
}

//Hinweis
.note {
    @include font-primary(note);
    // @include font-primary-regular(11px, $colorDarkGrey, normal, 18px);
}

.rating {
    @include font-primary(rating);
    // @include font-primary-regular(60px, $colorRed, normal, 60px);
}

strong {
    font-family: $font-primary-bold;
    font-weight: normal;
}

.text {
    &-color {
        &-white {
            color: $colorWhite;
        }

        &-red {
            color: $colorRed;
        }
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

.subHeadline {
    font-size: 30px;
    text-transform: uppercase;

    .subHeadline--black {
        color: $colorBlack;
    }

    .subHeadline--black {
        color: $colorBlack;
    }
}

.image-embed-item {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
    letter-spacing: 0.05px;
    @include font-lato-bold(15px, $colorDarkGrey, normal, 21px);
    &:hover {
        text-decoration: none;
        color: $colorBlack;
    }

    &:visited{
        color: $colorRed;
    }
}

section {
    margin-top: 40px;
    @include fromTablet {
        margin-top: 20px;
    }
}

.background {
    background-color: $colorLightGrey;
}

.btn {
    &:focus,
    &:active,
    &:active:focus {
        outline: 0;
    }
}

.btn-editMode {
    color: $colorWhite;
    background-color: $colorEditButton;
    border-color: $colorEditButton;
}

.contentContainer {
    padding: 30px;
}

.hiddenAnimate {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.row-eq-height {
    display: flex;
    flex-wrap: wrap;
}

.row {
    &::before,
    &::after {
        flex-basis: 0;
        order: 1;
    }
}

.scrollTop {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.fromPhone{
    display: none !important;
    @include fromPhone {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromPhone {
            display: flex !important;
        }
    }
}
.fromPhonePlus{
    display: none !important;
    @include fromPhonePlus {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromPhonePlus {
            display: flex !important;
        }
    }
}
.fromTablet{
    display: none !important;
    @include fromTablet {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromTablet {
            display: flex !important;
        }
    }
}

.toTablet{
    display: none !important;
    @include toTablet {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include toTablet {
            display: flex !important;
        }
    }
}

// Utility
.mobile {
    display: block;
    @include fromDesktop {
        display: none !important;
    }

    &--flex {
        display: flex;
        @include fromDesktop {
            display: none !important;
        }
    }
}

.mobile-only {
    display: block;
    @include fromDesktop {
        display: none !important;
    }

    @include tablet {
        display: none !important;
    }

    &--flex {
        display: flex;
        @include fromDesktop {
            display: none !important;
        }
    }
}

.mobile-to-576 {
    display: block;
    @include fromDesktop {
        display: none !important;
    }
    
    @include tablet {
        display: none !important;
    }
    
    @include fromPhonePlus {
        display: none !important;
    }
    &--flex {
        display: flex;
        @include fromDesktop {
            display: none !important;
        }
    }
}

.desktop {
    display: none !important;
    @include fromDesktop {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromDesktop {
            display: flex !important;
        }
    }
}
.extendedDesktop {
    display: none !important;
    @include fromDesktop {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromDesktop {
            display: flex !important;
        }
    }
}
.wideDesktop {
    display: none !important;
    @include fromWide {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromWide {
            display: flex !important;
        }
    }
}
.extraWide {
    display: none !important;
    @include fromExtraWide {
        display: block !important;
    }

    &--flex {
        display: none !important;
        @include fromExtraWide {
            display: flex !important;
        }
    }
}


.tablet{
    display: none !important;
    @include tablet {
        display: block !important;
    }
    &--flex {
        display: none !important;
        @include tablet {
            display: flex !important;
        }
    }
}
.extendedTablet{
    display: none !important;
    @include extendedTablet {
        display: block !important;
    }
    &--flex {
        display: none !important;
        @include extendedTablet {
            display: flex !important;
        }
    }
}

.icon {
    &--modellbereich_ggk {
        max-width: 26px;
        position: relative;
        top: 2px;
    }
}


// Form elements
.mat-select-value {
    color: $colorDarkGrey !important;
}

.alert {
    font-size: 12px;
    line-height: 17px;
    padding: 0 8px;
    color: $colorDarkGrey;
    margin-bottom: 0;
}
.checkboxgroup {
    .alert {
        position: relative;
        top:-15px;
    }
}

//container staging fix

.mainContent {
    &>.container {
        @include toTablet {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// Typo3 Content Elements

.ce-align-left {
    text-align: left;
}

.ce-align-center {
    text-align: center;
}

.ce-align-right {
    text-align: right;
}

.ce-table td,
.ce-table th {
    vertical-align: top;
}

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext,
.ce-gallery,
.ce-row,
.ce-uploads li,
.ce-uploads div {
    overflow: hidden;
}

.ce-left .ce-gallery,
.ce-column {
    float: left;
}

.ce-center .ce-outer {
    position: relative;
    float: right;
    right: 50%;
}

.ce-center .ce-inner {
    position: relative;
    float: right;
    right: -50%;
}

.ce-right .ce-gallery {
    float: right;
}

.ce-gallery figure {
    display: table;
    margin: 0;
}

.ce-gallery figcaption {
    display: table-caption;
    caption-side: bottom;
}

.ce-gallery img {
    display: block;
}

.ce-gallery iframe {
    border-width: 0;
}

.ce-border img,
.ce-border iframe {
    border: 2px solid #000000;
    padding: 0px;
}

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery {
    margin-bottom: 10px;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 10px;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 10px;
}

.ce-below .ce-gallery {
    margin-top: 10px;
}

.ce-column {
    margin-right: 10px;
}

.ce-column:last-child {
    margin-right: 0;
}

.ce-row {
    margin-bottom: 10px;
}

.ce-row:last-child {
    margin-bottom: 0;
}

.ce-above .ce-bodytext {
    clear: both;
}

.ce-intext.ce-left ol,
.ce-intext.ce-left ul {
    padding-left: 40px;
    overflow: auto;
}

/* Headline */
.ce-headline-left {
    text-align: left;
}

.ce-headline-center {
    text-align: center;
}

.ce-headline-right {
    text-align: right;
}

/* Uploads */
.ce-uploads {
    margin: 0;
    padding: 0;
}

.ce-uploads li {
    list-style: none outside none;
    margin: 1em 0;
}

.ce-uploads img {
    float: left;
    padding-right: 1em;
    vertical-align: top;
}

.ce-uploads span {
    display: block;
}

/* Table */
.ce-table {
    width: 100%;
    max-width: 100%;
}

.ce-table th,
.ce-table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
}

.ce-table thead th {
    border-bottom: 2px solid #dadada;
}

.ce-table th,
.ce-table td {
    border-top: 1px solid #dadada;
}

.ce-table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.ce-table-bordered th,
.ce-table-bordered td {
    border: 1px solid #dadada;
}

/* Space */
.frame-space-before-extra-small {
    margin-top: 1em;
}

.frame-space-before-small {
    margin-top: 2em;
}

.frame-space-before-medium {
    margin-top: 3em;
}

.frame-space-before-large {
    margin-top: 4em;
}

.frame-space-before-extra-large {
    margin-top: 5em;
}

.frame-space-after-extra-small {
    margin-bottom: 1em;
}

.frame-space-after-small {
    margin-bottom: 2em;
}

.frame-space-after-medium {
    margin-bottom: 3em;
}

.frame-space-after-large {
    margin-bottom: 4em;
}

.frame-space-after-extra-large {
    margin-bottom: 5em;
}

/* Frame */
.frame-ruler-before:before {
    content: '';
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 2em;
}

.frame-ruler-after:after {
    content: '';
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 2em;
}

.frame-indent {
    margin-left: 15%;
    margin-right: 15%;
}

.frame-indent-left {
    margin-left: 33%;
}

.frame-indent-right {
    margin-right: 33%;
}

.container>.row>div>.row>.frame-default>section>.container {
    width: auto; //Neutralize nested Bootstrap container
}

button {
    outline: 0 !important;
    &[type='submit'] {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        @include fromDesktop {
            left: 0;
            right: 0;
            width: 100%;
            max-width: 530px;
            margin: 0 auto;
        }
    }
    &[disabled] {
        opacity: 0.3;
    }
}

.loading-spinner {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    .icon {
        width: 60px;
        height: 55px;
        margin: 0 auto;
    }
    svg {
        opacity: 0.7;
        animation: blink 2s ease-in-out infinite;
    }
}

@keyframes blink {
    50% { opacity: 0; } 
    100% { opacity: 0.7;} 
}

.col {
    &--copy {
        padding: 0 27px;
    }
}

.fuelData p, .copyright {
    margin-top: 33px;
}

.copyright {
    strong {
        font-family: $font-primary-bold;
        font-weight: normal;
    }
}

.legalTexts, .fuelDataExtra {
    // @include font-lato-regular(14px, $colorLegalText, normal, 21px);
    // @include fromDesktop {
    //     @include font-lato-regular(16px, $colorLegalText, normal, 26px);
    // }
    @include font-primary(copy);
    font-weight: 400 !important;
    strong, em, b {
        @include font-primary(copy-bold);
        font-style: normal;
        font-weight: 400 !important;
    }
}


// footer

footer {
    padding-top: 20px;
    padding-bottom: 80px;
    @include fromDesktop {
        padding-bottom: 0;
    }
}

#footer {
    &.show {
        transition: opacity 400ms;
    }
    &.hide {
        opacity: 0;
        visibility: hidden;
    }
}

footer > div.container > div > div > div.fuelData > div > div > p:nth-child(1) strong {
    @include font-primary(h1);
    font-weight: 400 !important;
}

// NGB Modal Angular
ngb-modal-window {
    .modal-content{
        border-radius: 0;
    }
}

.scaleIn {
    .modal-content{
        animation-name: scaleIn;
        animation-duration: 0.3s;
    }
}

@keyframes scaleIn {
  0%   {transform: scale(0.5)}
  75%  {transform: scale(1.05)}
  100% {transform: scale(1)}
}

.fadeIn {
    .modal-content{
        animation-name: fadeIn;
        animation-duration: 0.3s;
    }
}

@keyframes fadeIn {
  0%   {opacity: 0.5}
  75%  {opacity: 0.9}
  100% {opacity: 1}
}

ngb-modal-window {
    &.px-modal-overlay {
        z-index: 1070;
    }
    &.fullscreen {
        height: 100vh;
        padding-bottom: 90px;
        background-color: $colorWhite;
        // animation-name: fadeIn;
        // animation-duration: .5s;
        @include fromTablet {
            padding-bottom: 59px;
        }
        .modal-dialog {
            height: 100%;
            max-width: 100%;
            @include toTablet {
                margin: 0;
            }
            @include fromTablet {
                margin: auto auto;
            }
        }
        .modal-content {
            border: 0 !important;
            height: 100%;
        }
        .modal-body {
            padding: 0;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            strong, b{
                font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
                font-weight: normal;
            }
        }
    }
    &.narrow {
        .modal-dialog {
            @include toPhone {
                margin: 27px;
            }
        }
        .modal-content {
            border: 0 !important;
        }
    }
}
ngb-modal-backdrop {
    &.px-modal-overlay {
        z-index: 1070 !important;
    }
}

.modal-body{
    strong, b{
        font-family: 'mmc-bold', Helvetica, Arial, sans-serif;
        font-weight: normal;
    }
}

// BS nesting debugging classes
// .container-fluid {
//     background: yellow;
// }
// .container {
//     background: green;
// }
// .row {
//     background: pink;
// }
// .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
//     background: lightblue;
// }


.sticky-spacer {
    &.active {
        height: 44px;
    }
}

.mainContent sup {
    cursor: pointer;
}