.a-01000 {
    height: 44px;
    width: 100%;
    max-width: 267px;   
    background-color: $colorWhite;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $colorBlack;
    cursor: pointer;
    .icon {
        position: absolute;
        display: inline-block; 
        right: 30px;
        opacity: 0; 
        transition:opacity 0.2s, right 0.2s;
        svg {
            width: 8px;
            height: 12px;
            margin-bottom: 2px;
        }
        /* START - Only for Preview purpose - REMOVE if not needed anymore*/
        // width: 20px;
        // height: 20px;
        // background: $colorBlack;
        /* END - Only for Preview purpose - REMOVE if not needed anymore*/
    }
    .text, span {
        // color: $colorBlack;
        // font-size: 12px;
        // font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 0 15px;
        @include font-primary(cta, $colorBlack);
    }
    &:hover {
        .icon {
            right: 20px;
            opacity: 1;
            animation-name: a-01000MoveFade;
            animation-duration: .4s;
            svg {
                @include fillSVG($colorBlack);
            }
        }
    }
    &:active {
        background-color: $colorBlack;
        .text {
            color: $colorWhite;
        }
    }
    .elementWrapper {
        display: flex;
        align-items: center;
    }
    &--b {
        background-color: $colorBlack;
        border-color: $colorWhite;
        .icon {
            // background-color: $colorWhite;
            svg {
                @include fillSVG($colorWhite);
            }
        }
        .text {
            color: $colorWhite;
        }
        &:hover {
            .icon {
                svg {
                    @include fillSVG($colorWhite);
                }
            }
        }
        &:focus {
            background-color: $colorWhite;
            .text {
                color: $colorBlack;
            }
        }
    }
    &--c {
        background-color: "transparent";
        border: 2px solid $colorWhite;
        .text {
            color: $colorWhite;
        }
        &:hover {
            background-color: $colorGrey;
        }        
    }
    &.disabled {
        border-color: $colorGrey;
        .text {
            color: $colorGrey;
        }
        .icon {
            display: none;
        }
    }
}

@keyframes a-01000MoveFade {
    0%      {  right: 30px; opacity: 0; }
    80%     {  right: 17px; opacity: 1; }
    100%    {  right: 20px; }
}
@keyframes a-01000MoveOut {
    0%      {  right: 20px; }
    100%    {  right: 30px; }
}