.m-0320 {
    position: relative;

    .image-primitive {
        px-lazy-image {
            img {
                width: 100%;
                height: auto;
                @include fromPhonePlus {
                    height: 1024px;
                    max-height: 1024px;
                    object-fit: cover;
                    object-position: bottom center;
                }
                @include fromTablet {
                    height: 1322px;
                    max-height: 1322px;
                }
                @include fromDesktop {
                    height: 811px;
                    max-height: 811px;
                }
                @include fromWide {
                    height: 940px;
                    max-height: 940px;
                }
            }
        }
    }

    .content {
        position: absolute;
        top: 26px;
        left: 55px;
        right: 0;
        max-width: 800px;
        width: calc(100% - 110px);
        height: calc(100% - 152px);

        &.right {
            right: 55px;
            left: auto;

            .m-0320_text {
                align-items: flex-end;
            }

            .m-0320_key_prices {
                .prices {
                    .bulletLine {
                        border-left: unset;
                        border-right: 2px solid $colorWhite;
                        align-items: flex-end;
                        padding: 0 40px 0 0;
                        @include fromPhonePlus {
                            max-width: unset;
                        }
                    }
                }
            }

            .m-0320_btn {
                @include fromPhonePlus {
                    left: unset;
                    right: 0;
                }
            }

            @include fromTablet {
                left: auto;
                right: 115px;
            }

            @include fromDesktop {
                left: auto;
                right: 160px;
            }
        }

        @include fromTablet {
            left: 115px;
            top: 70px;
            width: calc(100% - 230px);
            height: calc(100% - 140px);
        }
        @include fromDesktop {
            left: 160px;
            width: calc(100% - 320px);
            height: calc(100% - 140px);
        }
    }
    .m-0320__envkv {
        width: 100%;
        position: static;

        @include fromPhonePlus {
            position: absolute;
            width: 100%;
            bottom: 0;
        }
    }

    &_key_facts {
        .row {
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;

            .bulletLine {
                height: 50px;
                width: 302px;
                padding: 0 0 0 20px;
                @include fromPhonePlus {
                    max-width: 47%;
                }
                @include fromTablet {
                    padding: 0 0 0 40px;
                }
                border-left: 2px solid $colorWhite;
                margin: 15px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                margin-left: calc(var(--bs-gutter-x) * 0.5);

                h4 {
                    font-size: 15px;
                    line-height: 18px;
                    margin-bottom: 3px;
                    @include fromPhonePlus {
                        margin-bottom: 4px;
                    }
                    @include fromTablet {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                p {
                    color: $colorWhite;
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 0;
                }
            }
        }
    }

    &_key_prices {
        .prices {
            .bulletLine {
                height: 50px;
                width: 302px;
                padding: 0 0 0 20px;
                @include fromPhonePlus {
                    max-width: 47%;
                }
                @include fromTablet {
                    padding: 0 0 0 40px;
                }
                border-left: 2px solid $colorWhite;
                margin: 15px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                h4 {
                    line-height: 20px;
                    margin-bottom: 3px;

                    .short_text {
                        font-size: 21px;
                    }
                    .price_text {
                        font-size: 30px;

                        sup {
                            font-size: 10px;
                            line-height: 13px;
                            color: #fff;
                            border: 1px solid #fff;
                            border-radius: 50%;
                            width: 14px;
                            height: 14px;
                            display: inline-block;
                            padding: 0 3px;
                            position: relative;
                            top: -16px;
                            left: 1px;
                        }
                    }

                    @include fromPhonePlus {
                        margin-bottom: 4px;
                    }
                }

                p {
                    color: $colorWhite;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 0;
                }
            }
        }
    }

    &_text {
        display: flex;
        flex-direction: column;
        p,
        h3,
        h2 {
            color: $colorWhite;
        }
        h2 {
            font-family: $font-primary-regular;
            font-size: 23px;
            line-height: 30px;
            @include fromPhonePlus {
                font-size: 40px;
                line-height: 48px;
            }
            @include fromTablet {
                font-size: 50px;
                line-height: 60px;
            }
        }
        h3 {
            font-family: $font-primary-regular;
            font-size: 19px;
            line-height: 18px;
            @include fromPhonePlus {
                font-size: 25px;
                line-height: 30px;
            }
            @include fromTablet {
                font-size: 30px;
                line-height: 36px;
            }
        }
        p.copy {
            font-size: 15px;
            line-height: 21px;
            @include fromPhonePlus {
                line-height: 24px;
            }
            @include fromTablet {
                font-size: 16px;
                line-height: 30px;
            }
        }
    }

    .envkv_opened {
        @include fromPhonePlus {
            bottom: 80px;
        }
    }

    &_btn {
        position: static;
        @include fromPhonePlus {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: unset;
        }
        .a-01000 {
            margin-top: 20px;
            height: 35px;
            width: 250px;
            max-width: 100%;
            @include fromPhonePlus {
                width: 220px;
                height: 42px;
            }
            @include fromTablet {
                width: 260px;
                height: 50px;
            }
            .text,
            span {
                font-size: 12px;
                line-height: 14px;
                text-transform: none;
                @include fromTablet {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
}
