.a-00300 {
    span {
        padding: 3px 6px;
        @include font-primary(hero);
        @include fromDesktop {
            padding: 7px 12px;
            line-height: 48px;
        }
    }
    &--a {
        span {
            color: white;
            background-color: $colorDarkGrey;
        }
    }
	&--b {
        span {
            color: white;
            background-color: $colorRed;
        }
    }
    &--c {
        span {
            color: black;
            background-color: white;
        }
    }
    &--d {
        span {
            color: $colorRed;
            background-color: white;
        }
    }
}