.m-0310 {
  padding: 25px 0 0;
  @include fromDesktop {
    padding: 40px 0 35px;
  }
  & + .m-0310 {
    @include fromDesktop {
      border-top: 1px solid $colorDarkGrey;
    }
  }
  &:last-child {
    padding-bottom: 45px;
    @include fromDesktop {
      padding-bottom: 70px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include fromTablet {
      flex-direction: row;
      padding: 0;
    }
  }
  &__img {
    @include fromTablet {
      max-width: 50%;
    }
    @include fromDesktop {
      max-width: 600px;
    }
  }
  &__headline {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
    @include fromTablet {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 25px;
    }
  }
  &__text {
    padding-top: 5px;
    @include fromTablet {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include fromDesktop {
      max-width: 100%;
      padding-top: 0;
      padding-left: 110px;
    }
    p {
      margin: 0;
    }
  }
  .a-00800 {
    margin-top: 10px;
    @include fromDesktop {
      margin-top: 40px;
    }
  }
}