.a-01500 {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $colorBorderGray;
    @include font-primary(cta);
    .content-collapsed {
        display: none;
    }
    .content-shown {
        display: block;
    }
    &.collapsed {
        .content-collapsed {
            display: block;
        }
        .content-shown {
            display: none;
        }
    }
    &__collapse {
        p {
            padding-top: 5px;
        }
        &--b {
            p {
                color: white;
            }
        }
    }
    .text {
        text-transform: uppercase;
        text-align: left;
        // @include font-lato-bold(12px, $colorBlack, normal, 16px);
        @include font-primary(cta);
        color:$colorBlack;
    }
    .arrow {
        display: flex;
        align-items: center;
        color: black;
        // transform: rotate(90deg);
        svg {
            width: 16px;
            height: 9px;;
        }
    }
    &:hover {
        .text, .arrow {
            color: $colorDarkGrey;
        }
        .icon {
            background: $colorDarkGrey;
        }
    }
    &:focus {
        .text, .arrow {
            color: black;
        }
        .icon {
            background: black;
        }
    }
    &.disabled {
        .text, .arrow {
            color: $colorGrey;
        }
        .icon {
            background: $colorGrey;
        }
    }
    &[aria-expanded="true"] {
        .arrow {
            transform: rotate(180deg);
        }
    }
    &--b {
        border-bottom: 1px solid black;
        .text {
            color: white;
        }
        .arrow {
            color: white;
        }
        &:hover {
            .text, .arrow {
                color: $colorGrey;
            }
            .icon {
                background: $colorGrey;
            }
        }
        &:focus {
            .text, .arrow {
                color: white;
            }
            .icon {
                background: white;
            }
        }
        &.disabled {
            .text, .arrow {
                color: $colorGrey;
            }
            .icon {
                background: $colorGrey;
            }
        }
    }
}