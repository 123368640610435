.o-021 {
    padding: 0 15px 0 0;
    @include fromTablet {
        padding: 70px 0;
        margin-bottom: 0 !important;
    }

    &__wrapper {
        padding: 0;

        .button-prev {
            @include toTablet {
                display: none;
            }
        }
        .button-next {
            @include toTablet {
                display: none;
            }
        }
        
        .swiper-container {
            .swiper-wrapper {
                padding: 40px 0;
                .swiper-slide {
                    overflow: hidden !important;
                }
            }
            .swiper-pagination {
                height: 0;
                bottom: 35px;
                @include fromTablet {
                    bottom: 35px;
                }
                display: flex;
                justify-content: center;
                span {
                    width: 8px;
                    height: 8px;
                    margin: 5px !important;
                    &.swiper-pagination-bullet {
                        background-color: white;
                        box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.65);
                    }
                    &.swiper-pagination-bullet-active {
                        width: 9px;
                        height: 9px;
                        margin: 4px 3px;
                        background-color: #ed0000 !important;
                        border: 2px solid #ed0000 !important;
                    }
                }
            }
        }
    }
    &__slide {
        padding: 10px 6px;
        height: inherit !important;
        &:focus,
        &:active {
            border: 0 solid transparent !important;
            outline: none;
        }
    }
    &__wrapperSingle {
        padding: 10px 6px;
        px-m0071 {
            height: auto;
        }
        .m-0071 {
            height: auto;
        }
    }
    .slick-list {
        @include toTablet {
            overflow: visible;
        }
    }
    .slick-track {
        display: flex !important;
    }
    .slick-dots {
        height: 35px;
        bottom: -35px;
        @include fromTablet {
            bottom: 35px;
        }
        display: flex;
        justify-content: center;
        li {
            width: 7px;
            height: 7px;
            margin: 5px;
            &.slick-active {
                width: 9px;
                height: 9px;
                margin: 4px 3px;
            }
            button {
                width: 100%;
                height: 100%;
                box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
            }
        }
    }
    .slick-arrow {
        height: 40px;
        width: 40px;
        display: none !important;
        z-index: 10;
        @include fromDesktop {
            display: inline-block !important;
        }
        &:before {
            color: $colorGrey;
            content: "";
        }
    }
    .slick-slide {
        &.opacity {
            opacity: 0;
            &.slick-active {
                opacity: 1;
            }
        }
    }
    &__prevArrow,
    &__nextArrow {
        img,
        svg {
            height: 40px;
            width: auto;
            @include fillSVG($colorArrowGrey);
        }
    }
    &__prevArrow {
        left: -60px !important;
    }
    &__nextArrow {
        right: -60px !important;
    }
}
