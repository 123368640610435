.c0010 {
    &__container-wrapper {
        &_inner {
            position: relative;

            .image-primitive {
                px-lazy-image {
                    img {
                        width: 100%;
                        @include fromDesktop {
                            object-fit: cover;
                            height: 811px;
                        }
                        @include fromWide {
                            object-fit: cover;
                            object-position: top;
                            height: 940px;
                        }
                        
                    }
                }
            }

            .c0010_content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                .c0010_inner {
                    padding: 0 15px 15px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    @include fromPhonePlus {
                        padding: 0 30px 30px;
                    }
                    @include fromTablet {
                        padding: 0 40px 40px;
                    }
                    @include fromDesktop {
                        padding: 0 50px 50px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;
                    }
                    @include fromWide {
                        padding: 0 80px 80px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-end;
                    }

                    @media screen and (min-width: 992px) {
                        flex-direction: row;
                    }

                    .c0010_text {
                        width: 100%;
                        @include fromDesktop {
                            width: 860px;
                            max-width: calc(100% - 290px);
                            // max-width: calc(100% - 297px);
                        }
                        .c0010_headline {
                            color: $colorWhite;
                            font-size: 25px;
                            line-height: 1.2;
                            margin-bottom: 30px;
                            word-break: break-word;
                            hyphens: none;

                            @include fromPhonePlus {
                                font-size: 40px;
                            }
                            @include fromTablet {
                                font-size: 50px;
                                margin-bottom: 40px;
                            }
                            @include fromDesktop {
                                margin-bottom: 0px;
                                width: unset;
                            }
                        }
                    }
                    .c0010_btn {
                        .a-01000.a-01000--c {
                            width: 250px;
                            height: 42px;
                            @include fromPhonePlus {
                                width: 220px;
                                height: 42px;
                            }
                            @include fromTablet {
                                width: 260px;
                                height: 50px;
                            }
                            &:hover {
                                background-color: transparent;
                                opacity: 0.4;
                            }
                            .text, span {
                                text-transform: none;
                                font-size: 12px;
                                line-height: 14px;
                                @include fromTablet {
                                    font-size: 14px;
                                    line-height: 17px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}