.m-0050 {
    &__container-wrapper {
        > .row {
            &:nth-child(odd) {
                background-color: $colorLightGrey;
            }
            &:nth-child(even) {
                .desktopContent {
                    @include fromTablet {
                        flex-direction: row-reverse;
                        margin-left: -25px;
                        margin-right: -15px;
                    }
                }
                .innerConent {
                    @include fromTablet {
                        padding-left: 45px;
                        padding-right: 15px;
                    }
                    @include fromDesktop {
                        padding-left: 115px;
                    }
                }
                .innerImg {
                    @include fromTablet {
                        padding-left: 0 !important;
                        padding-right: 15px;
                    }
                    @include fromDesktop {
                        padding-right: 12px;
                    }
                }
            }
        }
        &--invert {
            > .row {
                &:nth-child(odd) {
                    background-color: transparent;
                }
                &:nth-child(even) {
                    background-color: $colorLightGrey;
                }
            }
        }
        &--firstVideoLeft {
            > .row {
                &:nth-child(odd) {
                    .desktopContent {
                        @include fromTablet {
                            flex-direction: row-reverse;
                            margin-left: -25px;
                            margin-right: -15px;
                        }
                    }
                    .innerConent {
                        @include fromTablet {
                            padding-left: 45px;
                            padding-right: 15px;
                        }
                        @include fromDesktop {
                            padding-left: 115px;
                        }
                    }
                    .innerImg {
                        @include fromTablet {
                            padding-left: 0 !important;
                            padding-right: 15px !important;
                        }
                        @include fromDesktop {
                            padding-left: 0 !important;
                            padding-right: 12px;
                        }
                    }
                }
                &:nth-child(even) {
                    .desktopContent {
                        @include fromTablet {
                            flex-direction: row;
                            margin-left: -15px;
                            margin-right: -25px;
                        }
                    }
                    .innerConent {
                        @include fromTablet {
                            padding-left: 15px;
                            padding-right: 45px;
                        }
                        @include fromDesktop {
                            padding-right: 115px;
                            padding-left: 15px;
                        }
                    }
                    .innerImg {
                        @include fromTablet {
                            padding-left: 15px !important;
                            padding-right: 0 !important;
                        }
                        @include fromDesktop {
                            padding-right: 0 !important;
                            padding-left: 12px;
                        }
                    }
                }
            }
        }
    }
    .mobileContent {
        width: 100%;
        margin: 0 0;
        .innerImg {
            @include toTablet {
                width: 100%;
            }
            img {
                @include toTablet {
                    width: 100%;
                }
            }
        }
    }
    &__seperator {
        border-top: 1px solid $colorLightGrey;
    }
    &__container {
        @include toTablet {
            width: 100%;
        }
        @include fromTablet {
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            max-width: $container-width-lg;
        }
        @include fromDesktop {
            padding: 0 $column-padding-desktop;
        }
        @include fromWide {
            max-width: $container-width-xl;
        }
    }
    &__wrapper {
        padding: 0;
        &--campaigns {
            .desktopContent {
                display: flex;
                flex-direction: row-reverse;

                .innerConent {
                    padding-left: 115px;
                    padding-right: 38px;
                }
            }
        }
    }
    .content {
        padding: 15px 25px 25px;
        width: 100%;
        // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        //     width: 100%;
        // }
        @include fromTablet {
            padding-top: 70px;
            padding-bottom: 70px;
        }
        @include fromDesktop {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .headline {
        margin-bottom: 7px;
        @include font-primary(h1);
        // @include font-alpha-regular(18px, $colorBlack, normal, 22px);
        @include fromTablet {
            margin-bottom: 25px;
            // @include font-alpha-regular(28px, $colorBlack, normal, 38px);
        }
    }
    .copy {
        @include font-primary(copy);
        // font-size: 14px;
        // line-height: 20px;
        margin-bottom: 15px;
        @include fromTablet {
            // font-size: 16px;
            // line-height: 26px;
            margin-bottom: 35px;
        }
        strong {
            font-family: mmc-bold,Helvetica,Arial,sans-serif;
            font-weight: normal;
        }
    }
    .desktopContent {
        @include fromTablet {
            margin-right: -25px;
        }
        @include fromDesktop {
            margin-right: -15px;
        }
    }
    .innerConent {
        @include fromTablet {
            padding-right: 45px;
        }
        @include fromDesktop {
            padding-right: 115px;
        }
        ol, ul {
            padding-left: 20px;
        }
    }
    .innerImg {
        @include fromTablet {
            padding-right: 0 !important;
        }
        .embed-responsive {
            width: 100%;
            max-width: 100%;
            @include fromTablet {
                width: 600px;
            }
        }
    }
    .innerVideo {
        @include toTablet {
            padding: 0 11px;
        }
    }
    .innerVideo + .contentwrapper {
        @include toTablet {
            padding: 0 11px;
        }
    }
    .a-00800 {
        max-width: 100%;
        @include fromTablet {
            max-width: 267px;
        }
    }
    .envkv {
        margin-bottom: 0;
    }
    .a-00800 + .envkv {
        margin-top: 15px;
    }
    .a-00500 {
        img {
            @include toDesktop {
                width: 100%;
            }
        }
    }
    px-a01100 {
        display: block;
        margin-bottom: 15px;
        @include fromTablet {
            margin-bottom: 20px;
            .a-01500 {
                .text {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
        }
    }
    px-a01500 {
        .a-01500 {
            border-top: 1px solid $colorBorderGray;
            border-bottom: 0 solid transparent;
        }
        .collapse, .collapsing {
            font-size: 14px;
            line-height: 20px;
            // transition: height .35s ease, padding .35s ease;
        }
        .show, .collapsing {
            border-top: 1px solid $colorBorderGray;
        }
        .collapse > div, .collapsing > div {
            padding: 15px 0px 25px;
        }

    }

    .appStoreBadgeBtn {
        width: 100%;
        padding: 0;
    }
    .appStoreBadge {
        width: 100%;
        height: auto;
    }
    &--b {
        .copy {
            margin-bottom: 0;
            @include fromTablet {
                margin-bottom: 35px;
            }
        }
    }
    &--d {
        .content {
            @include fromTablet {
                // zurück ändern wenn neues app-bild geliefert wird
                //padding-bottom: 0;
            }
        }
    }
    &--e {
        .innerConent {
            b, strong {
                @include font-primary(false, false, bold)
            }
        }
    }
    &__wrapper:nth-child(even) {
        .desktopContent {
            @include fromTablet {
                flex-direction: row-reverse;
                margin-left: -25px;
                margin-right: -15px;
            }
        }
        .innerConent {
            @include fromTablet {
                padding-left: 45px;
                padding-right: 15px;
            }
            @include fromDesktop {
                padding-left: 115px;
            }
        }
        .innerImg {
            @include fromTablet {
                padding-left: 0 !important;
                padding-right: 15px;
            }
            @include fromDesktop {
                padding-right: 12px;
            }
        }
    }
    
    .embed-responsive .cmplazypreviewiframe {
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.fullWidthTeaserEdit {
    &__header {
        position: relative;
        height: 20px;
    }
    &__close {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 16px;
        font-size: 30px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
    }
    &__editText {
        p {
            margin-bottom: 0;
        }
    }
    &__teaserRow {
        margin-left: 0px;
    }
    &__teaser {
        padding: 0;
        margin-top: 15px;
    }
    &__content{
        min-width: 950px;
    }
    &__teaserInner {
        position: relative;
        display: flex;
        height: 100%;
        padding: 50px 15px 15px;
        margin-right: 15px;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $colorEditBorder;
        &.disabled {
            border-color: rgba(222, 226, 230, 0.5);
            .fullWidthTeaserEdit__teaserImg, .fullWidthTeaserEdit__teaserHeadline  {
                opacity: 0.5;
            }
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
    }
    &__teaserCheckbox {
        position: absolute;
        width: 25px;
        top: 15px;
        right: 15px;
    }
    &__teaserHeadline {
        display: flex;
        min-height: 48px;
        margin-top: 5px;
        flex-direction: column;
        justify-content: flex-end;
        h4 {
            font-weight: 700;
        }
    }
    &__saveBtn {
        margin-top: 10px;
        float: right;
    }
}