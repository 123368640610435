.c0011__container-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px 15px;
    @include fromPhone {
        padding: 70px 19px;
    }
    @include fromDesktop {
        padding: 105px 19px;
    }
    .inner-wrap {
        @include fromDesktop {
            max-width: 800px;
        }
    }
    .heading {
        font-size: 16px;
        margin-bottom: 23px;
        @include fromPhone {
            font-size: 25px;
            margin-bottom: 25px;
        }
        @include fromDesktop {
            font-size: 30px;
        }
    }
    .text-content, .text-content p {
        font-size: 15px;
        line-height: 20px;
        @include fromPhone {
            font-size: 14px;
            line-height: 24px;
        }
        @include fromDesktop {
            font-size: 16px;
            line-height: 30px;
        }
        :last-child {
            margin-bottom: 0;
        }
    }

    strong {
        font-family: mmc-bold,Helvetica,Arial,sans-serif;
        font-weight: 400;
    }
}